import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propOr, mergeRight} from 'ramda';

import ResizeDetector from 'react-resize-detector';

/**
 * A styled div meant to be placed in a `ddk.Header` or a `ddk.Sidebar`
 */
export default class Title extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lineHeight: 'initial'
        }
        this.titleRef = React.createRef();
        this.setLineHeight = this.setLineHeight.bind(this);
    }

    componentDidMount() {
        this.setLineHeight();
    }

    // set the ddk.Title child's line height to match the Header `height`
    setLineHeight() {
        const node = this.titleRef.current;
        const lineHeight = (
            node.parentElement.classList.contains('layout-header') &&
            node.parentElement.clientHeight
        );
        /*
         * If there's a parent, use its dimensions for line-height and don't reset on a re-render.
         * Otherwise, use the default this.state.lineHeight
        */
        if (lineHeight) {
            this.setState({ lineHeight: lineHeight});
        }
    }

    render() {
      const { children, allow_hidden_in_header, id, style, capitalization } = this.props;
      const {lineHeight} = this.state;

      /* merge conditional styles from props */
      const styleInner = mergeRight(
          {
              whiteSpace: (
                  allow_hidden_in_header && lineHeight !== 'initial' ? 'nowrap' : 'normal'
              ),
              lineHeight: (typeof(lineHeight) === 'number' ? `${lineHeight}px` : lineHeight),
              textTransform: capitalization
          },
          propOr({}, 'style', this.props)
      );

      return (
        <div
            ref={this.titleRef}
            className={`layout-title ${propOr('', 'className', this.props)}`}
            id={id}
            style={style} // TODO: is this actually what we want??? It's what we had with omit.
        >
            {children &&
                <div
                    className="title"
                    style={styleInner}
                >
                    {children}
                </div>
            }
            <ResizeDetector
                handleHeight={true}
                handleWidth={true}
                refreshMode="debounce"
                refreshOptions={{trailing: true}}
                refreshRate={50}
                onResize={this.setLineHeight}
            />
        </div>
      );
    }
};

Title.defaultProps = { allow_hidden_in_header: true }

Title.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The title's content. This is usually just a string.
     */
    children: PropTypes.node,

    /**
     * If `True`, then the title will disappear on smaller screens when
     * there isn't enough room.
     * If `False`, then the title will disappear _word-by-word_ when there
     * isn't enough room.
     */
    allow_hidden_in_header: PropTypes.bool,

    /**
     * The text capitalization of the title. 'lowercase' will transform all
     * characters to lowercase, 'uppercase' will be all caps, and 'capitalize' will follow
     * "title case" capitalization (the first character of each word will be
     * uppercase)
     */
    capitalization: PropTypes.oneOf(['lowercase', 'uppercase', 'capitalize']),

    /**
     * The style of the outermost div of the Title component
     */
    style: PropTypes.object,
    /**
     * Additional class names for the outermost div of the Title component
     */
    className: PropTypes.string
};
