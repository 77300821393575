import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {equals, has, mergeRight, mergeAll, pick, propOr, path, type} from 'ramda';
import PageFooter from './PageFooter.react';
import PageHeader from './PageHeader.react';
import {CLASSNAMES} from '../constants';

import Row from './Row.react';

// See https://codepen.io/chriddyp/pen/ZgQPyM?editors=1100 for the sizing model
const SIZING_DEFAULT = {
    'letter': {
        'width': '8.5in',
        'height': '11in',
        'page_margin': '0.5in'
    },
    'legal': {
        'width': '8.5in',
        'height': '14in',
        'page_margin': '0.5in'
    },
    'a4': {
        'width': '210mm',
        'height': '297mm',
        'page_margin': '12.7mm'
    }
}

function computePageDimensions(size, page_margin, orientation) {
    const {page_margin: dflt_margin, width, height} = SIZING_DEFAULT[size];
    const DEFAULT_MARGINS = {
        'left': dflt_margin,
        'right': dflt_margin,
        'top': dflt_margin,
        'bottom': dflt_margin,
    }
    const {left, right, top, bottom} = mergeRight(DEFAULT_MARGINS, page_margin);
    const pageDimensions = {
        'page-container': {
            'width': orientation === 'vertical' ? width: height,
            'height': orientation === 'vertical' ? height: width,
            'position': 'relative',
            'paddingLeft': left,
            'paddingRight': right,
            // 'paddingTop': page_margin['top'],
            // 'paddingBottom': page_margin['bottom'],
        }
    }

    const headerAndFooterStyle = {
        'width': '100%',
        'overflow': 'hidden'
    }

    pageDimensions['page-footer'] = mergeRight(
        {
            'bottom': 0,
            'paddingBottom': bottom,
        },
        headerAndFooterStyle
    );
    pageDimensions['page-header'] = mergeRight(
        {
            'top': 0,
            'paddingTop': top
        },
        headerAndFooterStyle
    );
    return pageDimensions;
}

const elementIsCloselyRightBoundedBy = (child, container) => {
    return container && child && container.bounds.right - child.bounds.right <= parseInt(child.offsetSpace.right, 10);
}

const elementIsCloselyLeftBoundedBy = (child, container) => {
    return container && child && child.bounds.left - container.bounds.left <= parseInt(child.offsetSpace.left, 10);
}

const resetMarginAndAdjustWidth = (child, marginProp, adjustedWidth) => {
    const origMargin = parseInt(getComputedStyle(child)[marginProp], 10);
    if (origMargin >= 0) {
        child.style[marginProp] = '0px';
        // add back px subtracted from margin to total calc'd width
        return adjustedWidth + origMargin || 0 + origMargin;
    }
    return 0;
}

/*
 * we use this instead of offsetLeft because
 * 1. there's no offsetRight prop
 * 2. offsetLeft requires 'position: relative' on the inner DDK container,
 * which messes up its layout
 */
const getOffsetSpace = (element) => {
    const computedStyle = element.computedStyle;
    return {
        left: parseInt(computedStyle.marginLeft, 10) + parseInt(computedStyle.paddingLeft, 10),
        right: parseInt(computedStyle.marginRight, 10) + parseInt(computedStyle.paddingRight, 10)
    }
}

const adjustPageBlockChildrenMargins = function(component) {
    const container = component.innerPage.current;
    // add bounds as element prop to avoid expensive calls/reflow
    container.bounds = container.getBoundingClientRect();
    const pageCards = container.getElementsByClassName('block');
    for (var i = 0; i < pageCards.length; i++) {
        const child = pageCards[i];
        child.bounds = child.getBoundingClientRect();
        child.computedStyle = window.getComputedStyle(child);
        child.offsetSpace = getOffsetSpace(child);
        let adjustedWidth;
        // check if marginLeft was explicitly overridden
        if (!path(['marginLeft'], JSON.parse(child.dataset.userStyle || null))
          && elementIsCloselyLeftBoundedBy(child, container)) {
                adjustedWidth = resetMarginAndAdjustWidth(child, 'marginLeft', adjustedWidth)
        }
        // check if marginRight was explicitly overridden
        if (!path(['marginRight'], JSON.parse(child.dataset.userStyle || null))
          && elementIsCloselyRightBoundedBy(child, container)) {
            adjustedWidth = resetMarginAndAdjustWidth(child, 'marginRight', adjustedWidth)
        }
        // we must sum adjustedWidth and set here
        // due to race condition (?) on style.width assignment for left AND right-bounded blocks
        if (adjustedWidth) {
            /*
             * Subtract 1px to account for rounding errors - see #779
             * rounding errors probably not limited to Safari, so no conditional browser check
             */
            child.style.width = `${child.clientWidth + adjustedWidth -1}px`
        }
    }
}

/**
 * A component that describes a single page.
 * This component must be defined within the `children` of a ddk.Report.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Report(display_page_numbers=True, children=[
 *         ddk.Page([
 *             html.H1('Quarterly Earnings'),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x1', y='y1'
 *                 ))
 *             ]),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x2', y='y2'
 *                 ))
 *             ]),
 *
 *             html.H2('Expected Returns'),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x2', y='y2'
 *                 ))
 *             ]),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x1', y='y1'
 *                 ))
 *             ]),
 *             ddk.PageFooter("Past Performance Is No Guarantee of Future Returns.")
 *         ]),
 *     ])
 * ])
 * ```
 */
class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areMarginsAdjusted: false
        }
        this.innerPage = React.createRef();
    }

    componentDidUpdate() {
        if (!this.state.areMarginsAdjusted) {
            adjustPageBlockChildrenMargins(this);
            this.setState({areMarginsAdjusted: true});
        }
    }

    render() {
        const {props} = this;

        if (!has('_report', props)) {
            throw new Error([
                "This page component's parent component was not a Report.",
                "Page components need to be rendered directly inside Report components."
            ].join(' '));
        }

        const isEven = props._page_number % 2 === 0;

        const pageDimensions = computePageDimensions(
            props._report.size,
            propOr(
                mergeRight(
                    propOr({}, 'page_margin', props._report),
                    (isEven ?
                        propOr({}, 'page_margin_even', props._report) :
                        propOr({}, 'page_margin_odd', props._report)
                    )
                ),
                'page_margin',
                props
            ),
            props._report.orientation
        );

        /* Reconcile properties from parent Report */
        const style = mergeAll([
            pageDimensions['page-container'],
            propOr({}, 'page_style', props._report),
            (isEven ?
                propOr({}, 'page_style_even', props._report) :
                propOr({}, 'page_style_odd', props._report)
            ),
            propOr({}, 'style', props),
        ]);

        /*
         * Handle PageFooter child:
         * - PageFooter & PageHeader are always visible - they have a height
         * - If user didn't specify them in the markup, then supply them
         * - Inject default properties into them
         */
        const pageFooterProps = {
            _page_number: props._page_number,
            _display_page_number: props._report.display_page_numbers,
            _page_style: style
        };
        let userProvidedFooter = false;
        let userProvidedHeader = false;
        let footer = null;
        let header = null;

        const setDynamicHeight = (component, parent) => {
            if (component.type === 'Graph') {
                const {figure} = component.props;
                const height = figure?.layout?.height;
                const parentClass = parent.props.className || '';
                /*
                * only override dynamic height if it's non-default
                * TODO: there's probably a better way of checking
                * if the height has been defined by the user
                */
                if (height === 600 || height === 450 || !height) {
                    if (!parentClass.includes('dynamic-heights')) {
                        let extraClasses = ' dynamic-heights';
                        if (parent.type === 'Row' && !parentClass.includes('row ddk-row')) {
                            // shouldn't need to do this..but it gets lost in the render cycle
                            extraClasses += ' row ddk-row';
                        }
                        parent.props.className = (parentClass + extraClasses).trim();
                    }
                }
            }
        }

        const mutateAndReturnChildType = (child) => {
            if (!(equals('Object', type(child)) && has('props', child))) {
                return '';
            }

            const childLayout = child.props._dashprivate_layout;

            if(childLayout.type === 'PageFooter') {
                childLayout.props._page = pageFooterProps;
                childLayout.props.style = mergeRight(
                    pageDimensions['page-footer'],
                    propOr({}, 'style', childLayout.props)
                );
                userProvidedFooter = true;
                footer = child;
            } else if(childLayout.type === 'PageHeader') {
                childLayout.props.style = mergeRight(
                    pageDimensions['page-header'],
                    propOr({}, 'style', childLayout.props)
                );
                userProvidedHeader = true;
                header = child;
            } else if (childLayout.type === 'Card' || childLayout.type === 'Block' || childLayout.type === 'Row') {
                if (childLayout.props.children.type === 'Graph') {
                    setDynamicHeight(childLayout.props.children, childLayout);
                } else if (typeof(childLayout.props.children) === "object")  {
                    for (var j in childLayout.props.children) {
                        setDynamicHeight(childLayout.props.children[j], childLayout);
                    }
                }
            } else {
                setDynamicHeight(childLayout, childLayout);
            }
            return childLayout.type;
        };

        let clonedChildren;

        if (equals('Array', type(propOr(null, 'children', props)))) {
            clonedChildren = Array.from(props.children);
            for (let i=0; i<clonedChildren.length; i++) {
               let childType = mutateAndReturnChildType(clonedChildren[i]);
               if (childType === 'PageHeader'
                   || childType === 'PageFooter') {
                    clonedChildren.splice(i, 1);
                } else if (childType === 'Card'
                           || childType === 'Block') {
                    const startChild = i;
                    const rowChildren = [];
                    do {
                        rowChildren.push(clonedChildren[i]);
                        i++;
                        childType = mutateAndReturnChildType(clonedChildren[i]);
                    } while (childType === 'Card'
                             || childType === 'Block')
                    const wrappedChildren = (
                        <Row
                            style={{'flexWrap': 'wrap', 'flexGrow': 1}}
                        >
                            {Array.from(rowChildren)}
                        </Row>
                    );
                    clonedChildren.splice(startChild, rowChildren.length, wrappedChildren);
                    /* account for the fact that multiple children have been
                     * collapsed into a single parent */
                    i -= rowChildren.length;
                }
            }
        } else if (equals('Object', type(propOr(null, 'children', props)))) {
            // in this case, clonedChildren is really more like 'clonedChild'
            clonedChildren = React.cloneElement(props.children);
            mutateAndReturnChildType(clonedChildren);
        }

        // Display footer if user didn't include it in their markup
        if (!userProvidedFooter) {
            if(props._report.display_page_numbers &&
                !has('display_page_number', props) ||
                props.display_page_number
            ) {
                pageFooterProps._display_page_number = true;
            }
            footer = (
                <PageFooter
                    _page={pageFooterProps}
                    style={pageDimensions['page-footer']}
                />
            );
        }

        // Display header if user didn't include it in their markup
        if (!userProvidedHeader) {
            header = (
                <PageHeader
                    style={pageDimensions['page-header']}
                />
            );
        }

        const className = `
            ${CLASSNAMES['ddk-page']} ddk-page__typography
            ${propOr('', 'className', props)}
            ${isEven ? 'ddk-page--even' : 'ddk-page--odd'}
        `;

        return (
            <div
                className={className}
                style={style}
                {...pick(['id'], props)
                }
            >

                {header}

                {/*
                  * NOTE - If user supplied PageHeader & PageFooter,
                  * then it'll be inside props.children and header & footer
                  * will be null
                  */}
                <div
                    className='ddk-page--inner'
                    ref={this.innerPage}
                    style={(style.background || style.backgroundColor) && {'background': 'initial'}}
                >
                    {clonedChildren}
                </div>

                {footer}

            </div>
        );

    }
}


Page.propTypes = {
    /**
     * The list of components that are children of the Page container.
     */
    children: PropTypes.any,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the Page container.
     */
    className: PropTypes.string,

    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * Display the page number for this particular Page
     * in the PageFooter. Alternatively, set the page numbers for
     * _all_ of the pages in the report with the `display_page_numbers`
     * property in `ddk.Report`.
     */
    display_page_number: PropTypes.bool,

    /**
     * Set the (left, right, top, bottom) margin dimensions
     * for this particular Page in units (`in`, `px`, `em`, etc.)
     */
    page_margin: PropTypes.exact({
        'left': PropTypes.string,
        'right': PropTypes.string,
        'top': PropTypes.string,
        'bottom': PropTypes.string,
    })
}

export default Page;
