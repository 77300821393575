import Neon_Dark from './Neon-Dark';
import Neon_Light from './Neon-Light';
import Neutral_Dark from './Neutral-Dark';
import Neutral_Light from './Neutral-Light';
import Plotly_Dark from './Plotly-Dark';
import Plotly_Light from './Plotly-Light';
// import Prof_Serv_Dark from './Prof-Serv-Dark';
// import Prof_Serv_Light from './Prof-Serv-Light';
import Serious_Dark from './Serious-Dark';
import Serious_Light from './Serious-Light';

import Neon_Dark_svg from './Neon-Dark.svg';
import Neon_Light_svg from './Neon-Light.svg';
import Neutral_Dark_svg from './Neutral-Dark.svg';
import Neutral_Light_svg from './Neutral-Light.svg';
import Plotly_Dark_svg from './Plotly-Dark.svg';
import Plotly_Light_svg from './Plotly-Light.svg';
import Serious_Dark_svg from './Serious-Dark.svg';
import Serious_Light_svg from './Serious-Light.svg';

const themes_set = [
  {
    name: 'Neutral Light',
    theme: Neutral_Light,
    image: Neutral_Light_svg,
  },
  {
    name: 'Neutral Dark',
    theme: Neutral_Dark,
    image: Neutral_Dark_svg,
  },
  {
    name: 'Plotly Light',
    theme: Plotly_Light,
    image: Plotly_Light_svg,
  },
  {
    name: 'Plotly Dark',
    theme: Plotly_Dark,
    image: Plotly_Dark_svg,
  },
  {
    name: 'Serious Light',
    theme: Serious_Light,
    image: Serious_Light_svg,
  },
  {
    name: 'Serious Dark',
    theme: Serious_Dark,
    image: Serious_Dark_svg,
  },
  {
    name: 'Neon Light',
    theme: Neon_Light,
    image: Neon_Light_svg,
  },
  {
    name: 'Neon Dark',
    theme: Neon_Dark,
    image: Neon_Dark_svg,
  },
  // {
  //   name: 'Prof Serv Light',
  //   theme: Prof_Serv_Light,
  //   image: undefined,
  // },
  // {
  //   name: 'Prof Serv Dark',
  //   theme: Prof_Serv_Dark,
  //   image: undefined,
  // },
]

export default themes_set;