import { css, getValueFromCSSText} from '../utils.js';
import chroma          from 'chroma-js';
import is from 'is_js';

const themedStyles = css`
    .ddk-container .row.ddk-row {
        display: flex;
        flex-wrap: nowrap;
        margin-right: 0px;
        margin-left: 0px;
    }
    .ddk-container .row.ddk-row:after {
      content: "";
      display: table;
      clear: both;
    }

    .ddk-container .block {
        display: inline-block;
        flex-direction: column;
        vertical-align: top;
        flex: auto;
    }

    .ddk-container .block.card.ddk-card {
        display: inline-flex;
        box-sizing: border-box;
        background-color: ${props => props.theme.card_background_color};
    }
    .ddk-container .block.card.ddk-card:not(.card--content) {
        /* bootstrap resets */
        position: static;
        border: medium none;
        min-width: 0;

        box-shadow: ${props => props.theme.card_box_shadow};
        margin: ${props => props.theme.card_margin};
        padding: ${props => props.theme.card_padding};
        /* partial workaround for overlapping borders with 0 margins */
        border-width: ${props => props.theme.card_border.width};
        border-style: ${props => props.theme.card_border.style};
        border-color: ${props => props.theme.card_border.color};
        border-radius: ${props => props.theme.card_border.radius};

        outline-width: ${props => props.theme.card_outline.width};
        outline-style: ${props => props.theme.card_outline.style};
        outline-color: ${props => props.theme.card_outline.color};
    }

    .ddk-container .layout-header {
        background-color: ${props => props.theme.header_background_color};
        box-shadow: ${props => props.theme.header_box_shadow};
        margin: ${props => props.theme.header_margin};
        padding: ${props => props.theme.header_padding};
        /* partial workaround for overlapping borders with 0 margins */
        border-width: ${props => props.theme.header_border.width};
        border-style: ${props => props.theme.header_border.style};
        border-color: ${props => props.theme.header_border.color};
        border-radius: ${props => props.theme.header_border.radius};
        font-family: ${props => props.theme.font_family_header};
        font-size: ${props => props.theme.font_size_header};
        color: ${props => props.theme.header_text};
    }

    /*
     * Safari title ::before reflow workaround
     * Safari needs this for the pseudo-element to force the title
     * to wrap on smaller widths
     */
    .ddk-container .layout-title::before {
        width: ${() => (is.safari() ? '1px' : '0px')}
    }

    .ddk-page .ddk-page--inner .block.card.ddk-card.dynamic-heights .dash-graph,
    .ddk-page .ddk-page--inner .block.dynamic-heights .dash-graph,
    .ddk-page .ddk-page--inner .row.ddk-row.dynamic-heights .dash-graph {
        /* Safari flex-basis issue workaround; see #779 */
        flex-basis: ${() => (is.safari() ? 0 : 'auto')}
    }

    .ddk-page .ddk-page--inner .dash-graph.dynamic-heights  {
        flex-basis: ${() => (is.safari() ? 0 : 'auto')}
    }

    .ddk-page .ddk-page--inner .block.card.ddk-card.dynamic-heights .block.card--content {
        flex-basis: ${() => (is.safari() ? 0 : 'auto')};
    }

    .ddk-container .ddk-page .ddk-page--inner .block.card.ddk-card.dynamic-heights .block.card--content:not(.controls) {
        flex-basis: ${() => (is.safari() ? 0 : 'auto')};
    }

    .ddk-container .ddk-page--inner {
        flex-basis: ${() => (is.safari() ? 0 : 'auto')};
        height: 0;
    }

    .ddk-container .ddk-page .block.card.ddk-card {
        background-color: ${props => props.theme.report_background_content};
    }

    .ddk-container .ddk-page .block.card.ddk-card .card-header,
    .ddk-container .ddk-page .block.card.ddk-card .card-footer,
    .ddk-container .ddk-page .control-below {
        background-color: ${props => props.theme.report_background_content};
    }

    .ddk-container .fullscreen-element--expanded {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container .block.card.ddk-card.card--modal {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100;
        margin: 0px;
        z-index: 1005;
        background-color: rgba(0,0,0,0.2);
    }
    .ddk-container
    .block.card.ddk-card.card--modal
    .card--content {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
    .ddk-container
    .block.card.ddk-card.card--modal
    .card--content
    .card--content:not(.controls) {
        flex-grow: 1;
        justify-content: center;
    }
    .ddk-container
    .block.card.ddk-card
    .card--content.controls
    header.card-header,
    .ddk-container
    .block.card.ddk-card
    .card--content.controls
    footer.card-footer {
        width: 100%;
    }
    .ddk-container
    .block.card.ddk-card
    .card--content:not(.controls) {
        margin: 0;
        height: 100%;
    }
    /* allow side-by-side graphs in modal/fullscreen */
    .ddk-container
    .block.card.ddk-card.card--content {
        display: inline-block;
    }

    .ddk-container
    .control {
        display: flex;
        align-items: center;
        padding: 10px;
    }
    .ddk-container
    .control
    .control--label {
        padding: 2px;
        font-size: 0.9em;
    }
    .ddk-container
    .control
    .control--item {
        padding: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .ddk-container
    .control
    .control--item
    input:not([type="radio"]):not([type="checkbox"]) {
        width: 100%;
    }

    .ddk-container
    .control.label--right {
        flex-direction: row-reverse;
    }
    .ddk-container
    .control .control--label {
        padding: 2px 10px;
    }
    .ddk-container
    .control.control--vertical .control--label {
        flex: 1;
    }
    .ddk-container
    .control.control--vertical.label--top .control--label {
        flex: 0;
        padding-bottom: 20px;
    }

    .ddk-container
    .control.label--top,
    .ddk-container
    .control.label--bottom {
        flex-direction: column;
    }
    .ddk-container
    .control.label--top.label--bottom,
    .ddk-container
    .control.label--bottom.label--bottom {
        flex-direction: column-reverse;
    }
    .ddk-container
    .control.label--top.label--text--left,
    .ddk-container
    .control.label--bottom.label--text--left {
        align-items: flex-start;
    }
    .ddk-container
    .control.label--top.label--text--center,
    .ddk-container
    .control.label--bottom.label--text--center {
        align-items: center;
    }
    .ddk-container
    .control.label--top.label--text--right,
    .ddk-container
    .control.label--bottom.label--text--right {
        align-items: flex-end;
    }
    .ddk-container
    .control.label--top .control--item,
    .ddk-container
    .control.label--bottom .control--item {
        width: 100%;
    }
    .ddk-container
    .control.label--top .control--item input[type="text"],
    .ddk-container
    .control.label--bottom .control--item input[type="text"] {
        width: 100%;
    }

    .ddk-container
    .controls {
        display: flex;
    }
    .ddk-container
    .controls.horizontal.center {
        justify-content: center;
    }
    .ddk-container
    .controls.horizontal.right {
        justify-content: flex-end;
    }
    .ddk-container
    .controls.vertical.center {
        align-items: center;
    }
    .ddk-container
    .controls.vertical.right {
        align-items: flex-end;
    }
    .ddk-container
    .controls .control--item {
        flex: 1;
    }
    /* IE 10/11 CSS patch (plotly/dash-design-kit#800) */
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        .ddk-container
        .controls .control--item {
            flex-basis: auto;
        }
    }
    .ddk-container
    .controls .control--item > div:not(.rc-slider) {
        width: 100%;
    }

    .ddk-container
    .controls .DateRangePicker > div {
        display: flex;
        justify-content: center;
    }
    .ddk-container
    .controls .DateRangePicker__picker {
        left: 50% !important;
        transform: translateX(-50%);
    }
    .ddk-container
    .controls label {
        display: inline-flex;
        padding: 5px;
        align-items: center;
        color: var(--body_text);
    }
    .ddk-container
    .controls.vertical label {
        display: flex;
    }


    /* consistent border for 'below' controls-in-cards */
    .ddk-container .block.card.ddk-card .card-header,
    .ddk-container .block.card.ddk-card .control-below,
    .ddk-container .block.card.ddk-card .card-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 5px 10px;
    }

    .ddk-container .block.card.ddk-card .card-header,
    .ddk-container .block.card.ddk-card .control-below {
        margin: ${props => props.theme.card_header_margin};
        padding: ${props => props.theme.card_header_padding};
        background-color: ${props => props.theme.card_header_background_color};
        border-width: ${props => props.theme.card_header_border.width};
        border-style: ${props => props.theme.card_header_border.style};
        border-color: ${props => props.theme.card_header_border.color};
        border-radius: ${props => props.theme.card_header_border.radius};
        box-shadow: ${props => props.theme.card_header_box_shadow};
    }

    .ddk-container
    .block.card.ddk-card .card-footer {
        border-top: ${props => props.theme.border} thin solid;
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container.ddk-container .control-below .Select-control,
    .ddk-container.ddk-container .block.card.ddk-card .card-header_no-title .Select-control,
    .ddk-container.ddk-container .block.card.ddk-card .card-footer .Select-control {
        border: none;
    }

    .ddk-container.ddk-container .control-below .DateRangePicker,
    .ddk-container.ddk-container .block.card.ddk-card .card-header_no-title .DateRangePicker,
    .ddk-container.ddk-container .block.card.ddk-card .card-footer .DateRangePicker {
        border: none;
        padding-bottom: 1px;
    }

    .ddk-container.ddk-container .control-below .SingleDatePicker,
    .ddk-container.ddk-container .block.card.ddk-card .card-header_no-title .SingleDatePicker,
    .ddk-container.ddk-container .block.card.ddk-card .card-footer .SingleDatePicker {
        border: none;
    }

    .ddk-container.ddk-container .control-below input,
    .ddk-container.ddk-container .block.card.ddk-card .card-header_no-title input,
    .ddk-container.ddk-container .block.card.ddk-card .card-footer input {
        border: none;
    }

    .ddk-container.ddk-container .block.card.ddk-card .card-header .DateRangePicker {
        border: none;
        padding-bottom: 1px;
    }

    .ddk-container.ddk-container .block.card.ddk-card .card-footer .DateRangePicker {
        border: none;
        padding-top: 1px;
    }

    .ddk-container
    .layout-controls {
        display: flex;
    }

    .ddk-container
    .layout-controls .control {
        flex: 1;
    }

    .ddk-container
    .dot,
    .ddk-container
    .dot:before,
    .ddk-container
    .dot:after {
        background-color: ${props => props.theme.body_text};
    }

    .ddk-container .hamburger.is-active .hamburger-inner,
    .ddk-container .hamburger.is-active .hamburger-inner::before,
    .ddk-container .hamburger.is-active .hamburger-inner::after,
    .ddk-container .hamburger-inner,
    .ddk-container .hamburger-inner::before,
    .ddk-container .hamburger-inner::after {
        background-color ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .layout-sidebar a {
      color: ${props => props.theme.body_text};
    }

    .ddk-container .layout-sidebar .menu-item.highlighted-item {
        box-shadow: inset 5px 0 0 0 ${props => props.theme.accent}
    }

    .ddk-container .layout-sidebar .menu--sub > .menu-item.highlighted-item {
        box-shadow: inset 0 -4px 0 0 ${props => props.theme.accent}
    }

    .ddk-container.ddk-container .layout-sidebar .menu-item:hover > a {
        color: ${props => props.theme.accent};
    }

    .ddk-container .layout-sidebar .menu-item:hover .caret {
        border-color: ${props => props.theme.accent};
    }

    .ddk-container .layout-sidebar .menu--sub #menu {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container .layout-header .menu-item:hover .caret {
        border-color: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .layout-header .menu-item:hover > a {
        color: ${props => props.theme.accent};
    }

    .ddk-container .layout-header .menu-item.highlighted-item {
        box-shadow: inset 0 -4px 0 0 ${props => props.theme.accent}
    }

    .ddk-container.ddk-container .layout-header:not([data-contentalignment="spread"]) nav#menu {
        margin-left: initial;
    }

    .ddk-container .layout-header .menu--sub nav#menu li.menu-item.highlighted-item,
    .ddk-container .layout-header nav#menu[data-open="true"][data-collapsed="true"] li.menu-item.highlighted-item {
        box-shadow: inset 5px 0 0 0 ${props => props.theme.accent}
    }

    .ddk-container .layout-header nav#menu[data-collapsed="true"] ul#menu-closed .menu--sub .menu-item a {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .ddk-container .layout-header nav#menu[data-collapsed="true"] ul#menu-closed .menu--sub .menu-item a .caret {
        margin-top: -6px;
        left: auto;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    .ddk-container .layout-header .menu--sub nav#menu {
        background-color: ${props => props.theme.background_content}
    }

    .ddk-container .layout-header .menu--sub #menu-items-container,
    .ddk-container .layout-header nav#menu[data-collapsed="true"] #menu-items-container {
        border: 1px solid ${props => props.theme.border}
        border-top: none;
    }

    .ddk-container .layout-header nav#menu[data-collapsed="true"] ul#menu-closed
    .menu--sub:hover nav#menu ul#menu-closed #menu-items-container {
        border-top: 1px solid ${props => props.theme.border};
    }

    @media only screen
        and (min-width: 0px)
        and (max-width: ${props => props.theme.breakpoint_stack_blocks}) {
        .ddk-container:not(.ddk-container--print-context) .block {
            width: 100% !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
            overflow-x: hidden;
        }
        .ddk-container:not(.ddk-container--print-context) .tabs-parent {
            flex-direction: column;
            width: 100%;
        }
       .ddk-container:not(.ddk-container--print-context)  .row {
            flex-direction: column;
        }
    }

    @media only screen and (max-width: 1080px) {
        .ddk-container:not(.ddk-container--print-context) .tab-container {
            flex-direction: column;
        }

        .ddk-container:not(.ddk-container--print-context)  .tab-container .tab {
            width: 100%;
        }
    }

    .ddk-container .sidebar--content {
        width: 100%;
        overflow: hidden;
    }

    .ddk-container .section-title {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.1em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        letter-spacing: 0.12em;
        position: relative;
        display: inline-block;
        padding-left: 1em;
        padding-right: 1em;
        vertical-align: middle;
    }

    .ddk-container .section-title .section-title--line {
        position: absolute;
        top: 50%;
        display: block;
        width: calc(100% - 2em); /* 2em = padding of parent .section-title x2 */
        height: 1px;
        background-color: ${props => props.theme.border};
    }

    .ddk-container .section-title .section-title--text {
        background-color: ${props => props.theme.background_page};
        position: relative;
        z-index: 2;
        padding-left: 10px;
        padding-right: 10px;
        display: inline;
    }

    .ddk-container .expandToFull svg .cls-1,
    .ddk-container .copyText svg .cls-1 {
        fill: ${props => props.theme.accent};
    }

    .ddk-container .expandToFull svg .cls-2,
    .ddk-container .copyText svg .cls-2,
    .ddk-container .copyText svg .cls-3 {
        fill: ${props => chroma(props.theme.accent).brighten()};
    }

    .ddk-container .expandToFull:hover svg .cls-1,
    .ddk-container .copyText:hover svg .cls-1 {
        fill: ${props => chroma(props.theme.accent).brighten()};
    }

    .ddk-container .expandToFull:hover svg .cls-2,
    .ddk-container .copyText:hover svg .cls-2 {
        fill: ${props => props.theme.accent};
    }

    .ddk-container .copyText.copied:before {
        border-color: ${props => props.theme.border} transparent transparent transparent;
    }

    .ddk-container .copyText.copied:after {
        border-color: ${props => props.theme.border};
    }

    .ddk-container.ddk-container .block.card.ddk-card .card-header .expandToFull--workspace:before,
    .ddk-container.ddk-container button.edit-theme-button.edit-theme-button--old-edge:hover:before {
        border-color: ${props => props.theme.accent_negative} transparent transparent transparent;
    }

    .ddk-container.ddk-container .copyText.copied:after,
    .ddk-container.ddk-container .block.card.ddk-card .card-header .expandToFull--workspace.expandToFull--fullscreen:after,
    .ddk-container.ddk-container button.edit-theme-button.edit-theme-button--old-edge:hover:after {
        background: ${props => props.theme.background_content};
        color: ${props => props.theme.text};
        border-color: ${props => props.theme.border};
    }

    .ddk-container.ddk-container .block.card.ddk-card .card-header .expandToFull--workspace.expandToFull--fullscreen:after,
    .ddk-container.ddk-container button.edit-theme-button.edit-theme-button--old-edge:hover:after {
        border-color: ${props => props.theme.accent_negative};
    }

    .ddk-container .notification-store {
        background-color: ${props => props.theme.background_content};
        border-right: ${props => `thin ${props.theme.border} solid`};
    }

    .ddk-container .notification--title {
        color: ${props => props.theme.text};
        border-top: ${props => `thin ${props.theme.border} solid`};
        border-bottom: ${props => `thin ${props.theme.border} solid`};
    }

    .ddk-container .notification .notification--delete,
    .ddk-container .notification .notification--collapse {
        color: ${props => props.theme.border};
    }

    .ddk-container .notification-store-mobile-button.notification-store--contains-danger,
    .ddk-container .notification-store.notification-store--contains-danger {
        box-shadow: ${props => `0 0 10px ${props.theme.accent_negative}`};
    }

    .ddk-container .notification--info {
        border-left: ${props => `5px ${props.theme.border} solid`};
        margin-left: -1px;
    }
    .ddk-container .notification--warn {
        border-left: ${props => `5px ${props.theme.accent} solid`};
        margin-left: -1px;
    }
    .ddk-container .notification--danger {
        border-left: ${props => `5px ${props.theme.accent_negative} solid`};
        margin-left: -1px;
    }
    .ddk-container .notification-store-mobile-button {
        background-color: ${props => props.theme.background_content}};
    }
    .ddk-container .notification-store-mobile-button i {
        color: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .notification-store {
        margin-right: ${() => is.mac() ? '20px' : ''}
    }

    .ddk-container.ddk-container .notification .notification--collapse {
        right: ${() => (is.mac() && is.firefox()) ? '30px' : ''}
    }

    .ddk-container.ddk-container .notification .notification--delete {
        right: ${() => (is.mac() && is.firefox()) ? '13px' : ''};
    }

    .ddk-container .notification .notification--title--inner {
        max-width: ${() => (is.mac() && is.firefox()) ? 'calc(100% - 35px)' : ''};
    }

    .ddk-container .card-body,
    .ddk-container .card-header,
    .ddk-container .control--label {
        color: ${props => props.theme.text};    
    }
    
    @media only screen
        and (max-width: ${props => props.theme.breakpoint_stack_blocks}) {
            .ddk-container .notification-store-mobile-button {
                display: flex;
            }
            .ddk-container .notification-store {
                width: calc(100% - 20px) !important;
                width: 90%;
                top: 75px;
                right: 0%;
                max-height: 50%;
                /* Width delay is a hack for having nice transition on responsive change to this view port */
                transition: width 0.5s 0.5s, height 3s, padding-top 3s, padding-bottom 3s;
            }

            .ddk-container .notification-store[folded='true'] {
                padding-top: 0px;
                padding-bottom: 0px;
                max-height: 0;
                visibility: hidden;
                transition: max-height 0.15s ease-out, padding-top 0.15s ease-out, padding-bottom 0.15s ease-out, width 0.15s 0.14s, visibility 0.05s cubic-bezier(1, 0.5, 0, 0);;
            }
            .ddk-container .notification-store[folded='false'] {
                max-height: 50%;
                transition: max-height 0.25s ease-in, padding-top 0.15s ease-in, padding-bottom 0.15s ease-in;
            }
        }

    /* Control label alignment */

    .ddk-container .control.label--text--left .control--label + .control--item button {
        margin-left: 8px;
    }
    .ddk-container .control.label--text--left .control--label + .control--item
    .Select--single > .Select-control .Select-value,
    .ddk-container .control.label--text--left .control--label + .control--item
    .Select-placeholder {
        padding-left: ${props =>
            (props.theme.background_content !== props.theme.control_background_color)
            || (parseInt(getValueFromCSSText(
                'borderLeftWidth',
                'borderWidth',
                props.theme.control_border.width
            ), 10) === 0)
              ? '8px'
              : ''
        };
    }
    .ddk-container .control.label--text--left .control--label + .control--item
    .Select--multi .Select-multi-value-wrapper {
        padding-left: ${props =>
            (props.theme.background_content !== props.theme.control_background_color)
            || (parseInt(getValueFromCSSText(
                'borderLeftWidth',
                'borderWidth',
                props.theme.control_border.width
            ), 10) === 0)
              ? '3px'
              : ''
        }
    }
    .ddk-container .control.label--text--left .control--label + .control--item input[type="text"] {
        padding-left: ${props =>
            (props.theme.background_content !== props.theme.control_background_color)
            || (parseInt(getValueFromCSSText(
                'borderLeftWidth',
                'borderWidth',
                props.theme.control_border.width
            ), 10) === 0)
              ? '7px'
              : ''
        }
    }

    .ddk-container .control.label--text--left .control--label + .control--item > div:not(.rc-slider),
    .ddk-container .control.label--text--left .control--label + .control--item .dash-dropdown,
    .ddk-container .control.label--text--left .control--label + .control--item > input[type="text"] {
        margin: ${props =>
            (props.theme.background_content !== props.theme.control_background_color)
            || (parseInt(getValueFromCSSText(
                'borderLeftWidth',
                'borderWidth',
                props.theme.control_border.width
            ), 10) > 0)
              ? '0px 8px'
              : ''
        }
    }

    .ddk-container .control.label--text--left .control--label + .control--item > textarea {
        margin-left: 8px;
    }
`
export default themedStyles;
