import React from 'react';
import PropTypes from 'prop-types';

// from https://techoverflow.net/2018/03/30/copying-strings-to-the-clipboard-using-pure-javascript/
function copyStringToClipboard (str, customWindow) {
    let doc;
    if (customWindow) {
        doc = customWindow.document
    } else {
        doc = window.document;
    }
    // Create new element
    var el = doc.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    doc.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    doc.execCommand('copy');
    // Remove temporary element
    doc.body.removeChild(el);
}

/**
 * This component copies the innerText at the given id to the clipboard`
 */
export default class CopyText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {copied: false};

        this.copyInnerText = this.copyInnerText.bind(this);
    }

    copyInnerText() {
        const element = this.props.cardRef && this.props.cardRef.querySelectorAll(
          'pre code'
        )[0] || this.props.cardRef || document.getElementById(this.props.target_id);
        const innerText = (element && element.innerText)
          ? element.innerText
          : this.props.literalText || '';
        copyStringToClipboard(innerText.toString(), this.props.customWindow);
        this.setState({copied: true})
        setTimeout(() => this.setState({copied: false}), 2000);
    }

    render() {
        const { children, id, className, style} = this.props;
        const copied = this.state.copied;
        return (
            <div
                id={id}
                className={`copyText ${copied ? 'copied' : ''} ${className}`}
                style={{...style}}
                onClick={this.copyInnerText}
            >
                {children}
        </div>
        )
    }
};

CopyText.defaultProps = {
    className: ''
};

CopyText.propTypes = {
    /**
     * The optional id of the component
     */
    id: PropTypes.string,

    /**
     * The optional className of the component
     */
    className: PropTypes.string,

    /**
     * The element that, when clicked, will copy the innerText of the given id
     */
    children: PropTypes.node,

    /**
     * A reference to a card to expand
     */
    cardRef: PropTypes.node,

    /**
     * The id of the DOM element that contains the innerText to copy
     */
    target_id: PropTypes.string,

    /**
     * The width of the element when expanded (% or px)
     */
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * Dash-assigned callback that gets fired when the value changes.
     */
    setProps: PropTypes.any,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,
};
