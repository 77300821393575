const Plotly_Light = {
    accent: "#7A76FF",
    accent_positive: "#87C795",
    accent_negative: "#D7645F",
    background_content: "#FFFFFF",
    background_page: "#F1F1F1",
    body_text: "#333333",
    border: "#CCCCCC",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: 0,
    },
    button_border: {
        width: "1px",
        color: "#7A76FF",
        radius: "4px"
    },
    button_capitalization: "none",
    button_text: "#FFFFFF",
    button_background_color: "#7A76FF",
    control_border: {
        width: "1px 1px 1px 1px",
        color: "#CCCCCC",
        radius: "4px"
    },
    control_background_color: "#FFFFFF",
    control_text: "#7A76FF",
    card_margin: "20px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#e2e2e2",
        radius: "8px",
    },
    card_background_color: "#FFFFFF",
    card_box_shadow: "0px 0px 5px rgba(0,0,0,0)",
    card_outline: {
        width: "0px",
        style: "solid",
        color: "#CCCCCC"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "0px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#CCCCCC",
        radius: "0px",
    },
    card_header_background_color: "#FFFFFF",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#7A76FF",
        "#D95077",
        "#C1C1F9",
        "#FDC646",
        "#D381A3",
        "#2456F6",
        "#9ADCF7",
        "#F369CF",
        "#70D362",
        "#158885",
    ],
    colorscale: [
        "#eaeaff",
        "#d9ceff",
        "#c8b2ff",
        "#b696ff",
        "#a57aff",
        "#8e6af6",
        "#765bec",
        "#5f4be3",
        "#473cd9",
        "#302cd0",
    ],
    dbc_primary: "#7A76FF",
    dbc_secondary: "#D381A3",
    dbc_info: "#6296E8",
    dbc_gray: "#CCCCCC",
    dbc_success: "#87C795",
    dbc_warning: "#F0C871",
    dbc_danger: "#D7645F",
    font_family: "Open Sans",
    font_family_header: "Open Sans",
    font_family_headings: "Open Sans",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "uppercase",
    header_content_alignment: "spread",
    header_margin: "0px 0px 24px 0px",
    header_padding: "0px 16px 0px 0px",
    header_border: {
        width: "0px 0px 0px",
        style: "solid",
        color: "#e2e2e2",
        radius: "0px",
    },
    header_background_color: "#FFFFFF",
    header_box_shadow: "0px 0px 5px rgba(0,0,0,0.08)",
    header_text: "#333333",
    heading_text: "#333333",
    text: "#333333",
    graph_grid_color: "#CCCCCC",
    table_striped_even: "#FFFFFF",
    table_striped_odd: "#FFFFFF",
    table_border: "#CCCCCC",
    card_accent: "#7A76FF",
    report_background: "#F1F1F1",
    report_background_content: "#FFFFFF",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "Open Sans",
    report_font_size: "12px",
    report_border: "#CCCCCC",
};

export default Plotly_Light;