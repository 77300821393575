/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import layoutPolyfill from './../polyfill/layout';
import hrefEqual from '../util/hrefEqual.js';

import { History } from '@plotly/dash-component-plugins';

/**
 * A Menu displays a list of `html.A` (for absolute links),
 * `dcc.Link` (for fast, in-app relative links),
 * or `ddk.CollapsibleMenu` (a collapsible menu) components.
 *
 * This component is designed to be embedded inside a `ddk.Header` or
 * a `ddk.Sidebar` component.
 *
 * *Example Usage*
 *
 * ```
 * ddk.Header(
 *     children=ddk.Menu([
 *         ddk.CollapsibleMenu(
 *             title='Monetary Data',
 *             children=[
 *                 dcc.Link(
 *                     'Monetary Base',
 *                     href='/monetary-base'
 *                 ),
 *                 dcc.Link(
 *                     'Money Velocity',
 *                     href='/money-velocity'
 *                 ),
 *                 dcc.Link(
 *                     'Reserves',
 *                     href='/reserves'
 *                 ),
 *                 dcc.Link(
 *                     'Borrowings',
 *                     href='/borrowings'
 *                 )
 *             ]
 *         ),
 *         dcc.Link('Conditions', href='/conditions'),
 *         dcc.Link('Investment', href='/investments'),
 *         dcc.Link('Other', href='/other'),
 *     ])
 * )
 * ```
 *
 */
export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            open: false,
            folded: false
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.isOverflow = this.isOverflow.bind(this);
        this.onLocationChanged = this.onLocationChanged.bind(this);
    }

    onLocationChanged() {
        this.forceUpdate();
    }

    isOverflow(el1, el2) {
        return el2.getBoundingClientRect().width > el1.getBoundingClientRect().width;
    }

    componentDidMount() {
        this.updateDimensions();
        const node = this.menu;
        this._sidebarParent = (
            node?.parentElement?.classList?.contains('sidebar--content') &&
            node.parentElement
        ) || null

        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("dash-theme-update", this.updateDimensions);

        // For compatibility with Dash <= 1.8.0
        window.addEventListener("onpushstate", this.onLocationChanged);
        // For Dash > 1.8.0
        this._clearOnLocationChanged = History.onChange(this.onLocationChanged);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("dash-theme-update", this.updateDimensions);

        // For compatibility with Dash <= 1.8.0
        window.removeEventListener("onpushstate", this.onLocationChanged);
        // For Dash > 1.8.0
        this._clearOnLocationChanged();
    }

    updateDimensions() {
        const overflowed = this.isOverflow(this.menu, this.menuItemsContainer)
        this.setState({ collapsed : overflowed });
        if (overflowed) {
            this.setState({ open : false });
        }
    }

    render() {
        const { children, className, style } = this.props;
        const { open, collapsed, folded } = this.state;

        const menuItems = React.Children.map(children, (child) => {
            return (layoutPolyfill.getType(child) !== "CollapsibleMenu") ? (
                <li
                    className={
                        'menu-item' +
                        (hrefEqual(layoutPolyfill.getProps(child).href, window.location.pathname) ?
                            ' highlighted-item' :
                            '') +
                        ' menu-item--no-text-decoration'
                    }
                >
                    {child}
                </li>
            ) : child
        });

        return (
            <nav
                className={className}
                style={style}
                id="menu"
                ref={el => (this.menu = el)}
                data-open={open}
                data-collapsed={collapsed}
                data-folded={folded}
            >
                {this._sidebarParent && (
                    <div className="toggleNarrow"
                        onClick={ () => {
                                const sidebar = this._sidebarParent;
                                const folded = sidebar.hasAttribute('folded');
                                if (sidebar.hasAttribute("folded")) {
                                    sidebar.removeAttribute("folded")
                                }
                                else {
                                    this._sidebarParent.setAttribute('folded', "");
                                }
                                this.setState({folded: !folded})
                            }
                        }
                    >
                        <div
                            className={'hamburger hamburger--minus' + (folded ? '' : ' is-active')}
                        >
                          <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                          </span>
                        </div>
                    </div>
                )}
                {collapsed && (
                    <div
                        onClick={ () => this.setState({
                            open: !open
                        })}
                        className={'dots ' + (open ? 'checked' : '')}
                    >
                        <div className="dot"></div>
                    </div>
                )}
                <ul id="menu-closed">
                    <div id="menu-items-container" ref={el => (this.menuItemsContainer = el)}>
                        {menuItems}
                    </div>
                </ul>
            </nav>
        )
    }
}

Menu.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * A list of `html.A`, `dcc.Link`, or `ddk.CollapsibleMenu` components
     */
    children: PropTypes.node,

    /**
     * The className applied to the outermost container of the Menu (a nav component).
     */
    className: PropTypes.string,

    /**
     * The style applied to the outermost container of the Menu (a nav component).
     */
    style: PropTypes.object,

    /**
     * Dash-assigned callback that gets fired when the value changes.
     */
    setProps: PropTypes.any,
};
