/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {propOr} from 'ramda';

/**
 * A responsive container positioned to the right of
 * an app's `ddk.Sidebar`. It takes up the remaining horizontal space
 * on the screen to the right of the `Sidebar`. When the `Sidebar` folds,
 * the `SidebarCompanion` expands.
 *
 * `SidebarCompanion` shouldn't be used in any other context besides
 * being positioned next to the `Sidebar`.
 */
function SidebarCompanion(props) {
    return (
        <div
            id={props.id}
            className={`layout-sidebar-companion ${propOr('', 'className', props)}`}
            style={props.style}
        >
            {props.children}
        </div>
    );
}

SidebarCompanion.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * Optional user-defined CSS class for the SidebarCompanion component.
     */
    className: PropTypes.string,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,

    /**
     * The list of components that are children of the SidebarCompanion container.
     */
    children: PropTypes.node
};

export default SidebarCompanion;
