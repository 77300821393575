import { css } from '../utils.js';

const themedStyles = css`
    .ddk-container.ddk-container .dash-table-container button:not(.btn):not(.edit-theme-button):not([type="button"]), .ddk-container.ddk-container .dash-table-container input[type="submit"], .ddk-container.ddk-container .dash-table-container input[type="reset"], .ddk-container.ddk-container .dash-table-container input[type="button"] {
        border: none;
    }

    /* Centered checkboxes */
    .ddk-container.ddk-container td.dash-select-cell input[type=checkbox] {
        float: none;
        display: inline-block;
        margin-right: 0;
    }
    .ddk-container.ddk-container td.dash-select-cell {
        /*
         * 1em = w/h of DDK checkboxes
         * - 100% resets odd inner top margin from td */
        line-height: calc(1em - 100%);
    }
`
export default themedStyles
