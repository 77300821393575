import { clone } from 'ramda';
import { extend, setThemeVar } from './utils';
import tinycolor from 'tinycolor2';
import themes_set from './themes/themes';

export const CLASSNAMES = {
    'ddk-container': 'ddk-container',
    'ddk-page': 'ddk-page'
}

export const FONT_SIZES = {
    SMALLER: {
        '>font-breakpoint': '15px',
        '<font-breakpoint': '13px',
        header: '18px'
    },
    REGULAR: {
        '>font-breakpoint': '17px',
        '<font-breakpoint': '15px',
        header: '24px'
    },
    LARGER: {
        '>font-breakpoint': '19px',
        '<font-breakpoint': '17px',
        header: '30px'
    }
}

export const COLORWAYS = {
    categorical: ['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99'],

    turbid: ["rgb(232, 245, 171)", "rgb(220, 219, 137)", "rgb(209, 193, 107)", "rgb(199, 168, 83)",
             "rgb(186, 143, 66)", "rgb(170, 121, 60)", "rgb(151, 103, 58)", "rgb(129, 87, 56)",
             "rgb(104, 72, 53)", "rgb(80, 59, 46)", "rgb(57, 45, 37)", "rgb(34, 30, 27)"],

    thermal: ["rgb(3, 35, 51)", "rgb(13, 48, 100)", "rgb(53, 50, 155)", "rgb(93, 62, 153)",
              "rgb(126, 77, 143)", "rgb(158, 89, 135)", "rgb(193, 100, 121)", "rgb(225, 113, 97)",
              "rgb(246, 139, 69)", "rgb(251, 173, 60)", "rgb(246, 211, 70)", "rgb(231, 250, 90)"],

    haline: ["rgb(41, 24, 107)", "rgb(42, 35, 160)", "rgb(15, 71, 153)", "rgb(18, 95, 142)",
             "rgb(38, 116, 137)", "rgb(53, 136, 136)", "rgb(65, 157, 133)", "rgb(81, 178, 124)",
             "rgb(111, 198, 107)", "rgb(160, 214, 91)", "rgb(212, 225, 112)", "rgb(253, 238, 153)"],

    solar: ["rgb(51, 19, 23)", "rgb(79, 28, 33)", "rgb(108, 36, 36)", "rgb(135, 47, 32)",
            "rgb(157, 66, 25)", "rgb(174, 88, 20)", "rgb(188, 111, 19)", "rgb(199, 137, 22)",
            "rgb(209, 164, 32)", "rgb(217, 192, 44)", "rgb(222, 222, 59)", "rgb(224, 253, 74)"],

    ice: ["rgb(3, 5, 18)", "rgb(25, 25, 51)", "rgb(44, 42, 87)", "rgb(58, 60, 125)", "rgb(62, 83, 160)",
          "rgb(62, 109, 178)", "rgb(72, 134, 187)", "rgb(89, 159, 196)", "rgb(114, 184, 205)",
          "rgb(149, 207, 216)", "rgb(192, 229, 232)", "rgb(234, 252, 253)"],

    gray: ["rgb(0, 0, 0)", "rgb(16, 16, 16)", "rgb(38, 38, 38)", "rgb(59, 59, 59)", "rgb(81, 80, 80)",
           "rgb(102, 101, 101)", "rgb(124, 123, 122)", "rgb(146, 146, 145)", "rgb(171, 171, 170)",
           "rgb(197, 197, 195)", "rgb(224, 224, 223)", "rgb(254, 254, 253)"],

    oxy: ["rgb(63, 5, 5)", "rgb(101, 6, 13)", "rgb(138, 17, 9)", "rgb(96, 95, 95)", "rgb(119, 118, 118)",
          "rgb(142, 141, 141)", "rgb(166, 166, 165)", "rgb(193, 192, 191)", "rgb(222, 222, 220)",
          "rgb(239, 248, 90)", "rgb(230, 210, 41)", "rgb(220, 174, 25)"],

    deep: ["rgb(253, 253, 204)", "rgb(206, 236, 179)", "rgb(156, 219, 165)", "rgb(111, 201, 163)",
           "rgb(86, 177, 163)", "rgb(76, 153, 160)", "rgb(68, 130, 155)", "rgb(62, 108, 150)",
           "rgb(62, 82, 143)", "rgb(64, 60, 115)", "rgb(54, 43, 77)", "rgb(39, 26, 44)"],

    dense: ["rgb(230, 240, 240)", "rgb(191, 221, 229)", "rgb(156, 201, 226)", "rgb(129, 180, 227)",
            "rgb(115, 154, 228)", "rgb(117, 127, 221)", "rgb(120, 100, 202)", "rgb(119, 74, 175)",
            "rgb(113, 50, 141)", "rgb(100, 31, 104)", "rgb(80, 20, 66)", "rgb(54, 14, 36)"],

    algae: ["rgb(214, 249, 207)", "rgb(186, 228, 174)", "rgb(156, 209, 143)", "rgb(124, 191, 115)",
            "rgb(85, 174, 91)", "rgb(37, 157, 81)", "rgb(7, 138, 78)", "rgb(13, 117, 71)", "rgb(23, 95, 61)",
            "rgb(25, 75, 49)", "rgb(23, 55, 35)", "rgb(17, 36, 20)"],

    matter: ["rgb(253, 237, 176)", "rgb(250, 205, 145)", "rgb(246, 173, 119)", "rgb(240, 142, 98)",
             "rgb(231, 109, 84)", "rgb(216, 80, 83)", "rgb(195, 56, 90)", "rgb(168, 40, 96)",
             "rgb(138, 29, 99)", "rgb(107, 24, 93)", "rgb(76, 21, 80)", "rgb(47, 15, 61)"],

    speed: ["rgb(254, 252, 205)", "rgb(239, 225, 156)", "rgb(221, 201, 106)", "rgb(194, 182, 59)",
            "rgb(157, 167, 21)", "rgb(116, 153, 5)", "rgb(75, 138, 20)", "rgb(35, 121, 36)", "rgb(11, 100, 44)",
            "rgb(18, 78, 43)", "rgb(25, 56, 34)", "rgb(23, 35, 18)"],

    amp: ["rgb(241, 236, 236)", "rgb(230, 209, 203)", "rgb(221, 182, 170)", "rgb(213, 156, 137)",
          "rgb(205, 129, 103)", "rgb(196, 102, 73)", "rgb(186, 74, 47)", "rgb(172, 44, 36)", "rgb(149, 19, 39)",
          "rgb(120, 14, 40)", "rgb(89, 13, 31)", "rgb(60, 9, 17)"],

    tempo: ["rgb(254, 245, 244)", "rgb(222, 224, 210)", "rgb(189, 206, 181)", "rgb(153, 189, 156)",
            "rgb(110, 173, 138)", "rgb(65, 157, 129)", "rgb(25, 137, 125)", "rgb(18, 116, 117)", "rgb(25, 94, 106)",
            "rgb(28, 72, 93)", "rgb(25, 51, 80)", "rgb(20, 29, 67)"],

    phase: ["rgb(167, 119, 12)", "rgb(197, 96, 51)", "rgb(217, 67, 96)", "rgb(221, 38, 163)", "rgb(196, 59, 224)",
            "rgb(153, 97, 244)", "rgb(95, 127, 228)", "rgb(40, 144, 183)", "rgb(15, 151, 136)", "rgb(39, 153, 79)",
            "rgb(119, 141, 17)", "rgb(167, 119, 12)"],

    balance: ["rgb(23, 28, 66)", "rgb(41, 58, 143)", "rgb(11, 102, 189)", "rgb(69, 144, 185)", "rgb(142, 181, 194)",
              "rgb(210, 216, 219)", "rgb(230, 210, 204)", "rgb(213, 157, 137)", "rgb(196, 101, 72)", "rgb(172, 43, 36)",
              "rgb(120, 14, 40)", "rgb(60, 9, 17)"],

    plotly: ["#09ffff", "#19d3f3", "#e763fa", "#ab63fa", "#636efa", "#00cc96", "#EF553B"],

    delta: ["rgb(16, 31, 63)", "rgb(38, 62, 144)", "rgb(30, 110, 161)", "rgb(60, 154, 171)", "rgb(140, 193, 186)",
            "rgb(217, 229, 218)", "rgb(239, 226, 156)", "rgb(195, 182, 59)", "rgb(115, 152, 5)", "rgb(34, 120, 36)",
            "rgb(18, 78, 43)", "rgb(23, 35, 18)"],

    curl: ["rgb(20, 29, 67)", "rgb(28, 72, 93)", "rgb(18, 115, 117)", "rgb(63, 156, 129)", "rgb(153, 189, 156)",
           "rgb(223, 225, 211)", "rgb(241, 218, 206)", "rgb(224, 160, 137)", "rgb(203, 101, 99)", "rgb(164, 54, 96)",
    "rgb(111, 23, 91)", "rgb(51, 13, 53)"]
}

export const CUSTOMIZED_COLORPICKER_COLORS = [
    // designed by http://clrs.cc/ & sidi
    '#004172', // SIDI NAVY
    '#1a9dff', // SIDI BLUE
    '#b9cfed', // SIDI INK BLUE
    '#80fcff', // SIDI AQUA
    '#3D9970', // CLRS OLIVE
    '#2ECC40', // CLRS GREEN
    '#75c9be', // SIDI TEAL GREEN
    '#2bfebe', // SIDI NEON GREEN
    '#ffd15f', // SIDI YELLOW
    '#fde725', // VIRIDIS YELLOW
    '#b79a5e', // SIDI BROWN
    '#ff7f1f', // SIDI ORANGc
    '#e80a0a', // SIDI RED
    '#ff2c6d', // SIDI PINK
    '#A239CA', // CLRS FUCHSIA
    '#813772', // CLRS POSY
    '#dddddd', // SIDI GREY
    '#aaaaaa', // SIDI SILVER
    '#ffffff', // WHITE
    '#232323', // SIDI BLACK
];

export const BOX_SHADOWS = {
    none: {
        name: 'none',
        boxShadow: 'none'
    },
    light: {
        name: 'light',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
    },
    medium: {
        name: 'medium',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
    },
    heavy: {
        name: 'heavy',
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
    }
}

export const BORDERS = {
    underlined: {
        name             : 'underlined',
        borderWidth      : '0px 0px 1px 0px',
        borderStyle      : 'solid',
        borderRadius     : 0
    },

    rounded: {
        name: 'rounded',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '3px'
    },

    square: {
        name: 'square',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 0
    },

    double: {
        name: 'double',
        borderWidth: '4px',
        borderStyle: 'double',
        borderRadius: 0
    },

    threeD: {
        name: 'threeD',
        borderWidth: '3px',
        borderStyle: 'outset',
        borderRadius: 0
    }
}

// The rest of the theme colors are embedded in the `ThemeEditor`
/* THEMES.light is used as a "reference" theme in App to ensure no
   undefined variables */

/* Reminder: any new variables should be populated
   across this app - see
   https://github.com/plotly/dash-design-kit/pull/273 */

export const THEMES = {
    init : function() {
        window.dashTheme = this.light;
        delete this.init;
        return this;
    },
    light: {
        accent:             '#1f78b4',
        accent_positive:    '#017500',
        accent_negative:    '#C20000',
        background_content: '#F9F9F9',
        background_page:    '#F2F2F2',
        get body_text() { return this.text; },
        set body_text(value) {
            setThemeVar('body_text', value, this);
        },
        border: '#e2e2e2',
        border_style: BORDERS.underlined,
        button_border: function() { return {
            width: '1px',
            get color() { return window.dashTheme.accent; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.button_border);
            },
            radius: '0px'
        }}(),
        button_capitalization: 'uppercase',
        get button_text() { return this.accent; },
        set button_text(value) {
            setThemeVar('button_text', value, this);
        },
        get button_background_color() { return this.background_content; },
        set button_background_color(value) {
            setThemeVar('button_background_color', value, this);
        },
        control_border: function() { return {
            width: '0px 0px 1px 0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.control_border);
            },
            radius: '0px'
        }}(),
        get control_background_color() { return this.background_content; },
        set control_background_color(value) {
            setThemeVar('control_background_color', value, this);
        },
        get control_text() { return this.text; },
        set control_text(value) {
            setThemeVar('control_text', value, this);
        },
        card_margin:        '15px',
        card_padding:       '5px',
        card_border: function() { return {
            width:          '0px 0px 0px 0px',
            style:          'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            },
            radius:         '0px',
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_box_shadow:     '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)',
        /* partial workaround for overlapping borders with 0 margins */
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),

        card_header_margin: '0px',
        card_header_padding: '10px',
        card_header_border: function() { return {
            width:           '0px 0px 1px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get card_header_background_color() { return this.background_content; },
        set card_header_background_color(value) {
            setThemeVar('card_header_background_color', value, this);
        },
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        get card_accent() { return this.accent; },
        set card_accent(value) {
            setThemeVar('card_accent', value, this);
        },
        breakpoint_font: '1200px',
        // If a user has 2 graphs stacked side-by-side,
        // then the graphs will become at most 350px wide
        breakpoint_stack_blocks: '700px',

        colorway: [
            "#119dff",
            "#66c2a5",
            "#fc8d62",
            "#e78ac3",
            "#a6d854",
            "#ffd92f",
            "#e5c494",
            "#b3b3b3"
        ],
        colorscale: [
            "#1f78b4",
            "#4786bc",
            "#6394c5",
            "#7ba3cd",
            "#92b1d5",
            "#a9c0de",
            "#bed0e6",
            "#d4dfee",
            "#eaeff7",
            "#ffffff"
        ],
        get dbc_primary() { return this.accent; },
        set dbc_primary(value) {
            setThemeVar('dbc_primary', value, this);
        },
        get dbc_secondary() {
            return tinycolor(this.background_content).isLight()
              ? tinycolor(this.border).darken(40).toString()
              : tinycolor(this.border).lighten(40).toString();
        },
        set dbc_secondary(value) {
            setThemeVar('dbc_secondary', value, this);
        },
        dbc_info: '#009AC7',
        dbc_gray: '#adb5bd',
        get dbc_success() { return this.accent_positive; },
        set dbc_success(value) {
            setThemeVar('dbc_success', value, this);
        },
        dbc_warning: '#F9F871',
        get dbc_danger() { return this.accent_negative; },
        set dbc_danger(value) {
            setThemeVar('dbc_danger', value, this);
        },
        font_family:              'Open Sans',
        font_family_header:       'Open Sans',
        font_family_headings:     'Open Sans',
        font_size:                FONT_SIZES.REGULAR['>font-breakpoint'],
        font_size_smaller_screen: FONT_SIZES.REGULAR['<font-breakpoint'],
        font_size_header:         FONT_SIZES.REGULAR.header,
        title_capitalization:   'uppercase',
        header_content_alignment: 'spread',
        header_margin:           '0px 0px 15px 0px',
        header_padding:          '0px',
        header_border: function() { return {
            width:           '0px 0px 0px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.header_border);
            },
            radius:          '0px'
        }}(),
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        get header_box_shadow() { return this.card_box_shadow; },
        set header_box_shadow(value) {
            setThemeVar('header_box_shadow', value, this);
        },
        get header_text() { return this.text; },
        set header_text(value) {
            setThemeVar('header_text', value, this);
        },
        get heading_text() { return this.text; },
        set heading_text(value) {
            setThemeVar('heading_text', value, this);
        },
        text: '#606060',
        get report_background() { return this.background_page; },
        set report_background(value) {
            setThemeVar('report_background', value, this);
        },
        report_background_content: '#FAFBFC',
        report_background_page: 'white',
        report_text: 'black',
        report_font_family: 'Computer Modern',
        report_font_size: '12px',
        get report_border() { return this.border; },
        set report_border(value) {
            setThemeVar('report_border', value, this);
        },
        get graph_grid_color() { return this.border; },
        set graph_grid_color(value) {
            setThemeVar('graph_grid_color', value, this);
        },
        table_striped_even: 'rgba(255,255,255,0)',
        table_striped_odd: 'rgba(0,0,0,0.05)',
        get table_border() { return this.border; },
        set table_border(value) {
            setThemeVar('table_border', value, this);
        },

    },
    // fonts and colors subset for Preset tab
    light_preset_subset: {
        accent:             '#1f78b4',
        accent_positive:    '#017500',
        accent_negative:    '#C20000',
        background_content: '#F9F9F9',
        background_page:    '#F2F2F2',
        get body_text() { return this.text; },
        set body_text(value) {
            setThemeVar('body_text', value, this);
        },
        border: '#e2e2e2',
        get button_text() { return this.accent; },
        set button_text(value) {
            setThemeVar('button_text', value, this);
        },
        get button_background_color() { return this.background_content; },
        set button_background_color(value) {
            setThemeVar('button_background_color', value, this);
        },
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        get card_header_background_color() { return this.background_content; },
        set card_header_background_color(value) {
            setThemeVar('card_header_background_color', value, this);
        },
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        get card_accent() { return this.accent; },
        set card_accent(value) {
            setThemeVar('card_accent', value, this);
        },
        get control_background_color() { return this.background_content; },
        set control_background_color(value) {
            setThemeVar('control_background_color', value, this);
        },
        get control_text() { return this.text; },
        set control_text(value) {
            setThemeVar('control_text', value, this);
        },
        get dbc_primary() { return this.accent; },
        set dbc_primary(value) {
            setThemeVar('dbc_primary', value, this);
        },
        get dbc_secondary() {
            return tinycolor(this.background_content).isLight()
              ? tinycolor(this.border).darken(35).toString()
              : tinycolor(this.border).lighten(35).toString();
        },
        set dbc_secondary(value) {
            setThemeVar('dbc_secondary', value, this);
        },
        dbc_info: '#009AC7',
        dbc_gray: '#adb5bd',
        get dbc_success() { return this.accent_positive; },
        set dbc_success(value) {
            setThemeVar('dbc_success', value, this);
        },
        dbc_warning: '#F9F871',
        get dbc_danger() { return this.accent_negative; },
        set dbc_danger(value) {
            setThemeVar('dbc_danger', value, this);
        },
        colorway: [
            "#119dff",
            "#66c2a5",
            "#fc8d62",
            "#e78ac3",
            "#a6d854",
            "#ffd92f",
            "#e5c494",
            "#b3b3b3"
        ],
        colorscale: [
            "#1f78b4",
            "#4786bc",
            "#6394c5",
            "#7ba3cd",
            "#92b1d5",
            "#a9c0de",
            "#bed0e6",
            "#d4dfee",
            "#eaeff7",
            "#ffffff"
        ],
        font_family:              'Open Sans',
        font_family_header:       'Open Sans',
        font_family_headings:     'Open Sans',
        font_size:                FONT_SIZES.REGULAR['>font-breakpoint'],
        font_size_smaller_screen: FONT_SIZES.REGULAR['<font-breakpoint'],
        font_size_header:         FONT_SIZES.REGULAR.header,
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        text: '#606060',
        get header_text() { return this.text; },
        set header_text(value) {
            setThemeVar('header_text', value, this);
        },
        get heading_text() { return this.text; },
        set heading_text(value) {
            setThemeVar('heading_text', value, this);
        },
        get report_background() { return this.background_page; },
        set report_background(value) {
            setThemeVar('report_background', value, this);
        },
        report_background_content: '#FAFBFC',
        report_background_page: 'white',
        report_text: 'black',
        report_font_family: 'Computer Modern',
        report_font_size: '12px',
        get report_border() { return this.border; },
        set report_border(value) {
            setThemeVar('report_border', value, this);
        },
        get graph_grid_color() { return this.border; },
        set graph_grid_color(value) {
            setThemeVar('graph_grid_color', value, this);
        },
        table_striped_even: 'rgba(255,255,255,0)',
        table_striped_odd: 'rgba(0,0,0,0.05)',
        get table_border() { return this.border; },
        set table_border(value) {
            setThemeVar('table_border', value, this);
        }
    },
}.init();

export const CONTROL_BORDERS = {
    width: {
        minimal: '0px 0px 1px 0px',
        future_console: '1px 1px 4px 1px',
        bordered: '1px',
        none: '0px'
    },
    radius: {
        sharp: '0px',
        rounded: '5px',
        oval: '30px'
    }
}

export const BUTTON_BORDERS = {
    width: {
        '3D': '0px 0px 4px 0px'
    },
    radius: {
        sharp: '0px',
        rounded: '5px',
        oval: '30px'
    }
}

// TODO: consider whether map over merge is the best approach here
export const CONTAINER_THEMES = {
    box_shadow : {
        card_border: function() { return {
            width: '0px',
            radius: '0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_box_shadow: '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),
        card_margin: '15px',
        card_header_border: function() { return {
            width:           '0px 0px 1px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.header_border);
            },
            radius:          '0px'
        }}(),
        header_margin:           '0px 0px 15px 0px',
        get header_box_shadow() { return this.card_box_shadow; },
        set header_box_shadow(value) {
            setThemeVar('header_box_shadow', value, this);
        },
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
    },
    minimal_and_flat: {
        card_border: function() { return {
            'width': '0px',
            'radius': '0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            }
        }}(),
        card_header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        card_margin: '15px',
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.header_border);
            },
            radius:          '0px'
        }}(),
        header_margin:           '0px 0px 15px 0px',
        header_box_shadow: 'none',
    },
    tight_and_bordered: {
        card_margin: 0,
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_border: function() { return {
            'width': '1px',
            'radius': '0px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        card_outline: function() { return {
            width: '1px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            }
        }}(),
        card_header_border: function() { return {
            width:           '0px 0px 1px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        card_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        header_border: function() { return {
            width:           '0px 0px 1px 0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get header_background_color() { return this.background_page; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        header_margin: '0px',
        header_box_shadow: 'none'
    },
    future_console: {
        card_header_border: function() { return {
            style: 'dashed',
            width: '0px 0px 2px 0px',
            get color() { return window.dashTheme.card_border.color; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get card_background_color() { return this.background_content; },
        set card_background_color(value) {
            setThemeVar('card_background_color', value, this);
        },
        card_outline: function() { return {
            width: '0px',
            style: 'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_outline);
            },
        }}(),
        card_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_header_box_shadow: '0px 0px 0px rgba(0,0,0,0)',
        card_border: function() { return {
            width: '1px',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_border);
            }
        }}(),
        card_margin: '15px',
        header_border: function() { return {
            width:           '0px',
            style:           'solid',
            get color() { return window.dashTheme.border; },
            set color(value) {
                setThemeVar('color', value, window.dashTheme.card_header_border);
            },
            radius:          '0px'
        }}(),
        get header_background_color() { return this.background_content; },
        set header_background_color(value) {
            setThemeVar('header_background_color', value, this);
        },
        header_margin: '0px 0px 15px 0px',
        header_box_shadow: 'none'
    }
}

const PRESET_THEMES_NEW = () => {
    const themes = [];
    for (let i=0; i<themes_set.length; i++){
        themes.push(themes_set[i].theme);
    }
    return themes;
};
export const PRESET_THEMES = PRESET_THEMES_NEW().map(theme_preset => {
    // var base = extend({}, THEMES.light);
    var base = extend({}, THEMES.light_preset_subset);
    var preset = clone(theme_preset);
    var extended_preset = extend(base, preset)
    return extended_preset;
});
