import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { propOr, mergeRight } from 'ramda';
import useForceUpdate from 'use-force-update';

import { ReportContext } from './Report.react'
import { templateLiteralDedent } from '../utils';
import { FONT_SIZES } from '../constants';

function getJustifyContent(position) {
    return position === 'right'
      ? 'flex-end'
      : position === 'center'
        ? 'center'
        : 'flex-start'
}

function getFontSize(size) {
    return FONT_SIZES[size?.toUpperCase()]?.header || size;
}

/**
 * An app-level header component.
 *
 * **Example Usage**
 * ```
 * ddk.Header([
 *     ddk.Title('FRED Economic Indicators'),
 *     ddk.Logo(src='/assets/my-logo.png'),
 *     ddk.Menu([
 *         ddk.CollapsibleMenu(
 *             title='Monetary Data',
 *             children=[
 *                 dcc.Link(
 *                 'Monetary Base',
 *                 href='/monetary-base'
 *             ),
 *             dcc.Link(
 *                 'Money Velocity',
 *                 href='/money-velocity'
 *             ),
 *             dcc.Link(
 *                 'Reserves',
 *                 href='/reserves'
 *             ),
 *             dcc.Link(
 *                 'Borrowings',
 *                 href='/borrowings'
 *             )
 *         ]),
 *         dcc.Link('Conditions', href='/conditions'),
 *         dcc.Link('Investment', href='/investments'),
 *         dcc.Link('Other', href='/other'),
 *     ])
 * ])
 * ```
 */
function Header(props) {

    const {
        margin,
        padding,
        background_color,
        border_width,
        border_style,
        border_color,
        border_radius,
        box_shadow,
        content_alignment,
        font_color,
        font_family,
        font_size,
    } = props;

    const forceUpdate = useForceUpdate();

    const handleThemeUpdate = () => {
      forceUpdate();
    }

    useEffect(() => {
      window.addEventListener("dash-theme-update", handleThemeUpdate);
      return () => {
        // unsubscribe event
        window.removeEventListener("dash-theme-update", handleThemeUpdate);
      };
    }, []);

    /* merge conditional styles from props */
    const style = mergeRight(
        {
            padding: padding,
            margin: margin,
            backgroundColor: background_color,
            borderWidth: border_width,
            borderStyle: border_style,
            borderColor: border_color,
            borderRadius: border_radius,
            boxShadow: box_shadow,
            color: font_color,
            fontFamily: font_family,
            fontSize: getFontSize(font_size),
            justifyContent: getJustifyContent(
                content_alignment
                || window.dashTheme.header_content_alignment
            )
        },
        propOr({}, 'style', props)
    );
    return (
        <ReportContext.Consumer>
            { context => {
                if (context) {
                    const { inReportContext, suppress_layout_exceptions } = context
                    if (inReportContext && !suppress_layout_exceptions) {
                        throw new Error(
                            templateLiteralDedent(`
                                You added a ddk.Header within
                                a ddk.Report, but
                                it did not render because
                                we strongly recommend using
                                ddk.PageHeader instead, which was
                                designed for Pages within Reports.
                                By default, ddk.PageHeaders appear in the top
                                margin of your ddk.Page and should
                                be its first child.

                                If you wish to render the ddk.Header
                                anyway, add suppress_layout_exceptions=True
                                to your ddk.Report.
                            `)
                        )
                    }
                }

                return (
                    <header
                        className={`layout-header ${propOr('', 'className', props)}`}
                        style={style}
                        data-contentalignment={content_alignment
                          || window.dashTheme.header_content_alignment}
                    >
                        {props.children}
                    </header>
                )
            }}
        </ReportContext.Consumer>
    );
}

Header.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * Optional user-defined CSS class for the Header
     */
    className: PropTypes.string,

    /**
     * The contents of the Header.
     * This is frequently a list containing
     * a `ddk.Logo`, a `ddk.Title`, and a `ddk.Menu`:
     * ```
     * [
     *     ddk.Logo(src='/assets/logo.png'),
     *     ddk.Title('Header Title'),
     *     ddk.Menu([
     *         dcc.Link('Historical', href='/historical'),
     *         dcc.Link('Forecast', href='/forecast')
     *     ]),
     * ]
     * ```
     * but it can also contain arbitrary components
     * like controls or buttons.
     */
    children: PropTypes.node,

    /**
     * Space (in pixels) surrounding the header.
     * Overrides theme.header_margin.
     */
    margin: PropTypes.number,

    /**
     * Space (in pixels) on the inside of the header, between the border
     * and the edge of the content.
     * Overrides theme.header_padding.
     */
    padding: PropTypes.number,

    /**
     * The background color applied to the header. Overrides theme.header_background_color.
     */
    background_color: PropTypes.string,

    /**
     * The box shadow(s) applied to the header. Overrides theme.header_box_shadow.
     */
    box_shadow: PropTypes.string,

    /**
     * The border width applied to the header. Overrides theme.header_border.width.
     */
    border_width: PropTypes.string,

    /**
     * The border style applied to the header. Overrides theme.header_border.style.
     */
    border_style: PropTypes.string,

    /**
     * The border color applied to the header. Overrides theme.header_border.color.
     */
    border_color: PropTypes.string,

    /**
     * The border radius applied to the header. Overrides theme.header_border.radius.
     */
    border_radius: PropTypes.string,

    /**
     * The alignment of header content (ddk.Logo, ddk.Title, controls, and ddk.Menu).
     * Default (legacy) value is 'spread', where controls stretch to fit between
     * other components. (when appropriate).
     * For other values, controls will have a min-width of 200px and otherwise have a width
     * that fits its content. Overrides theme.header_content_alignment.
     */
    content_alignment: PropTypes.oneOf(['spread', 'left', 'right', 'center']),

    /**
     * The font color of Header children. Overrides theme.header_text
     */
    font_color: PropTypes.string,

    /**
     * The font family of Header children. Overrides theme.font_family_header
     */
    font_family: PropTypes.string,

    /**
     * The font size of Header children.
     * Can be a relative size, "smaller", "normal", "larger",
     * or a CSS attribute size (e.g. `px`, 'em`)
     */
    font_size: PropTypes.string,

    /**
     * The style object of the outermost div of the Header.
     * Use this to override e.g. the Header's height
     * (`{'height': '100px'}`)
     */
    style: PropTypes.object
}

export default Header;
