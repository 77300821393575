import { css } from '../utils.js';
import chroma from 'chroma-js';
import tinycolor from 'tinycolor2'

const getLightnessScaleAtDegree = (themeColor, degree) => {
    const scalef = chroma.scale(['white', themeColor, 'black']).domain([0, 250, 750, 1000])
    return scalef(degree).rgb().join(', ')
}

const themedStyles = css`
    body .ddk-container {
        /* primary -> dbc_primary mappings */
        --primary_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 0)
    };
        --primary_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 50)
    };
        --primary_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 100)
    };
        --primary_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 200)
    };
        --primary_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 300)
    };
        --primary_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 400)
    };
        --primary_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 500)
    };
        --primary_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 600)
    };
        --primary_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 700)
    };
        --primary_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 800)
    };
        --primary_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 900)
    };
        --primary_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_primary, 1000)
    };

        /* secondary -> dbc_secondary mappings */
        --secondary_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 0)
    };
        --secondary_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 50)
    };
        --secondary_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 100)
    };
        --secondary_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 200)
    };
        --secondary_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 300)
    };
        --secondary_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 400)
    };
        --secondary_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 500)
    };
        --secondary_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 600)
    };
        --secondary_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 700)
    };
        --secondary_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 800)
    };
        --secondary_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 900)
    };
        --secondary_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_secondary, 1000)
    };

        /* info -> dbc_info mappings */
        --info_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 0)
    };
        --info_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 50)
    };
        --info_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 100)
    };
        --info_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 200)
    };
        --info_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 300)
    };
        --info_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 400)
    };
        --info_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 500)
    };
        --info_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 600)
    };
        --info_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 700)
    };
        --info_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 800)
    };
        --info_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 900)
    };
        --info_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 1000)
    };
        --cyan_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 0)
    };
        --cyan_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 50)
    };
        --cyan_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 100)
    };
        --cyan_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 200)
    };
        --cyan_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 300)
    };
        --cyan_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 400)
    };
        --cyan_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 500)
    };
        --cyan_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 600)
    };
        --cyan_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 700)
    };
        --cyan_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 800)
    };
        --cyan_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 900)
    };
        --cyan_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_info, 1000)
    };

        /* gray -> dbc_gray mappings */
        --gray_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 0)
    };
        --gray_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 50)
    };
        --gray_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 100)
    };
        --gray_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 200)
    };
        --gray_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 300)
    };
        --gray_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 400)
    };
        --gray_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 500)
    };
        --gray_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 600)
    };
        --gray_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 700)
    };
        --gray_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 800)
    };
        --gray_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 900)
    };
        --gray_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_gray, 1000)
    };

        /* success -> dbc_success mappings */
        --success_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 0)
    };
        --success_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 50)
    };
        --success_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 100)
    };
        --success_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 200)
    };
        --success_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 300)
    };
        --success_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 400)
    };
        --success_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 500)
    };
        --success_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 600)
    };
        --success_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 700)
    };
        --success_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 800)
    };
        --success_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 900)
    };
        --success_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_success, 1000)
    };

        /* warning -> dbc_warning mappings */
        --warning_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 0)
    };
        --warning_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 50)
    };
        --warning_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 100)
    };
        --warning_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 200)
    };
        --warning_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 300)
    };
        --warning_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 400)
    };
        --warning_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 500)
    };
        --warning_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 600)
    };
        --warning_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 700)
    };
        --warning_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 800)
    };
        --warning_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 900)
    };
        --warning_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_warning, 1000)
    };

        /* danger -> dbc_danger mappings */
        --danger_0: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 0)
    };
        --danger_50: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 50)
    };
        --danger_100: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 100)
    };
        --danger_200: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 200)
    };
        --danger_300: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 300)
    };
        --danger_400: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 400)
    };
        --danger_500: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 500)
    };
        --danger_600: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 600)
    };
        --danger_700: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 700)
    };
        --danger_800: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 800)
    };
        --danger_900: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 900)
    };
        --danger_1000: ${props => getLightnessScaleAtDegree(props.theme.dbc_danger, 1000)
    };
    }

    /*
     * Base styles - "nonembedded" is for items outside ddk-container.
     * ddk-nonembedded is added to body only if not embedded. In embedded apps
     * we can't tell that these elements belong to DDK so we can't style them.
     */
    .ddk-nonembedded .modal > *,
    .ddk-nonembedded .popover,
    .ddk-nonembedded .tooltip {
        font-family: ${props => props.theme.font_family};
        color: ${props => props.theme.text};
    };

    .ddk-nonembedded,
    .ddk-nonembedded.modal-open {
        color: ${props => props.theme.text};
        background-color: ${props => props.theme.background_page};
    }

    .ddk-container .nav-item > .nav-link,
    .ddk-container .nav-item > active .nav-link {
        cursor: default;
        user-select: none;
        font-family: ${props => props.theme.font_family};
        color: ${props => props.theme.text};
    }

    .ddk-container .nav-tabs .nav-link.active,
    .ddk-container .nav-tabs .nav-link.active,
    .ddk-container .nav-tabs .nav-item.show .nav-link {
        color: ${props => props.theme.text};
        background-color: ${props => props.theme.background_content};
        border-color: ${props => `${props.theme.border} ${props.theme.border} transparent`};
    }

    .ddk-container .block .nav-tabs .nav-link.active,
    .ddk-container .block .nav-tabs .nav-item.show .nav-link,
    .ddk-container .card .nav-tabs .nav-link.active,
    .ddk-container .card .nav-tabs .nav-item.show .nav-link {
        background-color: ${props => props.theme.background_page};
    }

    .ddk-nonembedded .tooltip.tooltip {
        font-family: ${props => props.theme.font_family};
    }

    .ddk-nonembedded .tooltip.tooltip .tooltip-inner {
        color: ${props => props.theme.text};
        background-color: ${props => props.theme.background_content};
        border: 1px solid ${props => props.theme.border};
    }

    .ddk-nonembedded .bs-tooltip-top.bs-tooltip-top .arrow::before,
    .ddk-nonembedded .bs-tooltip-auto.bs-tooltip-auto[x-placement^="top"] .arrow::before {
        border-top-color: ${props => props.theme.background_content};
        margin-top: -1px;
    }

    .ddk-nonembedded .bs-tooltip-right.bs-tooltip-right .arrow::before,
    .ddk-nonembedded .bs-tooltip-auto.bs-tooltip-auto[x-placement^="right"] .arrow::before {
        border-right-color: ${props => props.theme.background_content};
        margin-right: -1px;
    }

    .ddk-nonembedded .bs-tooltip-bottom.bs-tooltip-bottom .arrow::before,
    .ddk-nonembedded .bs-tooltip-auto.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        border-bottom-color: ${props => props.theme.background_content};
        margin-bottom: -1px;
    }

    .ddk-nonembedded .bs-tooltip-left.bs-tooltip-left .arrow::before,
    .ddk-nonembedded .bs-tooltip-auto.bs-tooltip-auto[x-placement^="left"] .arrow::before {
        border-left-color: ${props => props.theme.background_content};
        margin-left: -1px;
    }

    .ddk-container .jumbotron {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container .list-group .list-group-item {
        background-color: ${props => props.theme.background_content};
        border: 1px solid ${props => props.theme.border};
    }

    .ddk-container .dropdown-menu {
        background-color: ${props => props.theme.background_content};
        border: 1px solid ${props => props.theme.border};
    }

    .ddk-container .dropdown-item {
        color: ${props => props.theme.text};
    }

    /* set text color to readable b/w based on computed bg color */
    .ddk-container .btn-secondary,
    .ddk-container .btn-outline-secondary:hover {
        /*
         * Luminance check is less accessible than tinycolor.mostReadable()
         * but matches Bootstrap/DBC more closely
         */
        color: ${props => chroma(props.theme.dbc_secondary).luminance() > 0.5
        ? '#000'
        : '#fff'
    };
    }
    .ddk-container .btn-primary,
    .ddk-container .btn-outline-primary:hover {
        color: ${props => chroma(props.theme.dbc_primary).luminance() > 0.5
        ? '#000'
        : '#fff'
    };
    }

    /*
     * Modal Buttons
     * DBC Modal elements are rendered outside .ddk-container
     */

    .ddk-nonembedded .modal button:not(.btn):not(.edit-theme-button):not([type="button"]),
    .ddk-nonembedded .modal input[type="submit"],
    .ddk-nonembedded .modal input[type="reset"],
    .ddk-nonembedded .modal input[type="button"] {
      color            : ${props => props.theme.button_text};
      border-style: solid;
      border-width: ${props => props.theme.button_border.width};
      border-color: ${props => props.theme.button_border.color};
      border-radius: ${props => props.theme.button_border.radius};
      background-color : ${props => props.theme.button_background_color};
      text-transform: ${props => props.theme.button_capitalization};
      font-family: ${props =>
        props.theme.button_capitalization !== 'uppercase'
            ? props.theme.font_family
            : 'sans-serif'
    };
      transition       : all 0.2s ease;
    }

    .ddk-nonembedded .modal button:not(.btn):not(.edit-theme-button):not([type="button"]):active,
    .ddk-nonembedded .modal input[type="submit"]:active,
    .ddk-nonembedded .modal input[type="reset"]:active,
    .ddk-nonembedded .modal input[type="button"]:active {
        box-shadow: none;
    }
    .ddk-nonembedded .modal .button:not(.btn):not(.edit-theme-button):not([type="button"]):hover:enabled,
    .ddk-nonembedded .modal button:not(.btn):not(.edit-theme-button):not([type="button"]):hover:enabled,
    .ddk-nonembedded .modal input[type="submit"]:hover:enabled,
    .ddk-nonembedded .modal input[type="reset"]:hover:enabled,
    .ddk-nonembedded .modal input[type="button"]:hover:enabled {
        color: ${props =>
        props.theme.button_background_color === props.theme.background_content
            ? tinycolor(props.theme.button_text).isLight()
                ? tinycolor(props.theme.button_text).darken(20)
                : tinycolor(props.theme.button_text).lighten(20)
            : ''
    };
        border-color: ${props =>
        (props.theme.button_background_color === props.theme.background_content
            || props.theme.button_background_color === props.theme.button_border.color)
            ? tinycolor(props.theme.button_border.color).isLight()
                ? tinycolor(props.theme.button_border.color).darken(15)
                : tinycolor(props.theme.button_border.color).lighten(15)
            : ''
    };
        background-color: ${props =>
        props.theme.button_background_color !== props.theme.background_content
            ? tinycolor(props.theme.button_background_color).isLight()
                ? tinycolor(props.theme.button_background_color).darken(15)
                : tinycolor(props.theme.button_background_color).lighten(15)
            : ''
    };
    }

    .ddk-nonembedded .modal .button:not(.btn):not(.edit-theme-button):not([type="button"]):focus,
    .ddk-nonembedded .modal button:not(.btn):not(.edit-theme-button):not([type="button"]):focus,
    .ddk-nonembedded .modal input[type="submit"]:focus,
    .ddk-nonembedded .modal input[type="reset"]:focus,
    .ddk-nonembedded .modal input[type="button"]:focus {
        outline: none;
    }

    .ddk-nonembedded .modal .button:not(.btn):not(.edit-theme-button):not([type="button"]):disabled,
    .ddk-nonembedded .modal button:not(.btn):not(.edit-theme-button):not([type="button"]):disabled,
    .ddk-nonembedded .modal input[type="submit"]:disabled,
    .ddk-nonembedded .modal input[type="reset"]:disabled,
    .ddk-nonembedded .modal input[type="button"]:disabled {
        opacity: 0.5;
    }

    .ddk-nonembedded .modal .button:not(.btn):not(.edit-theme-button):not([type="button"]).button-primary,
    .ddk-nonembedded .modal button:not(.btn):not(.edit-theme-button):not([type="button"]).button-primary,
    .ddk-nonembedded .modal input[type="submit"].button-primary,
    .ddk-nonembedded .modal input[type="reset"].button-primary,
    .ddk-nonembedded .modal input[type="button"].button-primary {
      color: ${props => props.theme.background_content};
      background-color: ${props => props.theme.accent};
      border-color: ${props => props.theme.accent};
    }

    /* Modal links */

    .ddk-nonembedded .modal a {
      color: ${props => props.theme.accent};
    }
    .ddk-nonembedded .modal a:hover {
      color: ${props => props.theme.accent};
    }


    /* Containers */
    .ddk-container .card {
        background-color: ${props => props.theme.card_background_color};
    }

    /* Navigation */

    .ddk-container .list-group .list-group-item {
        background-color: ${props => props.theme.background_content};
        border: 1px solid ${props => props.theme.border};
    }

    .ddk-container nav.navbar {
        /* needs !important as this is how it's defined in Bootstrap CSS */
        background-color: ${props => props.theme.header_background_color} !important;
    }

    .ddk-container nav.navbar,
    .ddk-container .navbar-nav,
    .ddk-container.ddk-container .navbar-nav .nav-link {
        color: ${props => props.theme.header_text};
    }

    .ddk-container .close {
        color: ${props => props.theme.text};
        text-shadow: 0 1px 0 ${props => props.theme.text};
    }

    .ddk-container .close:not(:disabled):not(.disabled):hover,
    .ddk-container .close:not(:disabled):not(.disabled):focus {
        color: ${props => props.theme.accent};
    }

    .ddk-container .layout-header .close {
        color: inherit;
    }

    .ddk-container .layout-header .close:not(:disabled):not(.disabled):hover,
    .ddk-container .layout-header .close:not(:disabled):not(.disabled):focus {
        color: ${props => props.theme.accent};
    }

    .ddk-container .navbar .navbar-toggler-icon {
        background-image: ${props => `url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='${tinycolor(props.theme.dbc_secondary).toRgbString()}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")`};
    }

    /*
    * Breadcrumb
    */
    .ddk-container .breadcrumb-item {
        margin-left: 0px !important;
    }

    /*
    * Pagination
    */
   .ddk-container .page-item {
        margin-left: 0px !important;
        padding-left: 0px !important;
   }


    /* Forms */
    .ddk-container .form-control:focus,
    .ddk-container .form-control {
        color: ${props => props.theme.text};
        background-color: inherit;
    }

    .ddk-container .input-group-text {
        color: ${props => props.theme.text};
        background-color: ${props => chroma(props.theme.control_background_color).brighten(0.2)};
        border: 1px solid var(--border);
    }

    .ddk-container .form-check-input,
    .ddk-container .form-check-input[type=checkbox],
    .ddk-container .form-check-input[type=radio]{
        background-color: ${props => props.theme.button_background_color};
        border-color: ${props => props.theme.button_border.color};
    }

    .ddk-container .form-check-input:checked[type=checkbox]{
        background-color: ${props => props.theme.accent};
        border-color: ${props => props.theme.button_border.color};
    }

    .ddk-container .form-select {
        background-color: ${props => props.theme.background_content};
        font-family: ${props => props.theme.font_family};
        color: ${props => props.theme.body_text};
    }

    /* Modal */
    .ddk-nonembedded .modal-content.modal-content {
        background-color: ${props => props.theme.background_page};
        border: 1px solid ${props => props.theme.border};
    }

    .ddk-nonembedded .modal-header.modal-header {
        border-bottom-color: ${props => props.theme.border};
    }

    .ddk-nonembedded .modal-footer.modal-footer {
        border-top-color: ${props => props.theme.border};
    }

    /* Popover */
    .ddk-nonembedded .popover-header.popover-header {
        background-color: ${props => props.theme.background_content};
        border-bottom: 1px solid ${props => props.theme.border};
    }

    .ddk-nonembedded .popover.popover {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-nonembedded .popover .popover-body {
        color: ${props => props.theme.text};
    }

    .ddk-nonembedded .bs-popover-top .arrow::before,
    .ddk-nonembedded .bs-popover-auto[x-placement^="top"] .arrow::before {
        border-top-color: ${props => props.theme.background_content};
    }
    .ddk-nonembedded .bs-popover-top .arrow::after,
    .ddk-nonembedded .bs-popover-auto[x-placement^="top"] .arrow::after {
        border-top-color: ${props => props.theme.background_content};
    }

    .ddk-nonembedded .bs-popover-right .arrow::before,
    .ddk-nonembedded .bs-popover-auto[x-placement^="right"] .arrow::before {
        border-right-color: ${props => props.theme.background_content};
    }
    .ddk-nonembedded .bs-popover-right .arrow::after,
    .ddk-nonembedded .bs-popover-auto[x-placement^="right"] .arrow::after {
        border-right-color: ${props => props.theme.background_content};
    }

    .ddk-nonembedded .bs-popover-bottom .arrow::before,
    .ddk-nonembedded .bs-popover-auto[x-placement^="bottom"] .arrow::before {
        border-bottom-color: ${props => props.theme.background_content};
    }
    .ddk-nonembedded .bs-popover-bottom .arrow::after,
    .ddk-nonembedded .bs-popover-auto[x-placement^="bottom"] .arrow::after {
        border-bottom-color: ${props => props.theme.background_content};
    }

    .ddk-nonembedded .bs-popover-left .arrow::before,
    .ddk-nonembedded .bs-popover-auto[x-placement^="left"] .arrow::before {
        border-left-color: ${props => props.theme.background_content};
    }
    .ddk-nonembedded .bs-popover-left .arrow::after,
    .ddk-nonembedded .bs-popover-auto[x-placement^="left"] .arrow::after {
        border-left-color: ${props => props.theme.background_content};
    }

    /* Table */
    .ddk-container .table {
      color: var(--text);
    }
    .ddk-container .table-striped > tbody > tr:nth-of-type(odd) > * {
      color: var(--text);
    }
    .ddk-container .table-striped > tbody > tr:nth-of-type(even) > * {
      color: var(--text);
    }
    .ddk-container .table-hover > tbody > tr:hover > *{
      color: var(--accent);
    }
    .ddk-container .table-bordered th, 
    .ddk-container .table-bordered td, 
    .ddk-container .table th, 
    .ddk-container .table thead th, 
    .ddk-container .ddk-container th, 
    .ddk-container td:not(.CalendarDay) {
        color: var(--text);
        border-color: ${props => props.theme.table_border};
    }

    .ddk-container .table {
        --bs-table-accent-bg: ${props => props.theme.table_striped_even};      /* headline and even numbered lines */
        --bs-table-striped-bg: ${props => props.theme.table_striped_odd};      /* odd numbered lines */
    }

    /*
    * DDK Graph
    */
   .ddk-container .ddk-graph {
       flex: 1;
   }

    .ddk-container.ddk-container .list-group li {
        color: var(--body_text);
    }
}
`
export default themedStyles;
