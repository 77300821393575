import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js'

import {setExpandedGraphDimensions, saveGraphDimensions} from '../graphUtils';
import {stringifyId, fireResize} from '../utils';
import IconCardModal from './../icons/icon-card-modal.svg';


const normalize_dimension = (dim) => {
    // we interpret numbers as percentage values
    return typeof dim === 'number' ? dim + '%' : dim
}

/* This component uses vanilla JS DOM manipulation
   to create and render elements at the React mount point 'modalContainer'.
   We are unable to use conventional React patterns here because we don't
   want to use a hack to get the underying React instance of the element */
class ModalContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    /* Dynamically create a container with a modal backdrop and append
       this.props.children */
    componentDidMount() {
        this.element = this.props.children;
        this.parentElement = this.props.children.parentNode;

        this.oldWidth = this.element.style.width;
        this.oldHeight = this.element.style.height;

        /* Replace the original element with a clone */
        this.clonedElement = this.props.children.cloneNode(true);
        this.parentElement.replaceChild(this.clonedElement, this.element);

        /* Un-hide the element if it is initialized as hidden */
        this.hidden = this.element.classList.contains('hidden');
        this.element.classList.remove('hidden');

        const graphContainers = this.element.classList.contains('dash-graph')
            ? [this.element] // the container is the Graph element itself
            : this.element.getElementsByClassName('dash-graph');

        if (!graphContainers.length) {
            this.element.classList.add('modal--cb');
        }

        /* resize the element if dimensions specified */
        if (this.props.width) {
            this.element.style.width = normalize_dimension(this.props.width)
            // if width is set, override max
            this.element.style.maxWidth = 'initial';
        }
        if (this.props.height) {
            this.element.style.height = normalize_dimension(this.props.height)
            // if height is set, override max
            this.element.style.maxHeight = 'initial';
        }

        this.modalContainer = document.getElementById('modalContainer');
        this.modalBackdrop = document.createElement("div");
        this.modalBackdrop.classList.add("modal--backdrop");
        this.modalBackdrop.onclick = this.props.toggleFull;
        this.element.classList.add('modal--inner', 'block', 'card', 'ddk-card');
        /* TODO: a more React-like way to do this might be to use Portals */
        this.modalContainer.appendChild(this.modalBackdrop);
        this.modalContainer.appendChild(this.element);


        // add a close button - see https://github.com/plotly/dash-design-kit/issues/978
        if (!document.querySelector(".close-button")) {
            this.closeButton = document.createElement("span");
            this.closeButton.className = "close-button";
            this.closeButton.appendChild(document.createTextNode("x"));
            this.closeButton.onclick = this.props.toggleFull;
            this.element.appendChild(this.closeButton);
        }

        /*
         * Remove the clonedElement so its dimensions don't clip the real element
         * Needed for Safari not to clip expanded graphs
         * See #374 (issue) and #902 (PR) for more context
         */
        if (is.safari()) {
            if (this.clonedElement.classList.contains("dash-graph")) {
                this.nextGraphSibling = this.clonedElement.nextSibling;
                this.clonedElement.parentNode.removeChild(this.clonedElement);
            } else {
                Array.from(this.clonedElement.getElementsByClassName("dash-graph")).forEach(graph => {
                    if (!this.modalContainer.contains(graph)) {
                        graph.parentNode.removeChild(graph);
                    }
                })
            }
        }

        if (is.ie()) {
            fireResize();
        }
    }

    componentWillUnmount() {
        /* Remove the class that centers the element + block/card styling */
        this.element.classList.remove('modal--inner', 'block', 'card');
        /* reset dimensions of the element using its unchanged clone */
        this.element.style.width = this.oldWidth;
        this.element.style.height = this.oldHeight;

        // remove the close button
        this.element.removeChild(this.closeButton);

        /*
         * Put back the removed clonedElement in order to swap (replace) it
         * Needed for Safari not to clip expanded graphs
         * See #374 (issue) and #902 (PR) for more context
         */
        if (is.safari()) {
            if (!this.parentElement.contains(this.clonedElement)) {
                this.parentElement.insertBefore(this.clonedElement, this.nextGraphSibling);
            }
        }
        /* Replace the cloned element underneath with the element */
        this.parentElement.replaceChild(this.element, this.clonedElement);
        /* Restore the element's hidden state if was initialized hidden
           (by either this component or a Modal) */
        if (this.props.hide_target || this.hidden) {
            this.element.classList.add('hidden');
        }
    }

    render() {
        /* We use this as a mount point, taking advantage of React's DidMount
           and WillUnmount methods */
        return (
            <div
                id='modalContainer'
                className={this.props.className}
            />
        );
    }
}

/**
 * This function is able to render target elements in a modal.
 *
 * Functional component: this component does not need to be wrapped in
 * `ddk.App`, and will work as intended with or without an inherited theme.
 */
export default class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.onDOMChanged = this.onDOMChanged.bind(this);
        this.toggleFull = this.toggleFull.bind(this);
        this.graphResize = this.graphResize.bind(this);

        this.state = {
            expanded: props.expanded || false,
            modalElement: null
        };

        this.observer = new MutationObserver(this.onDOMChanged);
        this.observer.observe(document.body, { childList: true });
    }

    UNSAFE_componentWillReceiveProps(newProps, oldProps) {
        if (newProps.expanded !== oldProps.expanded) {
            this.setState({expanded: newProps.expanded});
        }
    }

    componentDidMount() {
        this.onDOMChanged();

        /* Listen to clicks on the Modal's children */
        this.modalElement.addEventListener(
            'click',
            this.toggleFull,
            true
        );

        /* Listen for Esc */
        window.document.addEventListener('keydown', e => {
            if(e.key === "Escape") {
                this.graphResize();
                this.setState({expanded: false}, () => {
                    if (this.props.setProps) {
                        this.props.setProps({expanded: this.state.expanded});
                    }
                });
            }
        })


    }

    onDOMChanged() {
        if (this.element && document.contains(this.element)) {
            return;
        }

        this.element = this.props.cardRef ?? document.getElementById(
            stringifyId(this.props.target_id)
        );
        if (!this.element) {
            return;
        }

        this.graphContainers = this.element.classList.contains('dash-graph')
            ? [this.element] // the container is the Graph element itself
            : this.element.getElementsByClassName('dash-graph');

        /* Hide the element if initialized as hidden */
        if (this.props.hide_target) {
            this.element.classList.add('hidden');
        }

        this.forceUpdate();
    }

    toggleFull() {
        if (!this.state.expanded) {
            saveGraphDimensions(this);
            setExpandedGraphDimensions(this);
        } else {
            this.graphResize();
        }

        this.setState({expanded: !this.state.expanded}, () => {
            if (this.props.setProps) {
                this.props.setProps({expanded: this.state.expanded});
            }
        });
    }

    graphResize() {
        if (this.graphContainers && Array.isArray(this.graphContainers) && this.state.expanded) {
            /* We are exiting a modal state, so we restore the original
               graph styles */
            const origGraphChildrenStyles = this.origGraphChildrenStyles;
            this.graphContainers.map((graphContainer, i) => {
                graphContainer.style.width = graphContainer.style.oldWidth;
                graphContainer.style.height = graphContainer.style.oldHeight;
                const graphChild = graphContainer.getElementsByClassName('svg-container')[0];
                graphChild.style.cssText = origGraphChildrenStyles[i];
            });
        }
    }

    render() {
        const {
            children,
            id,
            className,
            modal_container_className,
            style,
            target_id,
            width,
            height
        } = this.props;
        const element = this.element ?? this.props.cardRef ?? document.getElementById(
            stringifyId(target_id)
        );
        return (
            <React.Fragment>
                <div
                    id={id}
                    ref={ (node) => { this.modalElement = node } }
                    data-expanded={this.state.expanded}
                    className={`
                        expandToFull
                        expandToFull--modal
                        ${className ? className : ''}
                    `}
                    style={{...style}}
                >
                    {children ? children : <IconCardModal expanded={this.state.expanded} />}
                </div>
                {this.state.expanded && (
                    <ModalContainer
                        className={modal_container_className}
                        toggleFull={this.toggleFull}
                        width={width}
                        height={height}
                    >
                        {element}
                    </ModalContainer>
                )}
            </React.Fragment>
        )
    }
};

Modal.defaultProps = {
    className: ''
};

Modal.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The optional className of the child element that
     * opens a modal on click
     */
    className: PropTypes.string,

    /**
     * The optional className of the modal container
     */
    modal_container_className: PropTypes.string,

    /**
     * The element that, when clicked, will activate a modal
     */
    children: PropTypes.node,

    /**
     * A reference to a card to expand
     */
    cardRef: PropTypes.node,

    /**
     * The id of the DOM element to expand to a Modal
     */
    target_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    /**
     * The width of the element when expanded (% or px)
     */
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * The height of the element when expanded (% or px)
     */
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * An option to initialize the modal target as hidden
     */
    hide_target: PropTypes.bool,

    /**
     * A boolean representing the current expanded state of the target element
     */
    expanded: PropTypes.bool,

    /**
     * Dash-assigned callback that gets fired when the value changes.
     */
    setProps: PropTypes.any,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,
};
