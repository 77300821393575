import { css, objectToStyles, getValueFromCSSText } from '../utils.js';
import chroma from 'chroma-js';


const themedStyles = css`
    .ddk-container.ddk-container .Select-input > input,
    .ddk-container.ddk-container .Select-input > input:not([type]) {
      border-color: none;
      border-width: 0;
      border-style: none;
      border-radius: 0;
      padding: initial;
    }

    .ddk-container.ddk-container .Select-arrow {
        vertical-align: top;
        border-top-color    : ${props => props.theme.control_text};
        border-left-color   : transparent;
        border-right-color  : transparent;
        border-bottom-color : transparent;
    }

    .ddk-container.ddk-container .Select-control {
        /*
         * resolves webkit width calculation bug
         * with display: table (which .Select-control has applied)
         * see http://www.stackoverflow.com/q/31719624 and http://www.bugs.webkit.org/show_bug.cgi?id=140371
         */
        width: calc(100% + 0.5px);
    }

    .ddk-container.ddk-container .layout-header .Select-control,
    .ddk-container .layout-header .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    .ddk-container .layout-header .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
    .ddk-container.ddk-container .layout-header .Select--single > .Select-control .Select-value {
        color: inherit;
        background-color: inherit;
    }

    .ddk-container .layout-header .Select-menu-outer {
        border-bottom-color: ${props => props.theme.body_text};
    }

    .ddk-container.ddk-container .is-open > .Select-control .Select-arrow {
        top: initial;
        border-bottom-color: ${props => props.theme.body_text};
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
    }

    .ddk-container.ddk-container .Select--multi .Select-value,
    .ddk-container .Select--multi a.Select-value-label {
        color: ${props => props.theme.body_text};
        user-select: none;
    }

    /* Legacy selector (pre .dash-dropdown class introduced in Dash 1.12.1) */
    .ddk-container.ddk-container div:not(.dash-dropdown) > .Select > .Select-control,
    .ddk-container.ddk-container div:not(.dash-dropdown) > .Select.is-disabled > .Select-control,
    /* Select .dash-dropdown introduced in  Dash 1.12.1 */
    .ddk-container.ddk-container .dash-dropdown {
        ${props => objectToStyles(props.theme.border_style)}

        border-width: ${props => props.theme.control_border.width};
        border-color: ${props => props.theme.control_border.color};
        border-radius: ${props => props.theme.control_border.radius};
    }

    .ddk-container.ddk-container .Select {
        background-color: ${props => props.theme.control_background_color};
        color: ${props => props.theme.control_text};
        border-radius: ${props => props.theme.control_border.radius};
    }


    .ddk-container .Select-placeholder {
        color: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
    }

    .ddk-container.ddk-container .layout-header .Select {
        background-color: inherit;
        color: inherit;
    }

    .ddk-container.ddk-container .Select.is-disabled {
        color: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
        background-color: transparent;
    }

    .ddk-container.ddk-container .layout-header .Select.is-disabled {
        color: inherit;
    }

    .ddk-container .Select.has-value.Select--single.is-disabled > .Select-control .Select-value .Select-value-label,
    .ddk-container .Select.has-value.is-pseudo-focused.Select--single.is-disabled > .Select-control .Select-value .Select-value-label,

    .ddk-container .Select.has-value.Select--single.is-disabled > .Select-control .Select-value a.Select-value-label:hover,
    .ddk-container .Select.has-value.is-pseudo-focused.Select--single.is-disabled > .Select-control .Select-value a.Select-value-label:hover,
    .ddk-container .Select.has-value.Select--single.is-disabled > .Select-control .Select-value a.Select-value-label:focus,
    .ddk-container .Select.has-value.is-pseudo-focused.Select--single.is-disabled > .Select-control .Select-value a.Select-value-label:focus,

    .ddk-container .Select.has-value.Select--single.is-disabled > .Select-control .Select-value a.Select-value-label:focus,
    .ddk-container .Select.has-value.is-pseudo-focused.Select--single.is-disabled > .Select-control .Select-value a.Select-value-label:focus {
        background-color: transparent;
    }

    .ddk-container .Select.is-disabled>.Select-control {
        background-color: ${props => chroma(props.theme.control_background_color).alpha(0.5).css()};
    }

    .ddk-container.ddk-container .Select > .Select-control,
    .ddk-container.ddk-container .Select.is-disabled > .Select-control {
        border-radius: ${props => props.theme.control_border.radius};
    }

    .ddk-container.ddk-container div.dash-dropdown > .Select > .Select-control,
    .ddk-container.ddk-container div.dash-dropdown > .Select.is-disabled > .Select-control {
        border: none;
    }

    .ddk-container.ddk-container div.dash-dropdown > .Select > .Select-control:hover {
        box-shadow: none;
    }

    .ddk-container.ddk-container .layout-header div:not(.dash-dropdown) > .Select > .Select-control,
    .ddk-container.ddk-container .layout-header div:not(.dash-dropdown) > .Select.is-disabled > .Select-control,
    .ddk-container.ddk-container .layout-header .dash-dropdown > .Select > .Select-control {
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? 'inherit'
              : props.theme.control_background_color
        };
    }

    .ddk-container.ddk-container .layout-header[style*="color:"] div:not(.dash-dropdown) > .Select > .Select-control,
    .ddk-container.ddk-container .layout-header[style*="color:"] div:not(.dash-dropdown) > .Select.is-disabled > .Select-control,
    .ddk-container.ddk-container .layout-header[style*="color:"] .Select-placeholder,
    .ddk-container.ddk-container .layout-header[style*="color:"] .dash-dropdown {
        color: ${props =>
            props.theme.control_text === props.theme.text
              ? 'inherit'
              : props.theme.control_text
        };
    }

    .ddk-container.ddk-container .layout-header[style*="background-color:"] div:not(.dash-dropdown) > .Select > .Select-control,
    .ddk-container.ddk-container .layout-header[style*="background-color:"] div:not(.dash-dropdown) > .Select.is-disabled > .Select-control,
    .ddk-container.ddk-container .layout-header[style*="background-color:"] .dash-dropdown > .Select > .Select-control {
        background-color: ${props =>
            props.theme.control_background_color === props.theme.background_content
              ? 'inherit'
              : props.theme.control_background_color
        };
    }

    .ddk-container.ddk-container .layout-header .Select.is-disabled > .Select-control {
        opacity: 0.5;
    }

    .ddk-container.ddk-container .Select.is-disabled .Select-value-label {
        background: none;
    }

    .ddk-container.ddk-container .Select-control:hover {
        border-color: ${props => props.theme.border};
        cursor: pointer;
    }

    .ddk-container.ddk-container .Select--multi .Select-value {
        border-color: ${props =>
            props.theme.control_border.color === props.theme.border
              ? props.theme.accent
              : props.theme.control_border.color
        };
        color: ${props => props.theme.control_text};
    }

    .ddk-container.ddk-container .layout-header:not([style*="color:"]) .Select-placeholder,
    .ddk-container.ddk-container .layout-header:not([style*="color:"]) .Select--multi .Select-value {
        color: ${props =>
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        };
    }

    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .Select--multi .Select-value {
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? 'inherit'
              : chroma(props.theme.control_background_color).alpha(0.8).css()
        };
    }

    .ddk-container.ddk-container .Select--multi .Select-value-icon {
        border-color: ${props =>
            props.theme.control_border.color === props.theme.border
              ? props.theme.accent
              : props.theme.control_border.color
        };
    }

    .ddk-container.ddk-container .Select--multi .Select-value-icon:hover,
    .ddk-container.ddk-container .Select--multi .Select-value-icon:focus {
        font-weight: bold;
    }

    .ddk-container.ddk-container .Select.is-disabled .Select-control {
        cursor: not-allowed;
    }

    .ddk-container.ddk-container .Select-clear-zone {
        display: inline-flex;
        position: absolute;
        width: auto;
        bottom: 0px;
        right: ${props =>
            ((props.theme.background_content !== props.theme.control_background_color)
                || parseInt(getValueFromCSSText(
                    'borderRightWidth',
                    'borderWidth',
                    props.theme.control_border.width
                ), 10) > 0)
              ? '5px'
              : 0
        };
        vertical-align: bottom;
        color: ${props => props.theme.control_text}
    }

    .ddk-container.ddk-container .Select-clear-zone:hover {
        color: ${props => props.theme.accent}
    }

    .ddk-container.ddk-container .Select-clear-zone .Select-clear {
        display: inline-block;
        vertical-align: bottom;
    }

    .ddk-container.ddk-container .Select-arrow-zone {
        display: inline-flex;
        position: absolute;
        width: auto;
        right: ${props =>
            ((props.theme.background_content !== props.theme.control_background_color)
            || parseInt(getValueFromCSSText(
                'borderRightWidth',
                'borderWidth',
                props.theme.control_border.width
            ), 10) > 0)
              ? '5px'
              : 0
        };
        top: 5px;
        vertical-align: top;
        padding-right: initial;
        color: ${props => props.theme.body_text}
    }

    .ddk-container.ddk-container .Select-arrow-zone:hover {
        color: ${props => props.theme.accent}
    }
`
export default themedStyles;
