const Serious_Dark = {
    accent: "#C63E32",
    accent_positive: "#2CB133",
    accent_negative: "#C63E32",
    background_content: "#303030",
    background_page: "#1C1C1C",
    body_text: "#F7F7F7",
    border: "#454545",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: 0,
    },
    button_border: {
        width: "0px",
        color: "#C63E32",
        radius: "0px"
    },
    button_capitalization: "none",
    button_text: "#F7F7F7",
    button_background_color: "#C63E32",
    control_border: {
        width: "1px 1px 1px 1px",
        color: "#3D3D3D",
        radius: "0px"
    },
    control_background_color: "#636363",
    control_text: "#F7F7F7",
    card_margin: "20px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#000000",
        radius: "0px",
    },
    card_background_color: "#303030",
    card_box_shadow: "0px 0px 5px rgba(0,0,0,0)",
    card_outline: {
        width: "0px",
        style: "solid",
        color: "#303030"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "0px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#303030",
        radius: "0px",
    },
    card_header_background_color: "#303030",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#FF7462",
        "#66C2FF",
        "#5966FF",
        "#FFCB64",
        "#72E778",
        "#A3759F",
        "#A56E51",
        "#7B7DFF",
        "#7A76FF",
        "#158885",
        "#ED7493",
    ],
    colorscale: [
        "#f2b9a3",
        "#edaa91",
        "#e79b81",
        "#e08c70",
        "#da7d60",
        "#d36d51",
        "#cc5e42",
        "#c44d34",
        "#bc3b26",
        "#b42519",
    ],
    dbc_primary: "#C63E32",
    dbc_secondary: "#66C2FF",
    dbc_info: "#0F9FFF",
    dbc_gray: "#828282",
    dbc_success: "#2CB133",
    dbc_warning: "#EBAD2A",
    dbc_danger: "#C63E32",
    font_family: "Open Sans",
    font_family_header: "Open Sans",
    font_family_headings: "Open Sans",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "uppercase",
    header_content_alignment: "spread",
    header_margin: "0px 0px 24px 0px",
    header_padding: "0px 16px 0px 0px",
    header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#C63E32",
        radius: "0px",
    },
    header_background_color: "#1C1C1C",
    header_box_shadow: "0px 4px 0px rgba(198,62,50,1)",
    header_text: "#F7F7F7",
    heading_text: "#F7F7F7",
    text: "#F7F7F7",
    report_background: "#f0f0f0",
    report_background_content: "whitesmoke",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "PT Serif",
    report_font_size: "12px",
    report_border: "#D6D6D6",
    graph_grid_color: "#4D4D4D",
    table_striped_even: "#303030",
    table_striped_odd: "#303030",
    table_border: "#BFC0C2",
    card_accent: "#828282",
};

export default Serious_Dark;