import React from 'react';

export default ({expanded = false}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.74 122.74">
        <title>{expanded ? "Collapse to card" : "Expand to fullscreen"}</title>
        <g id="Layer_2" dataName="Layer 2">
            <g id="Layer_1-2" dataName="Layer 1">
                <path className="cls-1" d="M 15.7 16.1 C 16.4 15.4 17.4 15 18.4 15 C 19.5 15 20.5 15.4 21.2 16.1 L 47.4 42.4 C 48.4 43.4 48.8 44.8 48.4 46.1 C 48.1 47.5 47 48.5 45.7 48.9 C 44.4 49.3 42.9 48.9 42 47.9 L 15.7 21.6 C 14.9 20.9 14.5 19.9 14.5 18.9 C 14.5 17.8 14.9 16.9 15.7 16.1 Z"/>
                <path className="cls-2" d="M 21.2 16.1 C 20.4 15.4 19.5 15 18.4 15 C 17.4 15 16.4 15.4 15.7 16.1 C 14.9 16.9 14.5 17.8 14.5 18.9 C 14.5 19.9 14.9 20.9 15.7 21.6 L 28.6 34.5 C 29.4 31.9 31.4 29.8 34 28.9 Z"/>
                <path className="cls-1" d="M 14.5 34.9 v -16 C 14.5 17.8 14.9 16.8 15.7 16.1 C 16.4 15.4 17.4 15 18.4 15 h 16 C 36.6 15 38.3 16.7 38.3 18.9 C 38.3 21 36.6 22.8 34.4 22.8 H 22.3 v 12.1 C 22.3 37 20.6 38.7 18.4 38.7 C 16.3 38.7 14.6 37 14.6 34.9 Z"/>
                <path className="cls-1" d="M 106.1 16.1 C 105.3 15.4 104.3 15 103.3 15 C 102.3 15 101.3 15.4 100.6 16.1 L 74.3 42.4 C 72.8 43.9 72.8 46.4 74.3 47.9 C 75.8 49.4 78.3 49.4 79.8 47.9 L 106.1 21.6 C 106.8 20.9 107.2 19.9 107.2 18.9 C 107.2 17.8 106.8 16.9 106.1 16.1 Z"/>
                <path className="cls-2" d="M 100.6 16.1 C 101.3 15.4 102.3 15 103.3 15 C 104.3 15 105.3 15.4 106.1 16.1 C 106.8 16.9 107.2 17.8 107.2 18.9 C 107.2 19.9 106.8 20.9 106.1 21.6 L 93.2 34.5 C 92.4 31.9 90.4 29.8 87.8 28.9 Z"/>
                <path className="cls-1" d="M 107.2 34.9 v -16 C 107.2 16.7 105.4 15 103.3 15 h -16 C 85.2 15 83.4 16.7 83.4 18.9 C 83.4 21 85.2 22.8 87.3 22.8 h 12.1 v 12.1 C 99.4 37 101.1 38.7 103.3 38.7 C 105.4 38.7 107.2 37 107.2 34.9 Z"/>
                <path className="cls-1" d="M 15.7 106.6 C 16.4 107.3 17.4 107.7 18.4 107.7 C 19.5 107.7 20.5 107.3 21.2 106.6 L 47.4 80.3 C 49 78.8 49 76.4 47.4 74.9 C 45.9 73.3 43.5 73.3 41.9 74.9 L 15.7 101.1 C 14.9 101.8 14.5 102.8 14.5 103.9 C 14.5 104.9 14.9 105.9 15.7 106.6 Z"/>
                <path className="cls-2" d="M 21.2 106.6 C 20.4 107.3 19.5 107.7 18.4 107.7 C 17.4 107.7 16.4 107.3 15.7 106.6 C 14.9 105.9 14.5 104.9 14.5 103.9 C 14.5 102.8 14.9 101.8 15.7 101.1 L 28.6 88.2 C 29.4 90.8 31.4 92.9 34 93.8 Z"/>
                <path className="cls-1" d="M 14.5 87.9 v 16 C 14.5 106 16.2 107.8 18.4 107.8 h 16 C 36.5 107.8 38.3 106 38.3 103.9 C 38.3 101.7 36.5 100 34.4 100 H 22.3 V 87.9 C 22.3 85.7 20.6 84 18.4 84 C 16.3 84 14.6 85.7 14.6 87.9 Z"/>
                <path className="cls-1" d="M 106.1 106.6 C 105.3 107.3 104.3 107.7 103.3 107.7 C 102.3 107.7 101.3 107.3 100.6 106.6 L 74.3 80.3 C 72.8 78.8 72.8 76.4 74.3 74.9 C 75.8 73.3 78.3 73.3 79.8 74.9 L 106.1 101.1 C 106.8 101.8 107.2 102.8 107.2 103.9 C 107.2 104.9 106.8 105.9 106.1 106.6 Z"/>
                <path className="cls-2" d="M 100.6 106.6 C 101.3 107.3 102.3 107.7 103.3 107.7 C 104.3 107.7 105.3 107.3 106.1 106.6 C 106.8 105.9 107.2 104.9 107.2 103.9 C 107.2 102.8 106.8 101.8 106.1 101.1 L 93.2 88.2 C 92.4 90.8 90.4 92.9 87.8 93.8 Z"/>
                <path className="cls-1" d="M 107.2 87.9 v 16 C 107.2 106 105.5 107.8 103.3 107.8 h -16 C 85.2 107.8 83.4 106 83.4 103.9 C 83.4 101.7 85.2 100 87.3 100 h 12.1 V 87.9 C 99.4 85.7 101.1 84 103.3 84 C 105.4 84 107.2 85.7 107.2 87.9 Z"/>
            </g>
        </g>
    </svg>
