import { css } from '../utils.js';
import chroma from 'chroma-js';

const themedStyles = css`
    .ddk-container .rc-slider-rail {
      background-color: ${props => props.theme.control_border};
    }

    .ddk-container .rc-slider-track {
      background-color: ${props => props.theme.accent};
    }

    .ddk-container .rc-slider-handle {
      border-color: ${props => props.theme.accent};
      background-color: ${props => props.theme.background_content};
    }

    .ddk-container .rc-slider-handle:hover {
      border-color: ${props => chroma(props.theme.accent).brighten().css()};
    }

    .ddk-container .rc-slider-handle-active:active {
      border-color: ${props => chroma(props.theme.accent).brighten().css()};
      box-shadow: ${props => chroma(props.theme.accent).brighten().css()};
    }

    .ddk-container .rc-slider-mark-text-active {
      color: ${props => props.theme.body_text};
    }

    .ddk-container .rc-slider-step {
      background-color: transparent;
    }

    .ddk-container .rc-slider-dot {
      border-color: ${props => chroma(props.theme.accent).alpha(0.5).css()};
      background-color: ${props => props.theme.background_content};
    }

    .ddk-container .rc-slider-dot-active {
      border-color: ${props => props.theme.accent};
    }

    .ddk-container .rc-slider-disabled {
      background-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
    }

    .ddk-container .rc-slider-disabled .rc-slider-track {
      background-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
    }

    .ddk-container .rc-slider-disabled .rc-slider-handle,
    .ddk-container .rc-slider-disabled .rc-slider-dot {
      border-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
      background-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
    }

    .ddk-container .rc-slider-tooltip-inner {
      color: ${props => props.theme.body_text};
      background-color: ${props => chroma(props.theme.background_page).alpha(0.5).css()};
      box-shadow: ${props => chroma(props.theme.accent).brighten().css()};
    }

    .ddk-container .rc-slider-tooltip-arrow {
      border-color: transparent;
    }

    .ddk-container .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      border-top-color: ${props => chroma(props.theme.background_content).alpha(0.5).css()};
    }
`
export default themedStyles;
