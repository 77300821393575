const Plotly_Dark = {
    accent: "#7A76FF",
    accent_positive: "#87C795",
    accent_negative: "#D7645F",
    background_content: "#000000",
    background_page: "#333333",
    body_text: "#FFFFFF",
    border: "#454545",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: 0,
    },
    button_border: {
        width: "0px",
        color: "#7A76FF",
        radius: "4px"
    },
    button_capitalization: "none",
    button_text: "#FFFFFF",
    button_background_color: "#7A76FF",
    control_border: {
        width: "1px 1px 1px 1px",
        color: "#454545",
        radius: "4px"
    },
    control_background_color: "#000000",
    control_text: "#FFFFFF",
    card_margin: "20px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#000000",
        radius: "8px",
    },
    card_background_color: "#000000",
    card_box_shadow: "0px 0px 5px rgba(0,0,0,0)",
    card_outline: {
        width: "0px",
        style: "solid",
        color: "#000000"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "0px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#000000",
        radius: "0px",
    },
    card_header_background_color: "#000000",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#7A76FF",
        "#4BC1EE",
        "#D381A3",
        "#DBAC49",
        "#2E741C",
        "#A1763B",
        "#1578C3",
        "#F369CF",
        "#8AD480",
        "#FC8250",
    ],
    colorscale: [
        "#eaeaff",
        "#d9ceff",
        "#c8b2ff",
        "#b696ff",
        "#a57aff",
        "#8e6af6",
        "#765bec",
        "#5f4be3",
        "#473cd9",
        "#302cd0",
    ],
    dbc_primary: "#7A76FF",
    dbc_secondary: "#D381A3",
    dbc_info: "#6296E8",
    dbc_gray: "#696969",
    dbc_success: "#87C795",
    dbc_warning: "#F0C871",
    dbc_danger: "#D7645F",
    font_family: "Open Sans",
    font_family_header: "Open Sans",
    font_family_headings: "Open Sans",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "uppercase",
    header_content_alignment: "spread",
    header_margin: "0px 0px 24px 0px",
    header_padding: "0px 16px 0px 0px",
    header_border: {
        width: "0px 0px 0px",
        style: "solid",
        color: "#202020",
        radius: "0px",
    },
    header_background_color: "#202020",
    header_box_shadow: "0px 1px 3px rgba(0,0,0,0)",
    header_text: "#FFFFFF",
    heading_text: "#FFFFFF",
    text: "#FFFFFF",
    report_background: "#F1F1F1",
    report_background_content: "#FFFFFF",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "Open Sans",
    report_font_size: "12px",
    report_border: "#CCCCCC",
    graph_grid_color: "#4D4D4D",
    table_striped_even: "#000000",
    table_striped_odd: "#000000",
    table_border: "#333333",
    card_accent: "#7A76FF",
};

export default Plotly_Dark;