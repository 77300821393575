import React from 'react';

export default ({expanded = false}) =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.69 122.69">
        <title>{expanded ? "Collapse to card" : "Expand to modal"}</title>
        <g id="Layer_2" dataName="Layer 2">
            <g id="Layer_1-2" dataName="Layer 1">
                <path className="cls-1" d="M 47 16 H 31 C 22.7 16 16 22.7 16 31 V 47 C 15.9 48.6 16.7 50 18.1 50.8 C 19.4 51.6 21.1 51.6 22.4 50.8 C 23.8 50 24.5 48.6 24.5 47 V 31 C 24.5 29.3 25.1 27.6 26.4 26.4 C 27.6 25.1 29.3 24.5 31 24.5 H 47 C 48.6 24.5 50 23.8 50.8 22.4 C 51.6 21.1 51.6 19.4 50.8 18.1 C 50 16.7 48.6 15.9 47 16 Z"/>
                <path className="cls-2" d="M 93.7 16 H 77.7 C 76.1 15.9 74.6 16.7 73.8 18.1 C 73 19.4 73 21.1 73.8 22.4 C 74.6 23.8 76.1 24.5 77.7 24.5 H 93.7 C 95.5 24.5 97.1 25.2 98.3 26.4 C 99.6 27.6 100.2 29.3 100.2 31 V 47 C 100.3 49.3 102.2 51 104.5 51 C 106.7 51 108.6 49.3 108.7 47 V 31 C 108.7 22.7 102 16 93.7 16 Z"/>
                <path className="cls-1" d="M 104.5 73.4 C 102.1 73.4 100.2 75.3 100.2 77.7 V 93.7 C 100.2 95.5 99.5 97.1 98.3 98.3 C 97.1 99.5 95.5 100.2 93.7 100.2 H 77.7 C 76.1 100.1 74.6 100.9 73.8 102.3 C 73 103.6 73 105.3 73.8 106.6 C 74.6 108 76.1 108.8 77.7 108.7 H 93.7 C 102 108.7 108.7 102 108.7 93.7 V 77.7 C 108.7 76.5 108.3 75.5 107.5 74.7 C 106.7 73.9 105.6 73.4 104.5 73.4 Z"/>
                <path className="cls-2" d="M 47 100.2 H 31 C 29.3 100.2 27.6 99.5 26.4 98.3 C 25.2 97.1 24.5 95.5 24.5 93.7 V 77.7 C 24.6 76.1 23.8 74.6 22.5 73.8 C 21.1 73 19.4 73 18.1 73.8 C 16.8 74.6 16 76.1 16 77.7 V 93.7 C 16.1 102 22.8 108.7 31.1 108.7 H 47 C 48.6 108.8 50 108 50.8 106.7 C 51.6 105.3 51.6 103.7 50.8 102.3 C 50 101 48.6 100.2 47 100.3 Z"/>
            </g>
        </g>
    </svg>
