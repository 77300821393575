import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import ColorscalePicker from './react-colorscales/ColorscalePicker';
import { GithubPicker } from 'react-color';
import { parse as parseShadow, stringify as stringifyShadow } from 'css-box-shadow'
import Tabs from './react-tabs/Tabs.react';
import Tab from './react-tabs/Tab.react';
import CopyText from './_CopyText.react';
import {COLORWAYS, BUTTON_BORDERS, CONTROL_BORDERS, CONTAINER_THEMES, CUSTOMIZED_COLORPICKER_COLORS, FONT_SIZES, PRESET_THEMES} from './constants';
import {appendStyle, debounce, extend, getNewShorthand, isValidColor, getValueFromCSSText, capitalizeString, templateLiteralDedent, setThemeVar} from './utils';

import {JsVisitor, PyVisitor, RVisitor, stringify} from './serializer'
import themes_set from './themes/themes';

import style_fonts from '!!raw-loader!../../dash_design_kit/fonts.css';

import style_editor from './css_js/editor/editor.css.js';
import style_editor_slider from './css_js/editor/rc-slider.css.js';
import style_editor_select from './css_js/editor/react-select.css.js';
import style_editor_virtualized_select from './css_js/editor/react-virtualized-select.css.js';

/* ICONS */
// import editor_footer from './icons/editor-footer.svg';
import icon_borders from './icons/icon-borders.svg';
import icon_circle_x from './icons/icon-circle-x.svg';
import icon_colors from './icons/icon-colors.svg';
import icon_info from './icons/icon-info.svg';
import icon_margins from './icons/icon-margins.svg';
import icon_reverse from './icons/icon-reverse.svg';
import icon_padding from './icons/icon-padding.svg';
import icon_save from './icons/icon-save.svg';
import icon_shadows from './icons/icon-shadows.svg';
import icon_top_color from './icons/icon_top_color.svg';
import icon_top_containers from './icons/icon_top_containers.svg';
import icon_top_controls from './icons/icon_top_controls.svg';
import icon_top_export from './icons/icon_top_export.svg';
import icon_top_preset from './icons/icon_top_preset.svg';
import icon_top_reports from './icons/icon_top_reports.svg';
import icon_top_typography from './icons/icon_top_typography.svg';
import IconCardCopy from './icons/icon-card-copy.svg';

import {
    chain,
    equals,
    has,
    indexOf,
    keys,
    mergeRight,
    omit,
    partial,
    pick,
    pluck,
    props,
    range,
    replace,
    reverse,
    take,
    toPairs,
    uniq,
    values
} from 'ramda';
import chroma from 'chroma-js';
import tinycolor from 'tinycolor2';
import Popout from './react-popout';
import { saveAs } from 'file-saver/FileSaver';

const ALL_CHARACTERS = `Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp
Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz 1 2 3 4 5 6 7 8 9 0
! @ # $ % ^ & * { } [ ] ~ , . / ; \``;

/*
 * event polyfill for IE
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
function CustomEvent(event, params) {
    // eslint-disable-next-line no-param-reassign
    params = params || {
        bubbles: false,
        cancelable: false,
        // eslint-disable-next-line no-undefined
        detail: undefined,
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
    );
    return evt;
}
CustomEvent.prototype = window.Event.prototype;

function ColorRoll(props) {
    const {colors, updateColor} = props;
    return (
        <div className="ColorRoll">
            {colors.map(color => (
                <div
                    className="ColorRoll--slice"
                    style={{
                        'backgroundColor': color,
                        'height': `calc(100% / ${colors.length})`
                    }}
                    onClick={() => updateColor(color)}
                />
            ))}
        </div>
    );
}

// class ThemeHover extends Component {
//     constructor() {
//         super();
//     }

//     render() {
//         const {font_family_header, font_family} = this.props.theme;

//         var colors = toPairs(pick([
//             'background_content',
//             'background_page',
//             'accent',
//             'border',
//             'text',
//             'accent_positive',
//             'accent_negative',
//             'card_accent',
//         ], this.props.theme));

//         return (
//             <div className="themeHover"
//             >
//                 {colors.map(color => (
//                     <div
//                         className="Preset--slice"
//                         style={{
//                             'width': `calc(100% / ${colors.length})`,
//                         }}
//                     >
//                         <div className="Preset--slice-swatch"
//                             style={{
//                                 'backgroundColor': color[1],
//                             }}
//                         />
//                         <div className="Preset--slice-desc">
//                             <div className="color-name">{
//                                 /* case_snake -> Snake Case */
//                                 color[0].replace(/_/g, ' ')
//                                 .split(' ').reverse().join(' ')}
//                             </div>
//                             <div className="color-hex">{color[1]}</div>
//                         </div>
//                     </div>
//                 ))}
//                 <div className="Preset--fontPreview-container">
//                     <div style={{'fontFamily': font_family_header}} className="Preset--fontPreview">
//                         <p>Header Font</p>
//                         <h2>{font_family_header}</h2>
//                         <p>{ALL_CHARACTERS}</p>
//                     </div>
//                     <div style={{'fontFamily': font_family}} className="Preset--fontPreview">
//                         <p>Body Font</p>
//                         <h2>{font_family}</h2>
//                         <p>{ALL_CHARACTERS}</p>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

class ColorTab extends Component {
    constructor() {
        super(props);
        this.state = {
            hovered: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.debouncedHandleSubmit = debounce(this.handleSubmit, 400);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            color: this.props.color,
            validColor: isValidColor(this.props.color)
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.color !== nextProps.color) {
            this.setState({
                color: chroma(nextProps.color).name(),
                validColor: isValidColor(nextProps.color)
            });
        }
    }

    handleSubmit(e) {
        const validColor = isValidColor(e.target.value);
        const value = e.target.value;

        if (value !== this.props.color && validColor) {
            this.props.updateColor(value);
        }

        this.setState({
            validColor: validColor
        });
    }

    handleChange(e) {
        /* debouncedHandleSubmit() needs persisted
         * event.target.value (after the handler is called)
         * because it uses the value after a delay with setTimeout()
         * see https://reactjs.org/docs/legacy-event-pooling.html
         * (TODO: no longer needed once we upgrade to React v17; FB considered
         * this an over-optimization)
        */
        e.persist();
        this.setState({
            color: e.target.value
        }, partial(this.debouncedHandleSubmit, [e]));
    }

    render() {
        const {
            color,
            name,
            updateColor,
            suggestedColors,
            onFocus,
            onUnfocus
        } = this.props;
        const {hovered} = this.state;
        const panelStyle = {'backgroundColor': color};
        if (hovered) {
            panelStyle.borderBottomLeftRadius = 0;
            panelStyle.borderBottomRightRadius = 0;
        }
        return (
            <div
                className="ColorTab"
            >

                <div
                    className="ColorTab--panel"
                    style={panelStyle}
                    onMouseEnter={() => {
                        this.setState({hovered: true});
                        if (!hovered) { onFocus(); }
                    }}
                    onMouseLeave={() => {
                        this.setState({hovered: false})
                        onUnfocus();
                    }}
                >

                    <div
                        className={`ColorTab--editor ${hovered ? "": "hidden"}`}
                    >
                        <ColorRoll
                            colors={suggestedColors || []}
                            updateColor={updateColor}
                        />
                    </div>
                </div>


                <div className="ColorTab--labelContainer">
                    <div className="ColorTab--label ColorTab--title">
                        {name}
                    </div>
                    <div
                        className="ColorTab--label ColorTab--footer"
                    >
                        <input
                            className={"ColorTab--input" +
                                (!this.state.validColor ? ' invalid-input' : '')
                            }
                            style={{
                                backgroundImage: !this.state.validColor
                                    ? 'url(' + icon_circle_x + ')'
                                    : 'initial'
                            }}
                            type="text"
                            value={this.state.color}
                            onChange={this.handleChange}
                            onBlur = {this.handleSubmit}
                            onKeyDown = {
                                (e) => e.key === 'Enter' && this.handleSubmit(e)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
ColorTab.defaultProps = {
    onFocus: () => {},
    onUnfocus: () => {}
}

class PresetTab extends Component {
    constructor() {
        super();
    }

    render() {
        const {
            index,
            updateTheme,
            pageColors,
            inverse
        } = this.props;

        var theme = this.props.theme;

        if (inverse) {
            theme = extend({}, theme);
            keySwap(theme, 'background_content', 'background_page');
        }

        var colors = pick([
            'background_content',
            'background_page',
            'accent',
            'border',
            'text',
            'accent_positive',
            'accent_negative',
            'card_accent',
        ], theme);

        const orderedColors = pick([
            'background_content',
            'background_page',
            'accent',
            'border',
            'text',
        ], colors);

        const isCurrentTheme = equals(pageColors, colors);

        return (
            <div
                className={"PresetTab" + (isCurrentTheme ? " PresetTab--current" : "")}
                onClick={partial(updateTheme, [theme])}
            >
                {themes_set[index].image ? (
                    <div className="PresetTab--image-wrap">
                        <img className="PresetTab--image" src={themes_set[index].image}/>
                    </div>
                ) : (
                    <div className={'Preset--swatch-container'} >
                    {
                        toPairs(orderedColors).map(color => (
                        <div
                            className={"Preset--swatch Preset--swatch-" + color[0]} // the key (name)
                            style={{
                                'backgroundColor': color[1], // the value (hex code)
                            }}
                        />
                    ))}
                    </div>
                )}
                <div className="PresetTab--name">{themes_set[index].name}</div>
                {/* <ThemeHover theme={theme}></ThemeHover> */}
            </div>
        );
    }
}

function ThemePresets(props) {
    const {themes, updateTheme, pageColors} = props;
    // Inverse themes disabled for 1.9.0. release
    return (
        <div className="presetsContainer">
            {themes.map((theme, index) => (
                <PresetTab
                    theme={theme}
                    index={index}
                    updateTheme={updateTheme}
                    pageColors={pageColors}
                    inverse={false}
                ></PresetTab>
            ))}
        </div>
    );
}


function PageColorSettings(props) {
    const {colors, updateColor} = props;

    const suggestedColors = {
        'background_content': {
            'name': 'Content Background'
        },
        'background_page': {
            'name': 'Page Background'
        },
        'border': {
            'name': 'Border'
        },
        'text': {
            'name': 'Text'
        },
        'accent': {
            'suggested': [
                // designed by http://clrs.cc/ & sidi
                '#004172', // SIDI NAVY
                '#1a9dff', // SIDI BLUE
                '#b9cfed', // SIDI INK BLUE
                '#80fcff', // SIDI AQUA
                '#3D9970', // CLRS OLIVE
                '#2ECC40', // CLRS GREEN
                '#75c9be', // SIDI TEAL GREEN
                '#2bfebe', // SIDI NEON GREEN
                '#ffd15f', // SIDI YELLOW
                '#b79a5e', // SIDI BROWN
                '#ff7f1f', // SIDI ORANGE
                '#e80a0a', // SIDI RED
                '#ff2c6d', // SIDI PINK
                '#A239CA', // CLRS FUCHSIA
                '#813772', // CLRS POSY
                '#dddddd', // SIDI GREY
                '#aaaaaa', // SIDI SILVER
                '#ffffff', // WHITE
                '#232323', // SIDI BLACK
            ],
            'name': 'Accent'
        },
        'accent_positive': {
            'suggested': [
                // designed by sidi
                '#00bc5e',
                '#73efbf',
                '#aeffef',
                '#33ffe6',
                '#4ab2ff'
            ],
            'name': 'Accent Positive',
        },
        'accent_negative': {
            'suggested': [
                '#e80a0a',
                '#ff5a5a',
                '#ff9f9f',
                '#ff2c6d',
                '#ff6e2e',
                '#ffd348'
            ],
            'name': 'Accent Negative'
        },
        'card_accent': {
            'suggested': [
                '#e80a0a',
                '#ff5a5a',
                '#ff9f9f',
                '#ff2c6d',
                '#ff6e2e',
                '#ffd348'
            ],
            'name': 'Data Card Accent'
        },
    }

    return (
        <div className="colorsContainer" >
            {
                ['background_content', 'background_page', 'border', 'text', 'accent', 'accent_positive', 'accent_negative', 'card_accent'].map(color => (
                    <ColorTab
                        name={suggestedColors[color].name}
                        color={colors[color]}
                        updateColor={newColor => updateColor(
                            {[color]: newColor}
                        )}
                        suggestedColors={
                            has('suggested', suggestedColors[color]) ?
                            suggestedColors[color].suggested
                            : uniq([
                                chroma(colors[color]).darken(2).css(),
                                chroma(colors[color]).darken(1.5).css(),
                                chroma(colors[color]).darken(1).css(),
                                chroma(colors[color]).darken(0.5).css(),
                                colors[color],
                                chroma(colors[color]).brighten(0.5).css(),
                                chroma(colors[color]).brighten(1).css(),
                                chroma(colors[color]).brighten(1.5).css(),
                                chroma(colors[color]).brighten(2).css(),
                            ])
                        }
                    />
                ))
            }
        </div>
    )
}

function DBCColorSettings(props) {
    const {colors, updateColor} = props;
    const suggestedColors = {
        'dbc_primary': {
            name: 'Primary',
            suggested: [
                // designed by http://clrs.cc/ & sidi
                '#004172', // SIDI NAVY
                '#1a9dff', // SIDI BLUE
                '#b9cfed', // SIDI INK BLUE
                '#80fcff', // SIDI AQUA
                '#3D9970', // CLRS OLIVE
                '#2ECC40', // CLRS GREEN
                '#75c9be', // SIDI TEAL GREEN
                '#2bfebe', // SIDI NEON GREEN
                '#ffd15f', // SIDI YELLOW
                '#b79a5e', // SIDI BROWN
                '#ff7f1f', // SIDI ORANGE
                '#e80a0a', // SIDI RED
                '#ff2c6d', // SIDI PINK
                '#A239CA', // CLRS FUCHSIA
                '#813772', // CLRS POSY
                '#dddddd', // SIDI GREY
                '#aaaaaa', // SIDI SILVER
                '#ffffff', // WHITE
                '#232323', // SIDI BLACK
            ],
        },
        dbc_secondary: {
            name: 'Secondary'
        },
        dbc_info: {
            name: 'Info'
        },
        dbc_gray: {
            name: 'Gray (Bootstrap neutral)'
        },
        dbc_success: {
            name: 'Success'
        },
        dbc_warning: {
            name: 'Warning'
        },
        dbc_danger: {
            name: 'Danger'
        }
    }

    return (
        <div className="colorsContainer" >
            {
                ['dbc_primary', 'dbc_secondary', 'dbc_info', 'dbc_gray', 'dbc_success', 'dbc_warning', 'dbc_danger'].map(color => (
                    <ColorTab
                        name={suggestedColors[color].name}
                        color={colors[color]}
                        updateColor={newColor => updateColor(
                            {[color]: newColor}
                        )}
                        suggestedColors={
                            has('suggested', suggestedColors[color]) ?
                            suggestedColors[color].suggested
                            : uniq([
                                chroma(colors[color]).darken(2).css(),
                                chroma(colors[color]).darken(1.5).css(),
                                chroma(colors[color]).darken(1).css(),
                                chroma(colors[color]).darken(0.5).css(),
                                colors[color],
                                chroma(colors[color]).brighten(0.5).css(),
                                chroma(colors[color]).brighten(1).css(),
                                chroma(colors[color]).brighten(1.5).css(),
                                chroma(colors[color]).brighten(2).css(),
                            ])
                        }
                    />
                ))
            }
        </div>
    )
}

function takeN(list, N) {
    const interval = Math.floor((list.length) / (N));
    return take(N)(list.filter((e, i) => i % interval === 0));
}

function keySwap(list, a, b) {
    var tmp = list[a];
    list[a] = list[b];
    list[b] = tmp;
    return list;
}

const FONT_LIST = [
    "Abel",
    "Abril Fatface",
    "Arimo",
    "Cardo",
    "Computer Modern",
    "Computer Modern Sans",
    "Computer Modern Typewriter",
    "Crimson Text",
    "Dosis",
    "Domine",
    "Gentium Book Basic",
    "Lato",
    "Libre Franklin",
    "Lora",
    "Merriweather",
    "Merriweather Sans",
    "Mukta",
    "Nanum Gothic",
    "Noto Sans",
    "Noto Serif",
    "Nunito",
    "Old Standard TT",
    "Open Sans",
    "Oswald",
    "Playfair Display",
    "Poppins",
    "PT Sans",
    "PT Serif",
    "Quattrocento Sans",
    "Quicksand",
    "Raleway",
    "Roboto",
    "Roboto Mono",
    "Source Sans Pro",
    "Ubuntu",
    "Vollkorn"
]

const VALID_BORDER_STYLES = [
    "none",
    "hidden",
    "dotted",
    "dashed",
    "solid",
    "double",
    "groove",
    "ridge",
    "inset",
    "outset",
    "initial",
    "inherit"
]

const VALID_TEXT_TRANSFORMS = [
    "none",
    "capitalize",
    "uppercase",
    "lowercase"
]

class SwatchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.defaultColor,
            validColor: isValidColor(this.props.defaultColor),
            shorthandColor: false,
            showSwatchPicker: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.debouncedHandleSubmit = debounce(this.handleSubmit, 400);
        this.outsideClickListener = this.outsideClickListener.bind(this);
        this.toggleSwatchPicker = this.toggleSwatchPicker.bind(this);
    }

    // TODO: find a safer way
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.defaultColor !== this.state.color) {
            this.setState({
                color: nextProps.defaultColor,
                validColor: isValidColor(nextProps.defaultColor),
                shorthandColor: !isValidColor(nextProps.defaultColor)
                  // TODO: DRY
                  && ['Top', 'Right', 'Bottom', 'Left'].map(side => getValueFromCSSText(`border${side}Color`, 'borderColor', nextProps.defaultColor)).every(isValidColor)
            });
        }
    }

    componentDidMount() {
      const editorDocument = this.props.popoutWindow.document;
      editorDocument.addEventListener('click', this.outsideClickListener)
    }

    componentWillUnmount() {
      const editorDocument = this.props.popoutWindow.document;
      editorDocument.removeEventListener('click', this.outsideClickListener)
    }

    toggleSwatchPicker() {
      this.setState({
          showSwatchPicker: !this.state.showSwatchPicker,
      });
    };

    outsideClickListener(e) {
        if (this.state.showSwatchPicker
            && e
            && e.target
            // loose check for colorpicker swatch square or text input
            && !e.target.hasAttribute('tabIndex')
            && !e.target.classList.contains('DraggableSwatch--input')
            && !e.target.classList.contains('swatchInput--container')
            && !e.target.hasAttribute('title')) {
                this.toggleSwatchPicker();
        }
    }

    handleChange(e) {
        e.persist();
        this.setState({
            color: e.target.value,
            validColor: isValidColor(e.target.value),
            shorthandColor: !isValidColor(e.target.value)
            && ['Top', 'Right', 'Bottom', 'Left'].map(side => getValueFromCSSText(`border${side}Color`, 'borderColor', e.target.value)).every(isValidColor)
        }, partial(this.debouncedHandleSubmit, [e]));
    }

    handleSubmit(e) {
        // we clicked on a swatch, so don't submit the text input's value
        if (e && e.relatedTarget && e.relatedTarget.hasAttribute('tabIndex') && e.relatedTarget.hasAttribute('title')) {
            e.preventDefault();
            return;
        }
        if (this.state.color && this.state.validColor) {
            this.props.onChange(e.target.value)
        }
    }

    render() {
        return (
          <div
              className="swatchInput--container"
              onClick={this.toggleSwatchPicker}
          >
              <input
                style={{
                    backgroundImage: !this.state.validColor && !this.state.shorthandColor
                        ? 'url(' + icon_circle_x + ')'
                        : 'initial',
                    backgroundColor: this.state.validColor
                        ? this.state.color
                        : 'initial',
                    color: this.state.validColor && chroma(this.state.color).luminance() < 0.5
                      // transparent has 0 luminance
                      && this.state.color !== 'rgba(0,0,0,0)'
                        ? 'white'
                        : 'black'
                }}
                className={`Swatch--input ${(!this.state.validColor && !this.state.shorthandColor ? ' invalid-input' : '')}`}
                type="text"
                value={this.state.color}
                onClick={ e => { e.target.select(); this.toggleSwatchPicker(); } }
                onChange={this.handleChange}
                onBlur={this.handleSubmit}
                onKeyDown = {
                    (e) => e.key === 'Enter' && this.handleSubmit(e)
                }
              />
              <div
                  className="dragDropPicker"
              >
                  {this.state.showSwatchPicker && (
                    <GithubPicker
                        color={ this.state.color }
                        triangle='hide'
                        colors={[this.state.color].concat(CUSTOMIZED_COLORPICKER_COLORS)}
                        onChangeComplete={val => {
                            this.props.onChange(val.hex);
                            this.setState({ color: val.hex, validColor: Boolean(val.hex)});
                        }}
                    />
                  )}
                </div>
          </div>
        )
    }
}

class CSSAttrInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentValue: props.value
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.debouncedHandleSubmit = debounce(this.handleSubmit, 400);
        this.handleChange = this.handleChange.bind(this);
    }

    // TODO: find a safer way
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.currentValue) {
            this.setState({currentValue: nextProps.value});
        }
    }

    handleSubmit(e) {
        this.props.onChange(this.props.unit ? `${e.target.value}${this.props.unit}` : e.target.value)
    }

    handleChange(e) {
        // see https://reactjs.org/docs/legacy-event-pooling.html
        e.persist();
        this.setState({
            currentValue: e.target.value
        }, partial(this.debouncedHandleSubmit, [e]));
    }

    render() {
        return (
            <div style={this.props.style}>
                <span><small>{this.props.label}</small></span>
                <div>
                    <input
                      key={`${this.props.key}Input`}
                      min={0}
                      max={100}
                      type="number"
                      onChange={this.handleChange}
                      value={this.state.currentValue}
                      onBlur={this.handleSubmit}
                      placeholder={'-'}
                      onKeyDown = {
                          (e) => e.key === 'Enter' && this.handleSubmit(e)
                      }
                    />
                    <span>{this.props.unit}</span>
                </div>
            </div>
        )
    }
}

function fontOptionRenderer(option) {
    const {value} = option;
    return (
        <div
            className="FontPicker--option"
            style={{
                'fontFamily': value
            }}
         >
        {value}
        </div>
    )
}

function titleCapitalizationRenderer(option) {
    const {value} = option;
    return (
        <div
            className="headerCapitalization--option"
            style={{
                'text-transform': value
            }}
         >
        {value}
        </div>
    )
}

function CSSAttrPicker(props) {
    const {
        onChange,
        optionRenderer,
        options,
        value,
        className,
        placeholder,
        label
    } = props;

    function defaultOptionRenderer(option) {
        return (
            <div>{option.label}</div>
        )
    }

    function renderValue(option) {
        return <span>{option.label ?? option.value}</span>;
    }

    return (
        <div className={className}>
            {label &&
              <span><small>{label}</small></span>
            }
            <Select
                options={options}
                value={value}
                clearable={false}
                placeholder={placeholder}
                optionRenderer={optionRenderer ?? defaultOptionRenderer}
                valueRenderer={renderValue}
                onChange={option => {
                    onChange(option.value)
                }}
            />
        </div>
    );
}

class OutlinePicker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            outlineShorthands: this.props.outlineShorthands
        };

        this.optionRenderer = this.optionRenderer.bind(this);
        this.renderValue = this.renderValue.bind(this);
    }

    optionRenderer(option) {
        const {value} = option;
        return (
            <div>
                {value}
            </div>
        )
    }

    renderValue(option) {
        return <span>{option.value}</span>;
    }

    render() {
        const {onChange} = this.props;
        const {outlineShorthands} = this.state;

        return (
            <div
                className="borderPicker--container outlineRow"
            >
                <CSSAttrInput
                    value={
                        parseInt(getValueFromCSSText(
                            `outlineWidth`,
                            'outlineWidth',
                            outlineShorthands.width
                        ), 10)
                    }
                    unit='px'
                    onChange={value => partial(onChange,
                        [
                            'width',
                            getNewShorthand(`outlineWidth`, value, 'outlineWidth', outlineShorthands.width)
                        ]
                    )()}
                />
                <Select
                    className="BorderPicker"
                    options={VALID_BORDER_STYLES.map(outline_style => ({value: outline_style}))}
                    value={getValueFromCSSText(`outlineStyle`, 'outlineStyle', outlineShorthands.style)}
                    clearable={false}
                    placeholder={"Select outline style"}
                    optionRenderer={this.optionRenderer}
                    valueRenderer={this.renderValue}
                    onChange={option => onChange(
                        'style',
                        getNewShorthand(`outlineStyle`, option.value, 'outlineStyle', outlineShorthands.style )
                    )}
                />
                <SwatchInput
                    defaultColor={getValueFromCSSText(`outlineColor`, 'outlineColor', outlineShorthands.color)}
                    onChange={value => partial(onChange,
                        [
                            'color',
                            getNewShorthand(`outlineColor`, value, 'outlineColor', outlineShorthands.color)
                        ]
                    )()}
                    popoutWindow={this.props.popoutWindow}
                />
            </div>
        );
    }
}

class BorderPicker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            borderShorthands: this.props.borderShorthands
        };

        this.optionRenderer = this.optionRenderer.bind(this);
        this.renderValue = this.renderValue.bind(this);
    }

    optionRenderer(option) {
        const {value} = option;
        return (
            <div>
                {value}
            </div>
        )
    }

    renderValue(option) {
        return <span>{option.value}</span>;
    }

    render() {
        const {onChange} = this.props;
        const {borderShorthands} = this.state;

        // TODO: clean this up
        var side = this.props.side;
        if (side === "all") {
            side = ""
        }

        var sideLabel = "";
        var cssProperty = "";
        switch(this.props.side) {
            case "top":
                sideLabel = "Top Left";
                cssProperty = "borderTopLeftRadius";
                break;
            case "right":
                sideLabel = "Top Right";
                cssProperty = "borderTopRightRadius";
                break;
            case "bottom":
                sideLabel = "Bottom Right";
                cssProperty = "borderBottomRightRadius";
                break;
            case "left":
                sideLabel = "Bottom Left";
                cssProperty = "borderBottomLeftRadius";
                break;
            default:
                sideLabel = "";
        }

        return (
            <div
                className="borderPicker--container borderRow"
            >
                <div>{(side.length > 0 && side) || "all"}</div>
                <CSSAttrInput
                    key={`${this.props.key}${capitalizeString(side)}Width`}
                    value={
                        // no spaces in shorthand = it's an "all" value
                        side !== "" || typeof borderShorthands.width === 'number' || borderShorthands.width.indexOf(' ') < 0
                          ? parseInt(getValueFromCSSText(
                                `border${capitalizeString(side)}Width`,
                                'borderWidth',
                                borderShorthands.width
                            ), 10)
                          : ''
                    }
                    unit='px'
                    onChange={value => partial(onChange,
                        [
                            'width',
                            getNewShorthand(`border${capitalizeString(side)}Width`, value, 'borderWidth', borderShorthands.width)
                        ]
                    )()}
                />
                <Select
                    className="BorderPicker"
                    options={VALID_BORDER_STYLES.map(border_style => ({value: border_style}))}
                    value={getValueFromCSSText(`border${capitalizeString(side)}Style`, 'borderStyle', borderShorthands.style)}
                    clearable={false}
                    placeholder={"Select border style"}
                    optionRenderer={this.optionRenderer}
                    valueRenderer={this.renderValue}
                    onChange={option => onChange(
                        'style',
                        getNewShorthand(`border${capitalizeString(side)}Style`, option.value, 'borderStyle', borderShorthands.style )
                    )}
                />
                <SwatchInput
                    defaultColor={getValueFromCSSText(`border${capitalizeString(side)}Color`, 'borderColor', borderShorthands.color)}
                    onChange={value => partial(onChange,
                        [
                            'color',
                            getNewShorthand(`border${capitalizeString(side)}Color`, value, 'borderColor', borderShorthands.color)
                        ]
                    )()}
                    popoutWindow={this.props.popoutWindow}
                />
                {side === "" &&
                    <React.Fragment>
                        <CSSAttrInput
                            key={`${this.props.key}${capitalizeString(side)}Radius`}
                            label='All'
                            value={
                                // no spaces in shorthand = it's an "all" value
                                typeof borderShorthands.radius === 'number' || borderShorthands.radius.indexOf(' ') < 0
                                  ? parseInt(getValueFromCSSText(
                                        `border${capitalizeString(side)}Radius`,
                                        'borderRadius',
                                        borderShorthands.radius
                                    ), 10)
                                  : ''
                            }
                            unit='px'
                            onChange={value => partial(onChange,
                                [
                                    'radius',
                                    getNewShorthand(
                                        `border${capitalizeString(side)}Radius`,
                                        value,
                                        'borderRadius',
                                        borderShorthands.radius
                                    )
                                ]
                            )()}
                        />
                        <CSSAttrInput style={{'visibility': 'hidden'}} />
                    </React.Fragment>
                }
                {side !== "" &&
                    <React.Fragment>
                        <CSSAttrInput
                            key={`${this.props.key}${capitalizeString(side)}TopRadius`}
                            label={`${sideLabel}`}
                            value={parseInt(getValueFromCSSText(cssProperty, 'borderRadius', borderShorthands.radius), 10)}
                            unit='px'
                            onChange={value => partial(onChange,
                                [
                                    'radius',
                                    getNewShorthand(cssProperty, value, 'borderRadius', borderShorthands.radius)
                                ]
                            )()}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

function getNewShadows(shadows, key, value, idx) {
     shadows[idx][key] = value;
     return shadows;
}

function ShadowPickers(props) {
    const {onChange} = props;
    let shadowString = props.shadowString;

    if (shadowString === null) {
        shadowString = '';
    }

    let parsedShadows = parseShadow(shadowString)

    parsedShadows = Array.isArray(parsedShadows)
      ? parsedShadows
      : [parsedShadows];

    var shadowPickers = parsedShadows.map((shadowObj, i) => (
        <div
            className="shadowPicker--container shadowRow"
        >
            <div>
                <input
                    type="checkbox"
                    name="inset"
                    checked={parsedShadows[i].inset}
                    onChange={e => onChange(
                        stringifyShadow(
                           getNewShadows(parsedShadows, 'inset', e.target.checked, i)
                        )
                    )}
                />
            </div>
            <CSSAttrInput
                value={parsedShadows[i].offsetX}
                onChange={value => {
                    onChange(
                        stringifyShadow(
                            getNewShadows(parsedShadows, 'offsetX', parseInt(value, 10), i)
                        )
                    )
                }}
            />
            <CSSAttrInput
                value={parsedShadows[i].offsetY}
                onChange={value => {
                    onChange(
                        stringifyShadow(
                            getNewShadows(parsedShadows, 'offsetY', parseInt(value, 10), i)
                        )
                    )
                }}
            />
            <CSSAttrInput
                value={parsedShadows[i].blurRadius}
                onChange={value => {
                    onChange(
                        stringifyShadow(
                            getNewShadows(parsedShadows, 'blurRadius', parseInt(value, 10), i)
                        )
                    )
                }}
            />
            <CSSAttrInput
                value={parsedShadows[i].spreadRadius}
                onChange={value => {
                    onChange(
                        stringifyShadow(
                            getNewShadows(parsedShadows, 'spreadRadius', parseInt(value, 10), i)
                        )
                    )
                }}
            />
            <SwatchInput
                defaultColor={parsedShadows[i].color}
                onChange={value => {
                    onChange(
                        stringifyShadow(
                            getNewShadows(parsedShadows, 'color', value, i)
                        )
                    )
                }}
                popoutWindow={props.popoutWindow}
            />
        </div>
    ));

    return shadowPickers;
}

function ColorWayPicker(props) {
    const {onChange, options, value} = props;
    function optionRenderer(option) {
        const {label} = option;
        return (
            <div className="ColorWayPicker--option cf">
                {JSON.parse(label).map(color => (
                    <div
                        className="ColorWayPicker--option-item"
                        style={{
                            'backgroundColor': color,
                        }}
                    />
                ))}
            </div>
        )
    }
    return (
        <Select
            options={options}
            value={JSON.stringify(value)}
            searchable={false}
            clearable={false}
            optionRenderer={optionRenderer}
            valueRenderer={optionRenderer}
            placeholder={'Custom'}
            onChange={option => {
                onChange(JSON.parse(option.value))
            }}
            closeOnSelect={true}
        />
    );
}

function DefinitionTab(props) {

    const {language, languageAbbr, that, description, popoutWindow} = props;

    let assignment;

    if (language === 'python') {
        assignment = 'theme = '
    } else if (language === 'r') {
        assignment = 'theme <- '
    } else if (language === 'javascript') {
        assignment = 'window.theme = '
    } else {
        assignment = ''
    }

    const generatedTheme = assignment + stringify(that[`${languageAbbr}Visitor`].visit(omit(['updateProps', 'popoutWindow'], that.props)))

    return (
        <Tab>
            <div className="Settings">
                <div className="Settings--title--container">
                    <div className="Settings--title--text">
                        <span className="Settings--title">
                            {`${capitalizeString(language)} Code`}
                        </span>
                        <span className="Settings--subtitle">
                            {description}
                        </span>
                    </div>
                    <div className="saveTheme--buttons">
                        {language === 'javascript' &&
                            <button
                                id='saveTheme'
                                onClick={() => {
                                    var blob = new Blob([
                                            "// Place in your app's '/assets' directory \n"
                                            + generatedTheme
                                        ],
                                        {type: "application/javascript;charset=utf-8"});
                                    saveAs(blob, "theme.js");
                                }}
                            ><img className="editor--icon" src={icon_save}/>{ 'Download'}</button>
                        }
                        <CopyText
                            literalText={generatedTheme}
                            customWindow={popoutWindow}
                        >
                            <div className='copyDef-container'>
                                <IconCardCopy/>
                                <span>Copy</span>
                            </div>
                        </CopyText>
                    </div>
                </div>
                <pre className="themeCode">
                    <code>{generatedTheme}</code>
                </pre>
            </div>
        </Tab>
    )
}

function ContainerTab(props) {
    const {component_name, updateProps, popoutWindow} = props;
    const component_label = component_name.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());

    return  (
        <Tab>
            <div className='rounded-border with-padding'>
                <div className="Settings--title--container">
                    <div className="Settings--title--text">
                        <span className="Settings--title">
                            {`${component_label}s`}
                        </span>
                    </div>
                </div>

                { component_name !== 'header' && (
                    <div className='containersRow'>
                        <div className='containerAttribute'>
                            <div className="Settings--title--container">
                                <div className="Settings--icon">
                                    <img className="editor--icon" src={icon_margins}/>
                                </div>
                                <div className="Settings--title--text">
                                    <span className="Settings--title">
                                        {`${component_label} Margins`}
                                    </span>
                                </div>
                                <CSSAttrInput
                                    value={parseInt(props[`${component_name}_margin`], 10)}
                                    unit='px'
                                    onChange={value => updateProps({
                                        [`${component_name}_margin`]: value
                                    })}
                                />
                            </div>
                        </div>
                        <div className='containerAttribute'>
                            <div className="Settings--title--container">
                                <div className="Settings--icon">
                                    <img className="editor--icon" src={icon_padding}/>
                                </div>
                                <div className="Settings--title--text">
                                    <span className="Settings--title">
                                        {`${component_label} Padding`}
                                    </span>
                                </div>
                                <CSSAttrInput
                                    value={parseInt(props[`${component_name}_padding`], 10)}
                                    unit='px'
                                    onChange={value => updateProps({
                                        [`${component_name}_padding`]: value
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                )}

                { component_name !== 'card' && (
                    <div className='containersRow'>
                        <div className='containerAttribute'>
                            <div className="Settings--title--container">
                                <div className="Settings--icon">
                                    <img className="editor--icon" src={icon_colors}/>
                                </div>
                                <div className="Settings--title--text">
                                    <span className="Settings--title">
                                        {`${component_label} Background Color`}
                                    </span>
                                </div>
                                <SwatchInput
                                    defaultColor={props[`${component_name}_background_color`]}
                                    onChange={(value) => {
                                        updateProps({
                                            [`${component_name}_background_color`]: value
                                        });
                                    }}
                                    popoutWindow={popoutWindow}
                                />
                            </div>
                        </div>

                    </div>
                )}

                { component_name === 'header' && (
                    <div className='containersRow'>
                        <div className='containerAttribute'>
                            <div className="Settings--title--container">
                                <div className="Settings--icon">
                                    <img className="editor--icon" src={icon_margins}/>
                                </div>
                                <div className="Settings--title--text">
                                    <span className="Settings--title">
                                        {`${component_label} Content Alignment`}
                                    </span>
                                </div>
                                <CSSAttrPicker
                                    value={props.header_content_alignment}
                                    options={[
                                        {value: 'spread', label: 'Spread'},
                                        {value: 'center', label: 'Center'},
                                        {value: 'left', label: 'Left'},
                                        {value: 'right', label: 'Right'},
                                    ]}
                                    onChange={value => updateProps({header_content_alignment: value})}
                                    className="HeaderAlignmentPicker"
                                    placeholder="Select header content alignment"
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className='containersRow bordersRow'>
                    <div className='containerAttribute'>
                        <div className="Settings--title--container">
                            <div className="Settings--icon">
                                <img className="editor--icon" src={icon_borders}/>
                            </div>
                            <div className="Settings--title--text">
                                <span className="Settings--title">
                                    {`${component_label} Borders`}
                                </span>
                            </div>
                            <div
                                id='borderPicker--propLabels'
                                className="borderPicker--container borderRow"
                                style={{'borderBottom': '1px solid lightgrey'}}
                            >
                                <div>
                                    <span><small>Side</small></span>
                                </div>
                                <div>
                                    <span><small>Width</small></span>
                                </div>
                                <div>
                                    <span><small>Style</small></span>
                                </div>
                                <div>
                                    <span><small>Color</small></span>
                                </div>
                                <div>
                                    <span><small>Radius (Corners)</small></span>
                                </div>
                            </div>
                            {["all", "top", "right", "bottom", "left"].map(side => (
                                <BorderPicker
                                    key={side}
                                    side={side}
                                    borderShorthands={props[`${component_name}_border`]}
                                    onChange={(attr, value) => {
                                        updateProps({
                                        [`${component_name}_border`]: {
                                                [attr]: value
                                            }
                                        });
                                    }}
                                    popoutWindow={popoutWindow}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                { component_name === 'card' && (
                    <div className='containersRow bordersRow'>
                        <div className='containerAttribute'>
                            <div className="Settings--title--container">
                                <div className="Settings--icon">
                                    <img className="editor--icon" src={icon_borders}/>
                                </div>
                                <div className="Settings--title--text">
                                    <span className="Settings--title">
                                        {`${component_label} Outline`}
                                    </span>
                                </div>
                                <div
                                    className="borderPicker--container outlineRow"
                                    style={{'borderBottom': '1px solid lightgrey'}}
                                >
                                    <div>
                                        <span><small>Width</small></span>
                                    </div>
                                    <div>
                                        <span><small>Style</small></span>
                                    </div>
                                    <div>
                                        <span><small>Color</small></span>
                                    </div>
                                </div>
                                <OutlinePicker
                                    outlineShorthands={props[`${component_name}_outline`]}
                                    onChange={(attr, value) => {
                                        updateProps({
                                        [`${component_name}_outline`]: {
                                                [attr]: value
                                            }
                                        });
                                    }}
                                    popoutWindow={popoutWindow}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className='containersRow shadowsRow'>
                    <div className='containerAttribute'>
                        <div className="Settings--title--container">
                            <div className="Settings--icon">
                                <img className="editor--icon" src={icon_shadows}/>
                            </div>
                            <div className="Settings--title--text">
                                <span className="Settings--title">
                                    {`${component_label} Box Shadows`}
                                </span>
                                <div className="Settings--title">
                                    <small>
                                    Multiple rows = multiple layered box shadows
                                    </small>
                                </div>
                            </div>
                            <div
                                className="shadowPicker--container"
                                style={{'borderBottom': '1px solid lightgrey'}}
                            >
                                <div>
                                    <span><small>Inset?</small></span>
                                </div>
                                <div>
                                    <span><small>X Offset</small></span>
                                </div>
                                <div>
                                    <span><small>Y Offset</small></span>
                                </div>
                                <div>
                                    <span><small>Blur Radius</small></span>
                                </div>
                                <div>
                                    <span><small>Spread Radius</small></span>
                                </div>
                                <div>
                                    <span><small>Color</small></span>
                                </div>
                            </div>
                            <ShadowPickers
                                shadowString={props[`${component_name}_box_shadow`]}
                                onChange={value => updateProps({[`${component_name}_box_shadow`]: value})}
                                style={{'flex': '1'}}
                                popoutWindow={popoutWindow}
                            >
                            </ShadowPickers>
                        </div>
                    </div>
                </div>
            </div>
        </Tab>
    )
}

class Editor extends Component {
    constructor() {
        super();
        this.state = {
            colorways: keys(COLORWAYS).map(
                k => JSON.stringify(takeN(COLORWAYS[k], 5))
            ),
            isWaiting: false,
            themes: PRESET_THEMES,
            tabs: {},
            colorscaleTabs: {},
            containersTabs: {},
            controlsTabs: {},
            colorsTabs: {},
            definitionTabs: {}
        }

        this.updateTheme = this.updateTheme.bind(this);
        this.generateCode = this.generateCode.bind(this);
        this.JsVisitor = new JsVisitor();
        this.PyVisitor = new PyVisitor();
        this.RVisitor = new RVisitor();
    }

    componentDidMount() {
        appendStyles(this.props.popoutWindow.document);
        copyFonts(this.props.popoutWindow.document);
    }

    updateTheme(colors) {
        const {updateProps} = this.props;
        updateProps(colors);
    }

    generateCode() {
        const serialized =
            replace(
                /false/g, 'False',
                replace(
                    /true/g, 'True',
                    JSON.stringify(
                        omit(['updateProps', 'popoutWindow'], this.props), null, 4
                    )
                )
            )

        return `theme = ${serialized}`;
    }

    render() {
    /* Reminder: any new variables should be populated
       across this app - see
       https://github.com/plotly/dash-design-kit/pull/273 */
        const {
            accent,
            accent_positive,
            accent_negative,
            background_content,
            background_page,
            body_text,
            border,
            // border_style,
            // breakpoint_font,
            // breakpoint_stack_blocks,
            button_border,
            button_background_color,
            button_capitalization,
            button_text,
            // card_border,
            // card_background_color,
            // card_box_shadow,
            // card_margin,
            // card_padding,
            // card_outline,
            // card_header_border,
            // card_header_background_color,
            // card_header_box_shadow,
            // card_header_margin,
            // card_header_padding,
            card_accent,
            colorway,
            colorscale,
            control_border,
            control_background_color,
            control_text,
            dbc_primary,
            dbc_secondary,
            dbc_info,
            dbc_gray,
            dbc_success,
            dbc_warning,
            dbc_danger,
            font_family,
            font_size,
            // font_size_smaller_screen,
            font_family_header,
            font_size_header,
            font_family_headings,
            font_headings_size,
            graph_grid_color,
            // header_border,
            // header_background_color,
            // header_box_shadow,
            title_capitalization,
            // header_content_alignment,
            // header_margin,
            // header_padding,
            header_text,
            heading_text,
            text,
            report_font_family,
            report_font_size,
            report_background_page,
            report_background_content,
            report_text,
            report_border,
            report_background,
            updateProps,
            table_striped_even,
            table_striped_odd,
            table_border
        } = this.props;

        const {
            // colorways,
            colorscaleTabs,
            containersTabs,
            controlsTabs,
            colorsTabs,
            definitionTabs,
            tabs,
            themes
        } = this.state;

        const pageColors = {background_content, background_page, border, text, accent, accent_positive, accent_negative, card_accent};

        const dbcColors = {dbc_primary, dbc_secondary, dbc_info, dbc_gray, dbc_success, dbc_warning, dbc_danger};

        const FONT_SIZE_OPTIONS = [
            {'label': 'Smaller', 'value': 'SMALLER'},
            {'label': 'Regular', 'value': 'REGULAR'},
            {'label': 'Larger', 'value': 'LARGER'},
        ];

        return (
          <div className="editor" data-waiting={this.state.isWaiting}>
            <div className="Settings--container">

                <div className="editor-header-wrap">
                    <div className="editor-header">
                        <div className="editor-header-left">
                            <div className="editor-header-logo">&nbsp;</div>
                            <div className="editor-header-name">Theme Editor</div>
                        </div>
                        <div className="editor-header-right">
                            <a className="editor-header-button" href="/docs/installation" target="_blank">Documentation</a>
                        </div>
                    </div>
                </div>

                <Tabs
                    id='tabs'
                    setProps={newTabsState => this.setState({tabs: mergeRight(tabs, newTabsState)})}
                    {...tabs}
                >
                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_preset}/>
                            {'Presets'}
                        </div>
                    )}>
                        <div>
                            <div className='presetsTab'>
                                <div className="Settings">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">{'Preset Themes'}</span>
                                        </div>
                                    </div>
                                    <ThemePresets updateTheme={this.updateTheme} themes={themes} pageColors={pageColors}></ThemePresets>
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_color}/>
                            {'Colors'}
                        </div>
                    )}>
                        <Tabs
                            className='Advanced-tabs Colors-tabs'
                            setProps={newColorsTabs => this.setState({colorsTabs: mergeRight(colorsTabs, newColorsTabs)})}
                            {...colorsTabs}
                        >
                            <Tab
                                label='Page Colors'
                            >
                                <div>
                                    <div className='pageColorsTab'>
                                        <div className="Settings rounded-border">
                                            <div className="Settings--title--container">
                                                <div className="Settings--title--text">
                                                    <span className="Settings--title">
                                                        {'Quick Presets'}
                                                    </span>
                                                </div>
                                                <div className="ColorWayPicker--container">
                                                    <ColorWayPicker
                                                        value={values(pageColors)}
                                                        options={chain(t=>{
                                                            const orderedColors = props([
                                                                'background_content',
                                                                'background_page',
                                                                'border',
                                                                'text',
                                                                'accent',
                                                                'accent_positive',
                                                                'accent_negative',
                                                                'card_accent'
                                                            ], t);
                                                            const orderedColors_inversed = keySwap(orderedColors.slice(0), 0, 1); // clone to avoid mutation
                                                            const stringifiedColors = JSON.stringify(orderedColors);
                                                            const stringifiedColors_inversed = JSON.stringify(orderedColors_inversed);
                                                            orderedColors.splice(-2,2);
                                                            orderedColors_inversed.splice(-2,2);
                                                            const stringifiedColorsPreview = JSON.stringify(orderedColors);
                                                            const stringifiedColors_inversedPreview = JSON.stringify(orderedColors_inversed);
                                                            return [
                                                                {
                                                                    label: stringifiedColorsPreview,
                                                                    value: stringifiedColors,
                                                                },
                                                                {
                                                                    label: stringifiedColors_inversedPreview,
                                                                    value: stringifiedColors_inversed
                                                                }
                                                            ]
                                                        }, themes)}
                                                        onChange={value => {
                                                            this.updateTheme({
                                                                background_content: value[0],
                                                                background_page: value[1],
                                                                border: value[2],
                                                                text: value[3],
                                                                accent: value[4],
                                                                accent_positive: value[5],
                                                                accent_negative: value[6],
                                                                card_accent: value[7],
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <PageColorSettings
                                                colors={pageColors}
                                                updateColor={this.updateTheme}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                label='Graph Colors'
                            >
                                <div>
                                    <div className="graphColorsTab">
                                        <div className="Settings">
                                            <div className="GraphColorPickers--container">
                                                <div className="ColorWayPicker" >
                                                    <div className="Settings--title">
                                                        {'Colorway'}
                                                    </div>

                                                    <div
                                                        className="Settings--icon"
                                                        id="Settings--icon--reverse"
                                                        onClick={e => {
                                                            updateProps({colorway: reverse(colorway)})
                                                            // simply for animation; doesn't rely on state
                                                            e.currentTarget.classList.toggle('reverse');
                                                        }}
                                                    >
                                                        <img className="editor--icon" src={icon_reverse}/>
                                                    </div>

                                                    <div className="Settings--info--container">
                                                        <div className="Settings--icon">
                                                            <img className="editor--icon" src={icon_info}/>
                                                        </div>

                                                        <div className="Settings--info"> Colorway is intended for categorical data that has distinct groups and a non-meaningful order. </div>
                                                    </div>

                                                    <Tabs
                                                        setProps={newColorscaleTabs => this.setState({colorscaleTabs: extend(colorscaleTabs, newColorscaleTabs)})}
                                                        {...colorscaleTabs}
                                                    >
                                                        <Tab label='With Accent'>
                                                            <ColorscalePicker
                                                                accent={accent}
                                                                background={background_content}
                                                                colorscale={colorway}
                                                                disableSwatchControls={true}
                                                                onChange={value => updateProps({colorway: value})}
                                                                colorscaleType={'accent_categorical'}
                                                            />
                                                        </Tab>
                                                        <Tab label='Predefined'>
                                                            <ColorscalePicker
                                                                accent={accent}
                                                                background={background_content}
                                                                colorscale={colorway}
                                                                disableSwatchControls={true}
                                                                onChange={value => updateProps({colorway: value})}
                                                                colorscaleType={'categorical'}
                                                            />
                                                        </Tab>
                                                        <Tab label='Customize'>
                                                            <ColorscalePicker
                                                                accent={accent}
                                                                colorscale={colorway}
                                                                popoutWindow={this.props.popoutWindow}
                                                                onChange={value => updateProps({colorway: value})}
                                                                colorscaleType={'custom'}
                                                            />
                                                        </Tab>
                                                    </Tabs>
                                                </div>

                                                <div className="ColorScalePicker" >
                                                    <div className="Settings--title">
                                                            {'Colorscale'}
                                                    </div>

                                                    <div
                                                        className="Settings--icon"
                                                        id="Settings--icon--reverse"
                                                        onClick={e => {
                                                            updateProps({colorscale: reverse(colorscale)})
                                                            // simply for animation; doesn't rely on state
                                                            e.currentTarget.classList.toggle('reverse');
                                                        }}
                                                    >
                                                        <img className="editor--icon" src={icon_reverse}/>
                                                    </div>

                                                    <div className="Settings--info--container">
                                                        <div className="Settings--icon">
                                                            <img className="editor--icon" src={icon_info}/>
                                                        </div>

                                                        <div className="Settings--info"> Colorscale is intended for sequential data that smoothly changes value and has meaningful order.</div>
                                                    </div>

                                                    <div>
                                                        <ColorscalePicker
                                                            accent={accent}
                                                            background={background_content}
                                                            colorscale={colorscale}
                                                            disableSwatchControls={true}
                                                            onChange={value => updateProps({colorscale: value})}
                                                            colorscaleType={'sequential'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                label='Bootstrap Colors'
                            >
                                <div className='pageColorsTab'>
                                    <div className="Settings rounded-border">
                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">
                                                    {'Quick Presets'}
                                                </span>
                                            </div>
                                            <div className="ColorWayPicker--container">
                                                <ColorWayPicker
                                                    value={values(dbcColors)}
                                                    options={chain(t=>{
                                                        const orderedColors = props([
                                                            'dbc_primary',
                                                            'dbc_secondary',
                                                            'dbc_info',
                                                            'dbc_gray',
                                                            'dbc_success',
                                                            'dbc_warning',
                                                            'dbc_danger'
                                                        ], t);
                                                        const stringifiedColors = JSON.stringify(orderedColors);
                                                        const stringifiedColorsPreview = JSON.stringify(orderedColors);
                                                        return [
                                                            {
                                                                label: stringifiedColorsPreview,
                                                                value: stringifiedColors,
                                                            }
                                                        ]
                                                    }, themes)}
                                                    onChange={value => {
                                                        this.updateTheme({
                                                            dbc_primary: value[0],
                                                            dbc_secondary: value[1],
                                                            dbc_info: value[2],
                                                            dbc_gray: value[3],
                                                            dbc_success: value[4],
                                                            dbc_warning: value[5],
                                                            dbc_danger: value[6],
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <DBCColorSettings
                                            colors={dbcColors}
                                            updateColor={this.updateTheme}
                                        />
                                    </div>
                                    <div className="Settings rounded-border">
                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">
                                                    {'Dash Bootstrap Component - Table'}
                                                </span>
                                                <span className="Settings--subtitle">
                                                    {'Table component color adjustments.'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='colorsContainer'>
                                            <ColorTab
                                                name={'Table Stripe - Even'}
                                                color={table_striped_even}
                                                updateColor={table_striped_even => updateProps({table_striped_even})}
                                                suggestedColors={[
                                                    'white',
                                                    '#F3F6FA',
                                                    'rgb(60, 60, 60)',
                                                    'rgb(30, 30, 30)',
                                                    'black'
                                                ]}
                                            />
                                            <ColorTab
                                                name={'Table Stripe - Odd'}
                                                color={table_striped_odd}
                                                updateColor={table_striped_odd => updateProps({table_striped_odd})}
                                                suggestedColors={[
                                                    'white',
                                                    '#F3F6FA',
                                                    'rgb(60, 60, 60)',
                                                    'rgb(30, 30, 30)',
                                                    'black'
                                                ]}
                                            />
                                            <ColorTab
                                                name={'Table line border'}
                                                color={table_border}
                                                updateColor={table_border => updateProps({table_border})}
                                                suggestedColors={[
                                                    'white',
                                                    '#F3F6FA',
                                                    'rgb(60, 60, 60)',
                                                    'rgb(30, 30, 30)',
                                                    'black'
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="Settings rounded-border">
                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">
                                                    {'Grid Color'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='colorsContainer'>
                                            <div className="ColorGridPicker">
                                                <div className="Settings--title">
                                                    {''}
                                                </div>
                                            </div>
                                            <ColorTab
                                                name={'Grid Color'}
                                                color={graph_grid_color}
                                                updateColor={graph_grid_color => updateProps({graph_grid_color})}
                                                suggestedColors={[
                                                    'white',
                                                    '#F3F6FA',
                                                    'rgb(60, 60, 60)',
                                                    'rgb(30, 30, 30)',
                                                    'black'
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </Tab>

                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_typography}/>
                            {'Typography'}
                        </div>
                    )}>
                        <div className='typographyTab'>

                            <div className="FontPicker--container">
                                <div className="fontSelect">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">{'Body'}</span>
                                        </div>
                                    </div>
                                    <div className='rounded-border fontCard'>
                                        <div className='fontDefinition'>
                                            <div>
                                                <CSSAttrPicker
                                                    value={font_family}
                                                    options={FONT_LIST.map(font_family => ({value: font_family}))}
                                                    onChange={value => updateProps({font_family: value})}
                                                    optionRenderer={fontOptionRenderer}
                                                    className="FontPicker"
                                                    placeholder="Select font"
                                                    label="Body font family"
                                                />
                                            </div>
                                            <div>
                                                <CSSAttrPicker
                                                    value={keys(FONT_SIZES)[
                                                        indexOf(font_size,
                                                            pluck(
                                                                '>font-breakpoint',
                                                                values(FONT_SIZES)
                                                            )
                                                        )
                                                    ]}
                                                    options={FONT_SIZE_OPTIONS}
                                                    onChange={value => {
                                                        updateProps({
                                                            font_size: FONT_SIZES[value]['>font-breakpoint'],
                                                            font_size_smaller_screen: FONT_SIZES[value]['<font-breakpoint'],
                                                        });
                                                    }}
                                                    className="FontSizePicker"
                                                    placeholder="Select size"
                                                    label="Body font size"
                                                />
                                            </div>
                                            <div>
                                                <div className="FontColorPicker">
                                                    <span><small>Body font color</small></span>
                                                    <SwatchInput
                                                        defaultColor={body_text}
                                                        onChange={(value) => {
                                                            updateProps({
                                                                body_text: value
                                                            });
                                                        }}
                                                        popoutWindow={this.props.popoutWindow}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fontPreview" style={{fontFamily: font_family}}>
                                            <div className="fontPreview--sizes">
                                                <p style={{fontSize: font_size}}>{font_family || "My Body"}</p>
                                            </div>
                                            <div className="fontPreview--characters">
                                                <p>{ALL_CHARACTERS}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="FontPicker--container">
                                <div className="fontSelect">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">{'Headings'}</span>
                                            <span className="Settings--subtitle">{'H1-H6'}</span>
                                        </div>
                                    </div>
                                    <div className='rounded-border fontCard'>
                                        <div className='fontDefinition'>
                                            <div>
                                                <CSSAttrPicker
                                                    value={font_family_headings}
                                                    options={FONT_LIST.map(font_family => ({value: font_family}))}
                                                    onChange={value => updateProps({font_family_headings: value})}
                                                    optionRenderer={fontOptionRenderer}
                                                    className="FontPicker"
                                                    placeholder={"Select font"}
                                                    label="Heading font family"
                                                />
                                            </div>
                                            <div>
                                                <div className="FontColorPicker">
                                                    <span><small>Heading font color</small></span>
                                                    <SwatchInput
                                                        defaultColor={heading_text}
                                                        onChange={(value) => {
                                                            updateProps({
                                                                heading_text: value
                                                            });
                                                        }}
                                                        popoutWindow={this.props.popoutWindow}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fontPreview" style={{fontFamily: font_family_headings}}>
                                            <div className="fontPreview--sizes">
                                                <h1 style={{fontSize: font_headings_size}}>{font_family_headings || "My Subtitle"}</h1>
                                            </div>
                                            <div className="fontPreview--characters">
                                                <h2>{ALL_CHARACTERS}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="FontPicker--container">
                                <div className="fontSelect">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">
                                                {'Header'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='rounded-border fontCard'>
                                        <div className='fontDefinition'>
                                            <div>
                                                <CSSAttrPicker
                                                    value={font_family_header}
                                                    options={FONT_LIST.map(font_family => ({value: font_family}))}
                                                    onChange={value => updateProps({font_family_header: value})}
                                                    optionRenderer={fontOptionRenderer}
                                                    className="FontPicker"
                                                    placeholder={"Select font"}
                                                    label="Header font family"
                                                />
                                            </div>
                                            <div>
                                                <CSSAttrPicker
                                                    value={keys(FONT_SIZES)[
                                                        indexOf(font_size_header,
                                                            pluck(
                                                                'header',
                                                                values(FONT_SIZES)
                                                            )
                                                        )
                                                    ]}
                                                    options={FONT_SIZE_OPTIONS}
                                                    onChange={value => {
                                                        updateProps({
                                                            font_size_header: FONT_SIZES[value].header
                                                        });
                                                    }}
                                                    className="FontSizePicker"
                                                    placeholder={"Select size"}
                                                    label="Header font size"
                                                />
                                            </div>
                                            <div>
                                                <div className="FontColorPicker">
                                                    <span><small>Header font color</small></span>
                                                    <SwatchInput
                                                        defaultColor={header_text}
                                                        onChange={(value) => {
                                                            updateProps({
                                                                header_text: value
                                                            });
                                                        }}
                                                        popoutWindow={this.props.popoutWindow}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fontPreview" style={{fontFamily: font_family_header}}>
                                            <div className="fontPreview--sizes">
                                                <h1 style={{fontSize: font_size_header}}>{font_family_header || "My Title"}</h1>
                                            </div>
                                            <div className="fontPreview--characters">
                                                <h1>{ALL_CHARACTERS}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="FontPicker--container">
                                <div className="fontSelect">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">{'Title'}</span>
                                        </div>
                                    </div>
                                    <div className='rounded-border fontCard'>
                                        <div className='fontDefinition'>
                                            <div>
                                                <CSSAttrPicker
                                                    value={title_capitalization}
                                                    options={VALID_TEXT_TRANSFORMS.map(value => ({
                                                        value: value,
                                                        label: capitalizeString(value),
                                                        title: "Capitalization for the title component"
                                                    }))}
                                                    onChange={value => updateProps({title_capitalization: value})}
                                                    optionRenderer={titleCapitalizationRenderer}
                                                    className="TitleCapitalizationPicker"
                                                    placeholder={"Select capitalization"}
                                                    label={"Title capitalization"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="FontPicker--container">
                                <div className="fontSelect">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">{'Button'}</span>
                                        </div>
                                    </div>
                                    <div className='rounded-border fontCard'>
                                        <div className='fontDefinition'>
                                            <div>
                                                <CSSAttrPicker
                                                    value={button_capitalization}
                                                    options={VALID_TEXT_TRANSFORMS.map(value => ({
                                                        value: value,
                                                        label: capitalizeString(value),
                                                        title: "html.Button() text capitalization"
                                                    }))}
                                                    onChange={value => updateProps({button_capitalization: value})}
                                                    optionRenderer={titleCapitalizationRenderer}
                                                    className="ButtonCapitalizationPicker"
                                                    placeholder={"Select capitalization"}
                                                    label={"Button text capitalization"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Tab>

                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_containers}/>
                            {'Containers'}
                        </div>
                    )}>
                        <div className="Settings Settings-containers">

                            <Tabs
                                className='Advanced-tabs'
                                setProps={newContainersTabs => this.setState({containersTabs: mergeRight(containersTabs, newContainersTabs)})}
                                {...containersTabs}
                            >
                                <Tab label={(
                                    <div className="editorTabIcon">
                                        {'Presets'}
                                    </div>
                                )}>

                                    <div className="presetsContainer rounded-border">
                                        <div className="PresetTab"
                                            onClick={
                                                partial(this.updateTheme, [CONTAINER_THEMES.box_shadow])
                                            }
                                        >
                                            <h1>Box Shadow</h1>
                                            <p>
                                            A subtle shadow beneath the cards and the header conveys physical space as
                                            if the cards were sitting on top of a sheet of paper.
                                            The paper concept was popularized by Material Design in 2014.
                                            </p>
                                        </div>

                                        <div className="PresetTab"
                                            onClick={
                                                partial(this.updateTheme, [CONTAINER_THEMES.minimal_and_flat])
                                            }
                                        >
                                            <h1>Minimal & Flat</h1>
                                            <p>
                                                Removes the shadow and the border from the cards and the header.
                                                Also makes the cards completely square. In this minimal theme, the
                                                background and content colors differentiate the cards from each other.
                                            </p>
                                        </div>

                                        <div className="PresetTab"
                                            onClick={
                                                partial(this.updateTheme, [CONTAINER_THEMES.tight_and_bordered])
                                            }
                                        >
                                            <h1>Tight & Bordered</h1>
                                            <p>
                                                Providing a thin border is an alternative to the minimal & flat design.
                                                The thin border differentiates the content rather than the contrast
                                                between the card background and the page background.
                                                With a thin border, you can pack more content onto your page
                                                by reducing the margins between components to be zero.
                                            </p>
                                        </div>

                                        <div className="PresetTab"
                                            onClick={
                                                partial(this.updateTheme, [CONTAINER_THEMES.future_console])
                                            }
                                        >
                                            <h1>Future Console</h1>
                                            <p>
                                                This is a fun one. A thicker, dashed border beneath the card headers
                                                provides a retro futuristic look and feel. Combine with a dark background
                                                color, white fonts, and a monospace font to take it even further.
                                            </p>
                                        </div>
                                    </div>


                                </Tab>
                                <ContainerTab
                                    label='Cards'
                                    component_name='card'
                                    key={'card'}
                                    updateProps={updateProps}
                                    popoutWindow={this.props.popoutWindow}
                                    {...this.props}
                                />

                                <ContainerTab
                                    label='Card Headers'
                                    component_name='card_header'
                                    key={'card_header'}
                                    updateProps={updateProps}
                                    popoutWindow={this.props.popoutWindow}
                                    {...this.props}
                                />

                                <ContainerTab
                                    label='Header'
                                    component_name='header'
                                    key={'header'}
                                    updateProps={updateProps}
                                    popoutWindow={this.props.popoutWindow}
                                    {...this.props}
                                />
                            </Tabs>
                        </div>
                    </Tab>

                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_controls}/>
                            {'Controls'}
                        </div>
                    )}>
                        <div className="Settings Settings-containers">

                            <Tabs
                                className='Advanced-tabs'
                                setProps={newControlsTabs => this.setState({controlsTabs: mergeRight(controlsTabs, newControlsTabs)})}
                                {...controlsTabs}
                            >
                                <Tab label='Core Components'>
                                    <div>
                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">{'Borders'}</span>
                                            </div>
                                        </div>

                                        <div className='rounded-border'>
                                            <div className="presetsContainer border-presets">
                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                control_border: {
                                                                    width: CONTROL_BORDERS.width.minimal
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Minimal (Underlined)</h1>
                                                    <p>
                                                        Input control borders consist
                                                        solely of an underline
                                                        (<code>border-bottom</code>).
                                                        DDK default.
                                                    </p>
                                                </div>

                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                control_border: {
                                                                    width: CONTROL_BORDERS.width.bordered
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Four-Sided</h1>
                                                    <p>
                                                        Adds a 1-pixel border on all
                                                        four sides of input controls.
                                                    </p>
                                                </div>

                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                control_border: {
                                                                    width: CONTROL_BORDERS.width.future_console
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Future Console</h1>
                                                    <p>
                                                        Adds a 1-pixel border on all
                                                        four sides of input controls,
                                                        and a 4-pixel border on the bottom.
                                                    </p>
                                                </div>

                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [
                                                                {
                                                                    control_border: {
                                                                        width: CONTROL_BORDERS.width.none
                                                                    },
                                                                    get control_background_color() {
                                                                        // ensure contrast on no-border controls
                                                                        return this.background_page
                                                                    },
                                                                    set control_background_color(value) {
                                                                        setThemeVar('control_background_color', value, this);
                                                                    }
                                                                }
                                                            ]
                                                        )
                                                    }
                                                >
                                                    <h1>None</h1>
                                                    <p>
                                                        Removes borders from input controls
                                                        on all four sides, and sets
                                                        'control_background_color'
                                                        to 'page_background'
                                                        for contrast.
                                                    </p>
                                                </div>
                                            </div>

                                            {parseInt(control_border.width, 10) >= 1 &&
                                                <div className="controlPresetsContainer">
                                                    <small>Additional control for border-radius, only enabled on Four-Sided & Future Console</small>
                                                    <div className="presetsContainer">
                                                        <div className="PresetTab"
                                                            onClick={
                                                                partial(
                                                                    this.updateTheme,
                                                                    [{
                                                                        control_border: {
                                                                            radius: CONTROL_BORDERS.radius.sharp
                                                                        }
                                                                    }]
                                                                )
                                                        }
                                                        >
                                                            <h1>Sharp</h1>
                                                            <p>
                                                                90-degree border corners, with no rounding (<code>border-radius</code>).
                                                            </p>
                                                        </div>

                                                        <div className="PresetTab"
                                                            onClick={
                                                                partial(
                                                                    this.updateTheme,
                                                                    [{
                                                                        control_border: {
                                                                            radius: CONTROL_BORDERS.radius.rounded
                                                                        }
                                                                    }]
                                                                )
                                                            }
                                                        >
                                                            <h1>Rounded</h1>
                                                            <p>
                                                                Adds a small amount (<code>5px</code>) of rounding to
                                                                input control corners with `border-radius`.
                                                            </p>
                                                        </div>


                                                        <div className="PresetTab">
                                                            <h1>Custom</h1>
                                                            <p>
                                                                Enter your own custom input rounding (<code>border-radius</code>) value in `px`
                                                            </p>
                                                            <CSSAttrInput
                                                                value={parseInt(control_border.radius, 10)}
                                                                unit='px'
                                                                onChange={(value) => {
                                                                    updateProps({
                                                                        control_border: extend(
                                                                            control_border,
                                                                            {radius: value}
                                                                        )
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">{'Colors'}</span>
                                            </div>
                                        </div>

                                        <div className="controlPresetsContainer rounded-border">
                                            <div className="presetsContainer inputs-background-colors">
                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                get control_background_color() {
                                                                    return this.background_content;
                                                                },
                                                                set control_background_color(value) {
                                                                    setThemeVar('control_background_color', value, this);
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Content Background</h1>
                                                    <p>
                                                        Set the control background color to the default background
                                                        color of DDK containers (<code>ddk.Card</code>, <code>ddk.Header</code>, <code>ddk.Menu</code> etc.). Within containers, control backgrounds will effectively be transparent. DDK default.
                                                    </p>
                                                </div>

                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                get control_background_color() {
                                                                    return this.background_page;
                                                                },
                                                                set control_background_color(value) {
                                                                    setThemeVar('control_background_color', value, this);
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Page Background</h1>
                                                    <p>
                                                        Set the control background color to the default background
                                                        color of the <code>ddk.App</code> page background.
                                                        Within containers, control backgrounds will appear contrasted
                                                        with their container background by the <code>theme.background_page</code>,
                                                        which, in presets and by default, is a lighter or darker variation that
                                                        page color.
                                                    </p>
                                                </div>

                                                <div className="PresetTab">
                                                    <h1>Custom</h1>
                                                    <p>
                                                        Enter your own custom input control colors
                                                    </p>
                                                    <div className="FontColorPicker">
                                                        <span><small>Control background color</small></span>
                                                        <SwatchInput
                                                            defaultColor={control_background_color}
                                                            onChange={(value) => {
                                                                updateProps({
                                                                    get control_background_color() {
                                                                        return value;
                                                                    },
                                                                    set control_background_color(value) {
                                                                        setThemeVar('control_background_color', value, this);
                                                                    }
                                                                })
                                                            }}
                                                            popoutWindow={this.props.popoutWindow}
                                                        />
                                                    </div>
                                                    <div className="FontColorPicker">
                                                        <span><small>Font color</small></span>
                                                        <SwatchInput
                                                            defaultColor={control_text}
                                                            onChange={(value) => {
                                                                updateProps({
                                                                    control_text: value
                                                                });
                                                            }}
                                                            popoutWindow={this.props.popoutWindow}
                                                        />
                                                    </div>
                                                    <div className="FontColorPicker">
                                                        <span><small>Control border color</small></span>
                                                        <SwatchInput
                                                            defaultColor={control_border.color}
                                                            onChange={(value) => {
                                                                updateProps({
                                                                    control_border: mergeRight(
                                                                        control_border,
                                                                        {color: value}
                                                                    )
                                                                });
                                                            }}
                                                            popoutWindow={this.props.popoutWindow}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab label='Buttons'>
                                    <div>
                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">
                                                    {'Style'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="presetsContainer button-presets--style rounded-border">
                                            <div className="PresetTab"
                                                onClick={
                                                    partial(
                                                        this.updateTheme,
                                                        [{
                                                            button_border: {
                                                                width: '1px',
                                                                color: accent
                                                            },
                                                            get button_text() { return this.accent; },
                                                            set button_text(value) {
                                                                setThemeVar('button_text', value, this);
                                                            },
                                                            get button_background_color() { return this.background_content; },
                                                            set button_background_color(value) {
                                                                setThemeVar('button_background_color', value, this);
                                                            }
                                                        }]
                                                    )
                                                }
                                            >
                                                <h1>Inverted</h1>
                                                <p>
                                                    Button background colors are the same
                                                    as content backgrounds
                                                    (<code>background_content</code>),
                                                    and border and font colors take on
                                                    the theme's accent color.
                                                    DDK default.
                                                </p>
                                            </div>
                                            <div className="PresetTab"
                                                onClick={
                                                    partial(
                                                        this.updateTheme,
                                                        [{
                                                            button_border: {
                                                                width: '1px',
                                                                color: accent
                                                            },
                                                            get button_text() { return this.background_content; },
                                                            set button_text(value) {
                                                                setThemeVar('button_text', value, this);
                                                            },
                                                            get button_background_color() { return this.accent; },
                                                            set button_background_color(value) {
                                                                setThemeVar('button_background_color', value, this);
                                                            },
                                                        }]
                                                    )
                                                }
                                            >
                                                <h1>Solid</h1>
                                                <p>
                                                    Sets border background colors
                                                    to the accent color, and font colors
                                                    to the content background
                                                    (<code>background_content</code>)
                                                </p>
                                            </div>
                                            <div className="PresetTab"
                                                onClick={() => {
                                                    this.updateTheme(
                                                        {
                                                            button_border: {
                                                                width: BUTTON_BORDERS.width['3D'],
                                                                color: (button_border.color === button_background_color
                                                                || button_border.color === accent)
                                                                ? tinycolor(button_border.color).isLight()
                                                                    ? tinycolor(button_border.color).darken(15).toString()
                                                                    : tinycolor(button_border.color).lighten(15).toString()
                                                                : button_border.color
                                                            },
                                                            get button_text() { return this.background_content; },
                                                            set button_text(value) {
                                                                setThemeVar('button_text', value, this);
                                                            },
                                                            get button_background_color() {
                                                                return button_background_color !== background_content
                                                                ? button_background_color
                                                                : this.accent;
                                                            },
                                                            set button_background_color(value) {
                                                                setThemeVar('button_background_color', value, this);
                                                            }
                                                        }
                                                    )
                                                }}
                                            >
                                                <h1>3D</h1>
                                                <p>
                                                    Adds a thick (<code>4px</code>) bottom border
                                                    to all buttons, and changes the background/text to
                                                    "solid" if it's "inverted".
                                                </p>
                                            </div>
                                            <div className="PresetTab">
                                                <h1>Custom Colors</h1>
                                                <p>
                                                    Enter your own custom button colors
                                                </p>
                                                <div className="FontColorPicker">
                                                    <span><small>Button background color</small></span>
                                                    <SwatchInput
                                                        defaultColor={button_background_color}
                                                        onChange={(value) => {
                                                            updateProps({
                                                                get button_background_color() {
                                                                    return value;
                                                                },
                                                                set button_background_color(value) {
                                                                    setThemeVar('button_background_color', value, this);
                                                                }
                                                            })
                                                        }}
                                                        popoutWindow={this.props.popoutWindow}
                                                    />
                                                </div>
                                                <div className="FontColorPicker">
                                                    <span><small>Button text color</small></span>
                                                    <SwatchInput
                                                        defaultColor={button_text}
                                                        onChange={(value) => {
                                                            updateProps({
                                                                get button_text() {
                                                                    return value;
                                                                },
                                                                set button_text(value) {
                                                                    setThemeVar('button_text', value, this);
                                                                }
                                                            })
                                                        }}
                                                        popoutWindow={this.props.popoutWindow}
                                                    />
                                                </div>
                                                <div className="FontColorPicker">
                                                    <span><small>Button border color</small></span>
                                                    <SwatchInput
                                                        defaultColor={
                                                            button_border.width === BUTTON_BORDERS.width['3D']
                                                            ? getValueFromCSSText(
                                                                    'borderBottomColor',
                                                                    'borderColor',
                                                                    button_border.color
                                                                )
                                                            : button_border.color
                                                        }
                                                        onChange={(value) => {
                                                            // var bottom_width = getValueFromCSSText(
                                                            //     'borderBottomWidth',
                                                            //     'borderBottom',
                                                            //     button_border.width
                                                            // )
                                                            updateProps({
                                                                button_border: mergeRight(
                                                                    button_border,
                                                                    {
                                                                        color: value
                                                                    }
                                                                )
                                                            });
                                                        }}
                                                        popoutWindow={this.props.popoutWindow}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="Settings--title--container">
                                            <div className="Settings--title--text">
                                                <span className="Settings--title">
                                                    {'Corners' }<code>(border-radius)</code>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="controlPresetsContainer rounded-border">
                                            <div className="presetsContainer button-presets--border-radius">
                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                button_border: {
                                                                    radius: BUTTON_BORDERS.radius.sharp
                                                                }
                                                            }]
                                                        )
                                                }
                                                >
                                                    <h1>Sharp</h1>
                                                    <p>
                                                        90-degree border corners, with no rounding (<code>border-radius</code>).
                                                    </p>
                                                </div>

                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                button_border: {
                                                                    radius: BUTTON_BORDERS.radius.rounded
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Rounded</h1>
                                                    <p>
                                                        Adds a small amount (<code>5px</code>) of rounding to
                                                        button corners with `border-radius`.
                                                    </p>
                                                </div>

                                                <div className="PresetTab"
                                                    onClick={
                                                        partial(
                                                            this.updateTheme,
                                                            [{
                                                                button_border: {
                                                                    radius: BUTTON_BORDERS.radius.oval
                                                                }
                                                            }]
                                                        )
                                                    }
                                                >
                                                    <h1>Oval</h1>
                                                    <p>
                                                        Adds a larger amount (<code>30px</code>) of rounding to
                                                        button corners with `border-radius`.
                                                    </p>
                                                </div>


                                                <div className="PresetTab">
                                                    <h1>Custom</h1>
                                                    <p>
                                                        Enter your own custom button rounding (<code>border-radius</code>) value in px
                                                    </p>
                                                    <CSSAttrInput
                                                        value={parseInt(button_border.radius, 10)}
                                                        unit='px'
                                                        onChange={(value) => {
                                                            updateProps({
                                                                button_border: extend(
                                                                    button_border,
                                                                    {radius: value}
                                                                )
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </Tab>

                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_reports}/>
                            {'Reports'}
                        </div>
                    )}>
                        <div className="Settings">

                            <div className="Settings--title--container">
                                <div className="Settings--title--text">
                                    <span className="Settings--title">
                                        {'Report & Page Styles'}
                                    </span>
                                    <span className="Settings--subtitle">
                                        Report default settings apply to the
                                        the <code>Report</code> and <code>Page</code> components
                                        which are used for creating
                                        print-ready reports & documents.
                                    </span>
                                </div>
                            </div>

                            <div className='colorsContainer rounded-border'>
                                <ColorTab
                                    name={'Report Background'}
                                    color={report_background_page}
                                    updateColor={report_background_page => updateProps({report_background_page})}
                                    suggestedColors={[
                                        'white',
                                        '#F3F6FA',
                                        'rgb(60, 60, 60)',
                                        'rgb(30, 30, 30)',
                                        'black'
                                    ]}
                                />
                                <ColorTab
                                    name={'Report Content Background'}
                                    color={report_background_content}
                                    updateColor={report_background_content => updateProps({report_background_content})}
                                    suggestedColors={[
                                        '#FFFFFF',
                                        '#F9F9F9',
                                        '#F8FFFF',
                                        '#333333',
                                        '#10181F',
                                    ]}
                                />
                                <ColorTab
                                    name={'Report Text'}
                                    color={report_text}
                                    updateColor={report_text => updateProps({report_text})}
                                    suggestedColors={[
                                        'black',
                                        'rgb(30, 30, 30)',
                                        'rgb(60, 60, 60)',
                                        '#2a3f5f',
                                        '#506784',
                                        '#F3F6FA',
                                        'white'
                                    ]}
                                />
                                <ColorTab
                                    name={'Report Border'}
                                    color={report_border}
                                    updateColor={report_border => updateProps({report_border})}
                                    suggestedColors={[
                                        'black',
                                        'rgb(30, 30, 30)',
                                        'rgb(60, 60, 60)',
                                        '#2a3f5f',
                                        '#506784',
                                        '#F3F6FA',
                                        'white'
                                    ]}
                                />
                                <ColorTab
                                    name={'Report Outer Background'}
                                    color={report_background}
                                    updateColor={report_background => updateProps({report_background})}
                                    suggestedColors={[
                                        'black',
                                        'rgb(30, 30, 30)',
                                        'rgb(60, 60, 60)',
                                        '#2a3f5f',
                                        '#506784',
                                        '#F3F6FA',
                                        'white'
                                    ]}
                                />
                            </div>

                            <div className="FontPicker--container">
                                <div className="fontSelect">
                                    <div className="Settings--title--container">
                                        <div className="Settings--title--text">
                                            <span className="Settings--title">
                                                {'Report Fonts'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='rounded-border fontCard'>
                                        <div className='fontDefinition'>
                                            <div>
                                                <CSSAttrPicker
                                                    value={report_font_family}
                                                    options={FONT_LIST.map(value => ({value}))}
                                                    onChange={report_font_family => updateProps({report_font_family})}
                                                    optionRenderer={fontOptionRenderer}
                                                    className="FontPicker"
                                                    placeholder={"Select font"}
                                                />
                                            </div>
                                            <div>
                                                <Select
                                                    style={{'marginLeft': '10px'}}
                                                    options={range(6, 25).map(i => ({
                                                        'label': `${i}px`, 'value': `${i}px`
                                                    }))}
                                                    onChange={option => {
                                                        updateProps({report_font_size: option.value})
                                                    }}
                                                    value={report_font_size}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="fontPreview"
                                            style={{
                                                fontFamily: report_font_family,
                                                fontSize: report_font_size
                                            }}
                                        >
                                            <div>
                                                <div className="fontPreview--sizes ddk-page__typography">
                                                    <h1>{`H1 - ${report_font_family || "Header"}`}</h1>
                                                </div>
                                                <div className="fontPreview--characters">
                                                    <h1>{ALL_CHARACTERS}</h1>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fontPreview--sizes ddk-page__typography">
                                                    <h1>{`H2 - ${report_font_family || "Header"}`}</h1>
                                                </div>
                                                <div className="fontPreview--characters">
                                                    <h2>{ALL_CHARACTERS}</h2>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fontPreview--sizes">
                                                    <p>{`Body - ${report_font_family || "My Body"}`}</p>
                                                </div>
                                                <div className="fontPreview--characters">
                                                    <p>{ALL_CHARACTERS}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </Tab>

                    <Tab label={(
                        <div className="editorTabIcon">
                            <img className="editor--icon" src={icon_top_export}/>
                            {'Export'}
                        </div>
                    )}>
                        <div className="Settings--title--container">
                            <div className="Settings--title--text">
                                <span className="Settings--title">{'Save Theme'}</span>
                            </div>
                        </div>
                        <p>In order for your theme to persist, you must save the theme definition in your project. You can either save the theme as a standalone JavaScript (JS) file or you can save it within your application code itself.</p>

                        <div className='code-tab-container'>
                            <div className="Settings Settings-containers">
                                <Tabs
                                    className='ThemeDefinition-tabs'
                                    setProps={newDefinitionTabs => this.setState({definitionTabs: mergeRight(definitionTabs, newDefinitionTabs)})}
                                    {...definitionTabs}
                                >
                                    <DefinitionTab
                                        label='Standalone JS File'
                                        language='javascript'
                                        languageAbbr='Js'
                                        description={
                                            templateLiteralDedent(`
                                                The Javascript object that describes
                                                your theme. Copy this text into
                                                a Javascript file in a directory named '/assets'
                                                in the root directory of your project, or click the
                                                "Download" button and copy the downloaded file into the
                                                same '/assets' directory. The Javascript file can be
                                                named anything as long as it has a ".js" extension.
                                                For example, name the file "theme.js".
                                            `)
                                        }
                                        that={this}
                                        updateProps={updateProps}
                                        popoutWindow={this.props.popoutWindow}
                                        {...this.props}
                                    />
                                    <DefinitionTab
                                        label='Python'
                                        language='python'
                                        languageAbbr='Py'
                                        description={
                                            templateLiteralDedent(`
                                                The Python dict that describes your
                                                theme. Copy this text in your app,
                                                e.g. app.py, and pass the 'theme'
                                                variable as an argument to ddk.App(),
                                                e.g. 'ddk.App(..., theme=theme)'.
                                            `)
                                        }
                                        that={this}
                                        updateProps={updateProps}
                                        popoutWindow={this.props.popoutWindow}
                                        {...this.props}
                                    />
                                    {/* <DefinitionTab
                                        label='R'
                                        language='r'
                                        languageAbbr='R'
                                        description={
                                            templateLiteralDedent(`
                                                The R named list that describes your
                                                theme. Copy this text in your app,
                                                e.g. app.R, or load it into your console
                                                so that it will be accessible in your
                                                global enviroment. Then, pass the 'theme'
                                                variable as an argument to ddkApp(),
                                                e.g. 'ddkApp(..., theme=theme)'.
                                            `)
                                        }
                                        that={this}
                                        updateProps={updateProps}
                                        popoutWindow={this.props.popoutWindow}
                                        {...this.props}
                                    /> */}
                                </Tabs>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
          </div>
        );
    }
}

function appendStyles(targetDoc) {
    const editor_stylesheets = {
        style_editor,
        style_editor_slider,
        style_editor_select,
        style_editor_virtualized_select,
    };
    for (const [id, template_string] of Object.entries(editor_stylesheets)) {
        if (!targetDoc.getElementById(id)) {
            appendStyle(id, template_string(null), targetDoc);
        }
    }
}

/*
 * TODO: Find an alternative to the unstable document.fonts.add()
 * API in preparation for plotly/dash-design-kit#242, once it
 * is completed
 */
function copyFonts(targetDoc) {
    const font_style_id = 'ddk_fonts';
    const fontNode = <style id={font_style_id}>{style_fonts}</style>
    if (targetDoc && !targetDoc.getElementById(font_style_id)) {
        ReactDOM.render(fontNode, targetDoc.head.firstElementChild)
    }
}

function EditorWithPopout(props) {
    return (
        <div className="Editor">
            <Popout
                title="Dash Theme Editor"
                style={{ 'overflow' : 'hidden', 'backgroundColor' : '#fff' }}
                options={{
                    width: 1400,
                    height: 650,
                    toolbar: 0,
                    location: 0,
                    titlebar: 0,
                    left: 400
                }}
            >
                {
                    popoutWindow => {
                        return (<Editor popoutWindow={popoutWindow} {...props}/>)
                    }
                }
            </Popout>
        </div>
    );
}

export default Editor;
export {EditorWithPopout};
