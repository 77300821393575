/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { History } from '@plotly/dash-component-plugins';

import layoutPolyfill from './../polyfill/layout';
import closestPolyfill from './../polyfill/closest';
import hrefEqual from '../util/hrefEqual.js';

/**
 * A leveled menu, meant to be included in a `ddk.Header` or `ddk.Sidebar`.
 */
export default class CollapsibleMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.default_open || false,
            childSelected: false,
            url: window.location.pathname
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.isOverflow = this.isOverflow.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onLocationChanged = this.onLocationChanged.bind(this);

        closestPolyfill();
    }

    isOverflow(el1, el2) {
        return !this.state.open && el2.getBoundingClientRect().width > el1.getBoundingClientRect().width;
    }

    handleClickOutside(event) {
        const node = this.node;
        if (
             // check the element clicked != the submenu that initiated the event
             !node || !node.contains(event.target)
             // check the element clicked == a different submenu
             && event.target.parentElement.classList.contains('menu-item')
             /* check the submenu does not contain a link to the current page
                (i.e. the same submenu, but in a ddk.Header */
             && !event.target.closest("[data-open='true']")
           ) {
            this.setState({
                open: false
            });
        }
    }

    onLocationChanged() {
        this.forceUpdate();
    }

    componentDidMount() {
        const node = this.node;
        const container = node.closest('#menu').parentElement.className;
        if (container === 'sidebar--content' && this.props.self_collapsing) {
            document.addEventListener("click", this.handleClickOutside, true);
        }
        if (container === 'HEADER') {
            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions);
        }
        // open the submenu that contains the page link on page load
        React.Children.map(this.props.children, (child) => {
            if (layoutPolyfill.getProps(child).href === this.state.url) {
                this.setState({open: true});
            }
        });

        // For compatibility with Dash <= 1.8.0
        window.addEventListener("onpushstate", this.onLocationChanged);
        // For Dash > 1.8.0
        this._clearOnLocationChanged = History.onChange(this.onLocationChanged);
    }

    componentDidUpdate(prevProps, prevState) {
        React.Children.map(this.props.children, (child) => {
            if (hrefEqual(layoutPolyfill.getProps(child).href, window.location.pathname)) {
                if (this.state.open !== prevState.open && window.location.pathname !== prevState.url) {
                    this.setState({open: true, url: window.location.pathname});
                } else if (window.location.pathname !== prevState.url) {
                    this.setState({url: window.location.pathname});
                }
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        document.removeEventListener("click", this.handleClickOutside, true);

        // For compatibility with Dash <= 1.8.0
        window.removeEventListener("onpushstate", this.onLocationChanged);
        // For Dash > 1.8.0
        this._clearOnLocationChanged();
    }

    updateDimensions() {
        this.setState({ collapsed : this.isOverflow(this.menu, this.menuItemsContainer) });
    }

    toggleCollapse(e) {
        e.preventDefault();
        this.setState({ open: !this.state.open });
    }

    render() {
        const { children, id, title } = this.props;
        var childSelected = false;

        const menuItems = React.Children.map(children, (child) => {
            var itemSelected = false;
            if (hrefEqual(layoutPolyfill.getProps(child).href, window.location.pathname)) {
                childSelected = true;
                itemSelected = true;
            }
            return (
                <li
                    className={
                        'menu-item' +
                        (itemSelected ?
                        ' highlighted-item' :
                        '')
                    }
                >
                    {child}
                </li>
            )
        });

        return (
            <li className="menu--sub"
                ref={node => {this.node = node}}
                data-open={this.state.open}
                id={id}
            >
                <li
                    onClick={(e) => this.toggleCollapse(e)}
                    className={
                        'menu-item' +
                        (childSelected ?
                        ' highlighted-item' :
                        '')
                    }
                >
                    <a href="">{title}
                        <div className="caret"/>
                    </a>
                </li>
                <nav id="menu" ref={el => (this.menu = el)} >
                    <ul id="menu-closed">
                        <div id="menu-items-container" ref={el => (this.menuItemsContainer = el)}>
                            {menuItems}
                        </div>
                    </ul>
                </nav>
            </li>
        )
    }
}

CollapsibleMenu.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The top-level title (label) of the Collapsible Menu
     */
    title: PropTypes.node,

    /**
     * The list of components that are children of the CollapsibleMenu container.
     * Children of CollapsibleMenu should be of type (`dcc.Link`, `html.A`).
     * Nested `CollapsibleMenu` children are not supported at the moment.
     */
    children: PropTypes.node,

    /**
     * Determines whether the submenu should be open on load
     */
    default_open: PropTypes.bool,

    /**
     * Determines whether the submenu collapses when another submenu is clicked
     */
    self_collapsing: PropTypes.bool,

    /**
     * Dash-assigned callback that gets fired when the value changes.
     */
    setProps: PropTypes.any,
};
