const Serious_Light = {
    accent: "#B42519",
    accent_positive: "#70B562",
    accent_negative: "#C63E32",
    background_content: "#F0F0F0",
    background_page: "#FFFFFF",
    body_text: "#333333",
    border: "#D6D6D6",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: 0,
    },
    button_border: {
        width: "1px",
        color: "#B42519",
        radius: "0px"
    },
    button_capitalization: "none",
    button_text: "#FFFFFF",
    button_background_color: "#B42519",
    control_border: {
        width: "1px 1px 1px 1px",
        color: "#BFC0C2",
        radius: "0px"
    },
    control_background_color: "#F8FAFC",
    control_text: "#131925",
    card_margin: "20px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#F0F0F0",
        radius: "0px",
    },
    card_background_color: "#F0F0F0",
    card_box_shadow: "0px 0px 5px rgba(0,0,0,0)",
    card_outline: {
        width: "0px",
        style: "solid",
        color: "#F0F0F0"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "12px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#62A6FB",
        radius: "0px",
    },
    card_header_background_color: "#F0F0F0",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#4758C2",
        "#E26C3E",
        "#B42519",
        "#5FA7C6",
        "#EFAB42",
        "#70B562",
        "#884371",
        "#9C6316",
        "#7A76FF",
        "#158885",
        "#ED7493",
    ],
    colorscale: [
        "#f2b9a3",
        "#edaa91",
        "#e79b81",
        "#e08c70",
        "#da7d60",
        "#d36d51",
        "#cc5e42",
        "#c44d34",
        "#bc3b26",
        "#b42519",
    ],
    dbc_primary: "#B42519",
    dbc_secondary: "#5FA7C6",
    dbc_info: "#4758C2",
    dbc_gray: "#828282",
    dbc_success: "#70B562",
    dbc_warning: "#EBAD2A",
    dbc_danger: "#C63E32",
    font_family: "PT Sans",
    font_family_header: "PT Sans",
    font_family_headings: "PT Sans",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "none",
    header_content_alignment: "spread",
    header_margin: "0px 0px 24px 0px",
    header_padding: "0px 16px 0px 0px",
    header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#828282",
        radius: "0px",
    },
    header_background_color: "#40434A",
    header_box_shadow: "0px 1px 3px rgba(0,0,0,0)",
    header_text: "#FFFFFF",
    heading_text: "#131925",
    text: "#131925",
    report_background: "#f0f0f0",
    report_background_content: "whitesmoke",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "PT Serif",
    report_font_size: "12px",
    report_border: "#D6D6D6",
    graph_grid_color: "#CCCCCC",
    table_striped_even: "#FFFFFF",
    table_striped_odd: "#FFFFFF",
    table_border: "#000000",
    card_accent: "#828282",
};

export default Serious_Light;