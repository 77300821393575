const Neutral_Dark = {
    accent: "#62A6FB",
    accent_positive: "#4EAD41",
    accent_negative: "#D25050",
    background_content: "#000000",
    background_page: "#333333",
    body_text: "#F9F9F9",
    border: "#454545",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: 0,
    },
    button_border: {
        width: "0px",
        color: "#1670AE",
        radius: "4px"
    },
    button_capitalization: "none",
    button_text: "#F9F9F9",
    button_background_color: "#1670AE",
    control_border: {
        width: "1px",
        color: "#666666",
        radius: "4px"
    },
    control_background_color: "#333333",
    control_text: "#FFFFFF",
    card_margin: "12px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#000000",
        radius: "8px",
    },
    card_background_color: "#000000",
    card_box_shadow: "0px 0px 5px rgba(0,0,0,0)",
    card_outline: {
        width: "0px",
        style: "solid",
        color: "#000000"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "0px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#000000",
        radius: "0px",
    },
    card_header_background_color: "#000000",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#62A6FB",
        "#FC8250",
        "#70D362",
        "#FDC646",
        "#158885",
        "#9C6316",
        "#1670AE",
        "#F369CF",
        "#A274FC",
        "#B64226",
    ],
    colorscale: [
        "#deeffa",
        "#c6dceb",
        "#afcadd",
        "#99b7cf",
        "#83a5c2",
        "#6d93b4",
        "#5882a7",
        "#427099",
        "#2b5f8c",
        "#094f7f",
    ],
    dbc_primary: "#62A6FB",
    dbc_secondary: "#1670AE",
    dbc_info: "#62A6FB",
    dbc_gray: "#666666",
    dbc_success: "#4EAD41",
    dbc_warning: "#FDBE2B",
    dbc_danger: "#D25050",
    font_family: "Noto Sans",
    font_family_header: "Noto Sans",
    font_family_headings: "Noto Sans",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "uppercase",
    header_content_alignment: "spread",
    header_margin: "0px",
    header_padding: "0px",
    header_border: {
        width: "0px 0px 0px",
        style: "solid",
        color: "#000000",
        radius: "0px",
    },
    header_background_color: "#000000",
    header_box_shadow: "0px 1px 3px rgba(0,0,0,0)",
    header_text: "#FFFFFF",
    heading_text: "#F9F9F9",
    text: "#F9F9F9",
    report_background: "#F1F2F4",
    report_background_content: "#FFFFFF",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "Noto Serif",
    report_font_size: "12px",
    report_border: "#bbbbbb",
    graph_grid_color: "#4D4D4D",
    table_striped_even: "#000000",
    table_striped_odd: "#000000",
    table_border: "#333333",
    card_accent: "#62A6FB",
};

export default Neutral_Dark;