import React, {Component} from 'react';
import Colorscale from './Colorscale.js';
import DraggableColorscale from './DraggableColorscale';
import chroma from 'chroma-js';
import * as R from 'ramda'
import tinycolor from 'tinycolor2';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'react-select/dist/react-select.css';
import './rc-slider-for-colorscales.css';

import {
  COLORSCALE_TYPES,
  COLORSCALE_DESCRIPTIONS,
  BREWER,
  CMOCEAN,
  CUBEHELIX,
  SCALES_WITHOUT_LOG,
  DEFAULT_SCALE,
  DEFAULT_BREAKPOINTS,
  DEFAULT_START,
  DEFAULT_LOG_BREAKPOINTS,
  DEFAULT_ROTATIONS,
  DEFAULT_GAMMA,
  DEFAULT_LIGHTNESS,
  DEFAULT_NCOLORS,
  DEFAULT_NPREVIEWCOLORS,
  BUILTINS,
} from './constants.js';

import './ColorscalePicker.css';

const Handle = Slider.Handle;

export function getColorscale(
  colorscale,
  nSwatches,
  logBreakpoints,
  log,
  colorscaleType
) {
  /*
   * getColorscale() takes a scale, modifies it based on the input
   * parameters, and returns a new scale
   */
  // helper function repeats a categorical colorscale array N times
  let repeatArray = (array, n) => {
    let arrays = Array.apply(null, new Array(n));
    arrays = arrays.map(function() {
      return array;
    });
    return [].concat.apply([], arrays);
  };

  let cs = chroma.scale(colorscale).mode('lch');

  if (log) {
    const logData = Array(nSwatches)
      .fill()
      .map((x, i) => i + 1);
    cs = cs.classes(chroma.limits(logData, 'l', logBreakpoints));
  }

  let discreteScale = cs.colors(nSwatches);

  // repeat linear categorical ("qualitative") colorscales instead of repeating them
  if (!log && colorscaleType === 'categorical') {
    discreteScale = repeatArray(colorscale, nSwatches).slice(0, nSwatches);
  }

  return discreteScale;
}

export default class ColorscalePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accent: this.props.accent,
      colorscale: this.props.colorscale || DEFAULT_SCALE,
      nSwatches: (this.props.colorscale || DEFAULT_SCALE).length,
      previousColorscale: this.props.colorscale || DEFAULT_SCALE,
      colorscaleType:
        this.props.colorscaleType || this.props.initialColorscaleType,
      log: false,
      logBreakpoints: DEFAULT_LOG_BREAKPOINTS,
      customBreakpoints: DEFAULT_BREAKPOINTS,
      previousCustomBreakpoints: null,
      cubehelix: {
        start: DEFAULT_START,
        rotations: DEFAULT_ROTATIONS,
      },
    };

    this.onClick = this.onClick.bind(this);
    this.setColorscaleType = this.setColorscaleType.bind(this);
    this.updateCubehelixStart = this.updateCubehelixStart.bind(this);
    this.updateCubehelixRotations = this.updateCubehelixRotations.bind(this);
    this.updateCubehelix = this.updateCubehelix.bind(this);
    this.updateSwatchNumber = this.updateSwatchNumber.bind(this);
    this.toggleLog = this.toggleLog.bind(this);
    this.handle = this.handle.bind(this);
  }

  componentDidMount() {
    this.setState({colorscaleOnMount: this.props.colorscale});
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.state.accent !== newProps.accent) {
      this.setState({accent: newProps.accent});
    }
    if (this.state.colorscale !== newProps.colorscale) {
        this.setState({colorscale: newProps.colorscale});
    }
  }

  handle(props) {
    const {value, dragging, index, ...restProps} = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  toggleLog() {
    const cs = getColorscale(
      this.state.previousColorscale,
      this.state.nSwatches,
      this.state.logBreakpoints,
      !this.state.log,
      this.state.colorscaleType
    );

    this.setState({log: !this.state.log, colorscale: cs});

    this.props.onChange(cs);
  };

  onClick(newColorscale, start, rot) {
    const cs = getColorscale(
      newColorscale,
      newColorscale.length,
      this.state.logBreakpoints,
      this.state.log,
      this.state.colorscaleType
    );

    let previousColorscale = newColorscale;

    if (!start && !rot) {
      this.setState({
        previousColorscale: previousColorscale,
        colorscale: cs,
        nSwatches: newColorscale.length,
        previousCustomBreakpoints:
          this.state.colorscaleType === 'custom'
            ? this.state.customBreakpoints
            : null,
      });
    } else {
      this.setState({
        previousColorscale: previousColorscale,
        colorscale: cs,
        nSwatches: newColorscale.length,
        previousCustomBreakpoints: null,
        cubehelix: {
          start: start,
          rotations: rot,
        },
      });
    }
    this.props.onChange(cs, this.state.colorscaleType);
  };

  updateSwatchNumber(ns) {
    const cs = getColorscale(
      this.state.previousColorscale,
      ns,
      this.state.logBreakpoints,
      this.state.log,
      this.state.colorscaleType
    );
    this.setState({
      nSwatches: ns,
      colorscale: cs,
      customBreakpoints: DEFAULT_BREAKPOINTS,
    });
    this.props.onChange(cs);
  };

  updateBreakpoints(e) {
    const bp = e.currentTarget.valueAsNumber;

    const cs = getColorscale(
      this.state.previousColorscale,
      this.state.nSwatches,
      bp,
      this.state.log,
      this.state.colorscaleType
    );

    this.setState({
      logBreakpoints: bp,
      colorscale: cs,
    });

    this.props.onChange(cs);
  };

  updateBreakpointArray(e) {
    const bpArr = e.currentTarget.value
      .replace(/,\s*$/, '')
      .split(',')
      .map(Number);
    this.setState({
      customBreakpoints: bpArr,
    });
  };

  updateCubehelixStart(start) {
    const rot = this.state.cubehelix.rotations;
    this.updateCubehelix(start, rot);
  };

  updateCubehelixRotations(rot) {
    const start = this.state.cubehelix.start;
    this.updateCubehelix(start, rot);
  };

  updateCubehelixStartState(start) {
    const ch = this.state.cubehelix;
    ch.start = start;
    this.setState({cubehelix: ch});
  };

  updateCubehelixRotState(rot) {
    const ch = this.state.cubehelix;
    ch.rotations = rot;
    this.setState({cubehelix: ch});
  };

  updateCubehelix(start, rot) {
    const newColorscale = chroma
      .cubehelix()
      .start(start)
      .rotations(this.state.cubehelix.rotations)
      .gamma(DEFAULT_GAMMA)
      .lightness(DEFAULT_LIGHTNESS)
      .scale()
      .correctLightness()
      .colors(DEFAULT_NCOLORS);

    this.onClick(newColorscale, start, rot);
  };

  setColorscaleType(colorscale) {
    const value = colorscale.value;
    if (value !== this.state.colorscaleType) {
      let isLogColorscale = this.state.log;

      if (SCALES_WITHOUT_LOG.indexOf(value) >= 0) {
        isLogColorscale = false;
      }

      this.setState({
        colorscaleType: value,
        log: isLogColorscale,
      });
    }
  }

  renderSwatchControls() {
    let swatchLabel = null;
    let swatchSlider = null;
    let minSwatches = 1;
    let maxSwatches = 20;

    if (!this.props.fixSwatches) {
      swatchLabel = (
        <div className="noWrap inlineBlock">
          <span className="colorscaleLabel">Swatches: </span>
          <span className="colorscaleLabel">{this.state.nSwatches}</span>
        </div>
      );
      swatchSlider = (
        <Slider
          min={minSwatches}
          max={maxSwatches}
          value={this.state.nSwatches}
          defaultValue={this.state.nSwatches}
          onChange={this.updateSwatchNumber}
        />
      );
    }

    return (
        <div>
            <div>{swatchLabel}</div>
            <div className="swatchController">
                <div className="swatchSlider">
                    {swatchSlider}
                </div>
                <div className="swatchButtons">
                    <button
                        value={this.state.nSwatches}
                        onClick={this.updateSwatchNumber.bind(this, Math.max(this.state.nSwatches-1, minSwatches))}
                    >-</button>
                    <button
                        onClick={this.updateSwatchNumber.bind(this, Math.min(this.state.nSwatches+1, maxSwatches))}
                    >+</button>
                </div>
            </div>
           <div className="colorscaleLabel colorscaleDragDescription">Drag swatches vertically to rearrange. Click for colorpicker.</div>
        </div>
    );
  }

  render() {
    const colorscaleOptions = COLORSCALE_TYPES.map(c => ({
      label: c + ' scales',
      value: c,
    }));

    const colorscalePickerContainerClassnames =
      'colorscalePickerContainer' +
      (this.props.className ? ' ' + this.props.className : '');

    return (
      <div
        className={colorscalePickerContainerClassnames}
        style={{width: this.props.width || '100%'}}
      >
        <Colorscale
            className="Colorway"
            colorscale={this.state.colorscale}
            label={'Current'}
        />

        {this.props.disableSwatchControls ? null : this.renderSwatchControls()}

        <ColorscalePaletteSelector
          colorscaleType={
            this.props.colorscaleType || this.state.colorscaleType
          }
          accent={this.state.accent}
          colorscaleOnMount={this.state.colorscaleOnMount}
          onClick={this.onClick}
          previousColorscale={this.state.previousColorscale}
          colorscale={this.state.colorscale}
          customBreakpoints={this.state.customBreakpoints}
          nSwatches={this.state.nSwatches}
          updateSwatchNumber={this.updateSwatchNumber}
          cubehelix={this.state.cubehelix}
          updateCubehelixStartState={this.updateCubehelixStartState}
          updateCubehelixStart={this.updateCubehelixStart}
          handle={this.handle}
          updateCubehelixRotState={this.updateCubehelixRotState}
          updateCubehelixRotations={this.updateCubehelixRotations}
          updateBreakpointArray={this.updateBreakpointArray}
          scaleLength={this.props.scaleLength}
          popoutWindow={this.props.popoutWindow}
        />

      </div>
    );
  }
}

ColorscalePicker.defaultProps = {
  initialColorscaleType: 'sequential',
};

function ColorscalePaletteSelector(props) {

    const {
      accent,
      colorscaleType,
      colorscaleOnMount,
      onClick,
      previousColorscale,
      colorscale,
      customBreakpoints,
      nSwatches,
      updateSwatchNumber,
      cubehelix,
      updateCubehelixStartState,
      updateCubehelixStart,
      handle,
      updateCubehelixRotState,
      updateCubehelixRotations,
      updateBreakpointArray,
      scaleLength,
      popoutWindow
    } = props;

    const brewerFromChroma = (colorscaleType) => R.reduce(
        (acc, key) => { acc[key] = chroma.brewer[key]; return acc;},
        {},
        BREWER[colorscaleType]
    );

    const sequentialAccentOpposite = (chroma(accent).luminance() < 0.5) ?
        // the accent is relatively dark
        chroma(accent).brighten().set('lch.l', '*3'):
        // the accent is relatively light
        chroma(accent).darken().set('lch.l', '/3') ;

    const accentSequential = chroma.bezier([accent, sequentialAccentOpposite]).scale().colors(10)

    const accentSplit = tinycolor(accent).splitcomplement().map((t) => ( t.toHexString() ) )

    const accentAnalogous = tinycolor(accent).analogous().map((t) => ( t.toHexString() ) )

    const accentTriad = tinycolor(accent).triad().map((t) => ( t.toHexString() ) )

    const accentTetrad = tinycolor(accent).tetrad().map((t) => ( t.toHexString() ) )

    const scaleLen = scaleLength ? scaleLength : 10;

    const accentDivergent = (() => {
        const complement = tinycolor(accent).complement().toHexString();
        return chroma.bezier([accent, complement]).scale().colors(10)
    })()

    // chromatic difference between a provided color and the (bound) accent color
    const accentDelta = chroma.deltaE.bind(null, accent);

    // accentDelta = 100 = colors are exact opposite
    const mostSimilarToAccent = (colorway) => R.reduce(R.minBy(accentDelta), 100, colorway);

    const replaceNearestSwatchWithAccent = (colorway, accent) => {
        if (colorway) {
            // we must clone the colorway, otherwise splice() will mutate it
            colorway = R.clone(colorway)
            // swatch most similar to accent color
            const i = R.indexOf(mostSimilarToAccent(colorway), colorway);
            // remove most similar swatch
            colorway.splice(i, 1);
            // prepend accent (we always wish accent to be the first swatch)
            colorway.unshift(accent);
            return colorway;
        }
    }

    // we convert the object with `"key" : [arr]` items to a 2D array to sort, then convert back
    const sortByAccentSimilarity = (colorscales) => R.fromPairs(R.sort((a, b) => {
        return accentDelta(mostSimilarToAccent(a[1])) - accentDelta(mostSimilarToAccent(b[1]));
    }, R.toPairs(colorscales)));

    const categoricalColorways = R.mergeRight(BUILTINS['categorical'], brewerFromChroma('categorical'));

    const sequentialColorscales = R.mergeRight(
        brewerFromChroma('sequential'),
        BUILTINS['sequential']
    );

    return (
      <div className="colorscalePickerBottomContainer">
        <div style={{margin: '0 auto'}}>

          {colorscaleType === 'sequential' && (
            <div>
              <Colorscale
                key="sequential"
                colorscale={accentSequential}
                onClick={onClick}
                label={'Sequential'}
                scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
              />

              <Colorscale
                key="divergent"
                colorscale={accentDivergent}
                onClick={onClick}
                label={'Divergent'}
                scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
              />
            </div>
          )}

          {colorscaleType === 'sequential' &&
            Object.keys(sortByAccentSimilarity(sequentialColorscales)).map((x, i) => (
              <Colorscale
                key={i}
                onClick={onClick}
                colorscale={sequentialColorscales[x]}
                label={x}
                scaleLength={sequentialColorscales[x].length}
              />
            ))}

          {colorscaleType === 'categorical' &&
            Object.keys(sortByAccentSimilarity(categoricalColorways)).map((x, i) => (
              <Colorscale
                key={i}
                onClick={onClick}
                colorscale={categoricalColorways[x]}
                label={x}
                scaleLength={categoricalColorways[x].length}
              />
            ))}

          {colorscaleType === 'cubehelix' &&
            CUBEHELIX.map((x, i) => (
              <Colorscale
                key={i}
                onClick={onClick}
                colorscale={chroma
                  .cubehelix()
                  .start(x.start)
                  .rotations(x.rotations)
                  .gamma(DEFAULT_GAMMA)
                  .lightness(DEFAULT_LIGHTNESS)
                  .scale()
                  .correctLightness()
                  .colors(scaleLength || DEFAULT_NPREVIEWCOLORS)}
                start={x.start}
                rot={x.rotations}
                label={`s${x.start} r${x.rotations}`}
                scaleLength={scaleLength}
              />
            ))}

          {colorscaleType === 'cmocean' &&
            Object.keys(CMOCEAN).map((x, i) => (
              <Colorscale
                key={i}
                onClick={onClick}
                colorscale={CMOCEAN[x]}
                label={x}
                scaleLength={CMOCEAN[x].length}
              />
            ))}

          {colorscaleType === 'accent_categorical' &&
            Object.keys(sortByAccentSimilarity(categoricalColorways)).map((x, i) => (
              <Colorscale
                key={i}
                onClick={onClick}
                colorscale={replaceNearestSwatchWithAccent(categoricalColorways[x], accent)}
                label={x}
                scaleLength={categoricalColorways[x].length}
              />
            ))
          }

          {colorscaleType === 'accent_categorical' && (
            <div>
              <Colorscale
                key="split-complement"
                colorscale={accentSplit}
                onClick={onClick}
                label={'Split Complement'}
                scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
              />

              <Colorscale
                key="analogous"
                colorscale={accentAnalogous}
                onClick={onClick}
                label={'Analogous'}
                scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
              />

              <Colorscale
                key="triadic"
                colorscale={accentTriad}
                onClick={onClick}
                label={'Triadic'}
                scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
              />

              <Colorscale
                key="tetradic"
                colorscale={accentTetrad}
                onClick={onClick}
                label={'Tetradic'}
                scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
              />
            </div>
          )}

          {colorscaleType === 'custom' && (
            <div>
                <DraggableColorscale
                  onClick={onClick}
                  colorscale={colorscale}
                  popoutWindow={popoutWindow}
                  maxWidth={200}
                  label="Preview"
                  scaleLength={scaleLength}
                />

            </div>
          )}

          <Colorscale
            key="reset"
            colorscale={colorscaleOnMount}
            onClick={onClick}
            label={'Previous'}
            scaleLength={scaleLength || DEFAULT_NPREVIEWCOLORS}
          />

          <p className="colorscaleDescription">
            {COLORSCALE_DESCRIPTIONS[colorscaleType]}
          </p>

          {['custom', 'cubehelix'].includes(colorscaleType) ? (
            <div className="colorscaleControlPanel">
              {colorscaleType === 'cubehelix' && (
                <div>
                  <div className="noWrap">
                    <span className="textLabel">Start: </span>
                    <span className="textLabel">{cubehelix.start}</span>
                    <Slider
                      min={0}
                      max={300}
                      step={1}
                      value={cubehelix.start}
                      onChange={updateCubehelixStartState}
                      onAfterChange={updateCubehelixStart}
                      handle={handle}
                    />
                  </div>
                  <div className="noWrap">
                    <span className="textLabel">Rotations: </span>
                    <span className="textLabel">{cubehelix.rotations}</span>
                    <Slider
                      min={-1.5}
                      max={1.5}
                      step={0.1}
                      value={cubehelix.rotations}
                      onChange={updateCubehelixRotState}
                      onAfterChange={updateCubehelixRotations}
                      handle={handle}
                    />
                  </div>
                </div>
              )}
              <div>
                {colorscaleType === 'custom' && (
                  <div className="colorscaleControlsRow">
                    <p className="textLabel zeroSpace">
                      Decimals between 0 and 1, or numbers between MIN and MAX
                      of your data, separated by commas:
                    </p>
                    <input
                      type="text"
                      defaultValue={customBreakpoints.join(', ')}
                      onChange={updateBreakpointArray}
                    />
                    <p className="textLabel spaceTop">
                      {customBreakpoints.length - 1} breakpoints:{' '}
                      {customBreakpoints.join(' | ')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
