import { css, getValueFromCSSText } from '../utils.js';
import chroma from 'chroma-js';

const themedStyles = css`
    .ddk-container.ddk-container .CalendarDay__default {
        background-color: ${props => props.theme.control_background_color}
    }

    .ddk-container.ddk-container .DateRangePicker__picker {
        background-color: ${props => props.theme.control_background_color};
    }

    .ddk-container.ddk-container .CalendarMonth {
        background: ${props => props.theme.control_background_color};
    }

    .ddk-container.ddk-container .DayPickerNavigation_button__default {
        background-color: ${props => props.theme.control_background_color};
    }

    .ddk-container .DateRangePickerInput_arrow svg {
        fill: ${props => props.theme.control_border.color}
    }

    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePickerInput,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePicker_picker,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input__focused,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePicker_picker__fullScreenPortal,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePickerInput,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePicker_picker,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePicker_picker__fullScreenPortal,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .CalendarDay__default,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePicker__picker,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .CalendarMonth,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DayPickerNavigation_button__default {
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? 'inherit'
              : props.theme.control_background_color
        };
    }

    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePickerInput__disabled,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input__focused,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput__disabled,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input__disabled,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePickerInput__disabled {
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? chroma(props.theme.header_background_color).alpha(0.3).css()
              : chroma(props.theme.control_background_color).alpha(0.3).css()
        };
    }

    .ddk-container.ddk-container .layout-header:not([style*="color:"]) .DateInput {
        color: ${props =>
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        };
      }
    .ddk-container.ddk-container .DateInput__display-text--focused {
        color: ${props => props.theme.control_text}
    }
    .ddk-container.ddk-container .DateInput__display-text--has-input {
        color: ${props => props.theme.control_text}
    }
    .ddk-container.ddk-container .DateInput__display-text--disabled {
        color: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
    }
    .ddk-container.ddk-container .SingleDatePickerInput {
        border-width: ${props => props.theme.control_border.width};
        border-color: ${props => props.theme.control_border.color};
        border-radius: ${props => props.theme.control_border.radius};
        width: 100%;
    }
    .ddk-container.ddk-container .DateInput {
        line-height: 12px;
        font-size: inherit;
        font-weight: normal;
        border-radius: ${props => props.theme.control_border.radius};
    }
    .ddk-container.ddk-container .DateRangePickerInput {
        border-radius: ${props => props.theme.control_border.radius};
    }
    .ddk-container.ddk-container .DateRangePicker {
        width: 100%;
        border-radius: ${props => props.theme.control_border.radius};
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: ${props => props.theme.control_border.color};
    }
    .ddk-container.ddk-container .control.label--text--left .control--label + .control--item .DateRangePicker {
        width: ${props =>
            (props.theme.background_content !== props.theme.control_background_color)
            || (parseInt(getValueFromCSSText(
                'borderLeftWidth',
                'borderWidth',
                props.theme.control_border.width
            ), 10) > 0)
              ? 'calc(100% - 8px)'
              : '100%'
        };
    }
    .ddk-container .layout-header .DateRangePicker {
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? 'inherit'
              : props.theme.control_background_color
        };
    }
    .ddk-container .layout-header[style*="background-color:"] .DateRangePicker {
        background-color: ${props =>
            props.theme.control_background_color === props.theme.background_content
              ? 'inherit'
              : props.theme.control_background_color
        };
    }
    .ddk-container.ddk-container .DateInput--disabled {
        background-color: ${props => chroma(props.theme.control_background_color).alpha(0.5).css()};
    }
    .ddk-container.ddk-container .DateInput__display-text--focused {
        color: ${props => props.theme.accent};
        font-weight: bold;
    }
    /* SingleDatePicker */
    .ddk-container.ddk-container .SingleDatePickerInput:hover {
        border-color: ${props => props.theme.accent}
    }
    .ddk-container.ddk-container .SingleDatePickerInput__clear-date:focus,
    .ddk-container.ddk-container .SingleDatePickerInput__clear-date--hover {
        background-color: ${props => props.theme.control_background_color}
    }
    /* Portal */
    .ddk-container.ddk-container .SingleDatePicker__picker--full-screen-portal {
        background-color: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()};
    }
    .ddk-container.ddk-container .DateRangePicker__picker--full-screen-portal {
        background-color: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()};
    }
    .ddk-container.ddk-container .CalendarDay--valid.CalendarDay--selected-span.CalendarDay--hovered {
        border: 1px double ${props => props.theme.accent};
        font-weight: bold;
    }
    .ddk-container.ddk-container .SingleDatePickerInput__clear-date svg {
        fill: ${props => props.theme.control_border.color};
    }
    .ddk-container.ddk-container .DateRangePickerInput__close-icon svg {
        fill: ${props => props.theme.control_border.color};
    }

`
export default themedStyles;
