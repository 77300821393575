const Neon_Light = {
    accent: "#B942FC",
    accent_positive: "#02DC46",
    accent_negative: "#FF493C",
    background_content: "#FFFFFF",
    background_page: "#F6F5F8",
    body_text: "#14175A",
    border: "#EDEDEF",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: 0,
    },
    button_border: {
        width: "0px 4px 2px 0px",
        color: "#14175A",
        radius: "4px"
    },
    button_capitalization: "none",
    button_text: "#14175A",
    button_background_color: "#D8FE56",
    control_border: {
        width: "1px 1px 1px 1px",
        color: "#14175A",
        radius: "4px"
    },
    control_background_color: "#FFFFFF",
    control_text: "#14175A",
    card_margin: "20px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#BFC0C2",
        radius: "4px",
    },
    card_background_color: "#FFFFFF",
    card_box_shadow: "4px 4px 0px rgba(20,23,90,1)",
    card_outline: {
        width: "1px",
        style: "solid",
        color: "#14175A"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "12px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#FFFFFF",
        radius: "0px",
    },
    card_header_background_color: "#FFFFFF",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#FD3EA5",
        "#FF942C",
        "#02DC46",
        "#D4E30F",
        "#00FFED",
        "#955BFF",
        "#FF493C",
        "#3B00E8",
        "#4A95F7",
    ],
    colorscale: [
        "#d4e30f",
        "#edd000",
        "#ffbc00",
        "#ffa616",
        "#ff8f32",
        "#ff784a",
        "#ff6261",
        "#ff4f78",
        "#ff428f",
        "#fd3ea5",
    ],
    dbc_primary: "#B942FC",
    dbc_secondary: "#D8FE56",
    dbc_info: "#00A3FF",
    dbc_gray: "#E4E1EA",
    dbc_success: "#02DC46",
    dbc_warning: "#F6C344",
    dbc_danger: "#FF493C",
    font_family: "Roboto",
    font_family_header: "Roboto",
    font_family_headings: "Roboto",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "none",
    header_content_alignment: "spread",
    header_margin: "0px 0px 32px 0px",
    header_padding: "0px 16px 0px 0px",
    header_border: {
        width: "1px 1px 1px 1px",
        style: "solid",
        color: "#14175A",
        radius: "0px",
    },
    header_background_color: "#14175A",
    header_box_shadow: "4px 4px 0px rgba(0,0,0,0)",
    header_text: "#FFFFFF",
    heading_text: "#14175A",
    text: "#14175A",
    report_background: "#F6F5F8",
    report_background_content: "#FFFFFF",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "Roboto",
    report_font_size: "12px",
    report_border: "white",
    graph_grid_color: "#E6E6E6",
    table_striped_even: "#FFFFFF",
    table_striped_odd: "#FFFFFF",
    table_border: "#F3F4F6",
    card_accent: "#B942FC",
};

export default Neon_Light;