import React from 'react';
import fscreen from 'fscreen';
import PropTypes from 'prop-types';

import is from 'is_js'

import {setExpandedGraphDimensions, saveGraphDimensions } from '../graphUtils';
import {stringifyId} from '../utils';
import IconCardFullscreen from './../icons/icon-card-fullscreen.svg';


const isDisabledInWorkspace = () => {
    return window.location.href.indexOf("Workspaces/view") > -1
    && (window.location !== window.parent.location)
    && !is.chrome()
}

/* fullscreen browser support is disabled */
export const isFullScreenDisabled = () => {
    return is.iphone() ||
           is.ipad('<7') ||
           is.android()
}

/**
 * This component is able to render target elements as full-screen.
 *
 * Functional component: this component does not need to be wrapped in
 * `ddk.App`, and will work as intended themed or not.
 */
export default class FullScreen extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onDOMChanged = this.onDOMChanged.bind(this);
        this.toggleFull = this.toggleFull.bind(this);
        this.toggleFullscreenState = this.toggleFullscreenState.bind(this);
        this.graphResize = this.graphResize.bind(this);

        this.isDisabled = isFullScreenDisabled();
        this.isDisabledInWorkspace = isDisabledInWorkspace();

        this.state = {
            expanded: props.expanded || false,
        };

        this.observer = new MutationObserver(this.onDOMChanged);
        this.observer.observe(document.body, { childList: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.element) {
            return;
        }

        if (nextProps.expanded) {
            /* we can't guarantee the graph container DOM elements are loaded,
               so we cascade their fullscreen dimensions down with CSS */
            this.element.classList.remove('hidden');
        }
    }

    graphResize() {
        if (!this.element) {
            return;
        }

        if (this.graphContainers && this.state.expanded) {
            /* We are exiting a fullscreen state, so we restore the original
               graph styles */
            const origGraphChildrenStyles = this.origGraphChildrenStyles;
            if (!origGraphChildrenStyles) {
                return;
            }
            const graphContainers = Array.from(this.graphContainers);
            graphContainers.map((graphContainer, i) => {
                graphContainer.style.width = graphContainer.style.oldWidth;
                graphContainer.style.height = graphContainer.style.oldHeight;
                const graphChild = graphContainer.getElementsByClassName('svg-container')[0];
                graphChild.style.cssText = origGraphChildrenStyles[i];
            });
            /* Restore the element's hidden state if was initialized hidden
               (by either this component or a Modal) */
            if (this.props.hide_target || this.hidden) {
                this.element.classList.add('hidden');
            }
        }
        else if (fscreen.fullscreenElement === this.element) {
            this.element.classList.remove('hidden');
            setExpandedGraphDimensions(this);
        }
    }

    toggleFullscreenState() {
        if (!this.element) {
            return;
        }

        if (fscreen.fullscreenElement && this.element === fscreen.fullscreenElement) {
            this.expandedElement = fscreen.fullscreenElement;
        }
        this.graphResize();
        if (this.expandedElement !== this.element) {
            return;
        }
        if (this.state.expanded) {
            this.element.classList.remove('fullscreen-element--expanded');
        } else {
            this.element.classList.add('fullscreen-element--expanded');
        }
        if (this.props.setProps) {
            this.props.setProps({ expanded: !this.state.expanded });
        }
        this.setState({ expanded: !this.state.expanded });
    }

    componentDidMount() {
        this.onDOMChanged();

        fscreen.addEventListener('fullscreenchange', this.toggleFullscreenState);
    }

    componentWillUnmount() {
        fscreen.removeEventListener('fullscreenchange', this.toggleFullscreenState);
    }

    onDOMChanged() {
        if (this.element && document.contains(this.element)) {
            return;
        }

        this.element = this.props.cardRef ?? document.getElementById(
            stringifyId(this.props.target_id)
        );
        if (!this.element) {
            return;
        }

        this.graphContainers = this.element.classList.contains('dash-graph')
            ? [this.element] // the container is the Graph element itself
            : this.element.getElementsByClassName('dash-graph');

        this.graphContainers = this.element.classList.contains('dash-graph')
            ? [this.element] // the container is the Graph element itself
            : this.element.getElementsByClassName('dash-graph');

        if (!this.graphContainers.length) {
            this.element.classList.add('fullscreen--cb');
        }
        /* Check if a Modal initialized this element as 'hidden' */
        this.hidden = this.element.classList.contains('hidden');
        if (this.props.hide_target) {
            this.element.classList.add('hidden');
        }

        this.setState({});
    }

    toggleFull() {
        const element = this.element;
        if (!element) {
            return;
        }

        var expanded = this.state.expanded;

        /* We are entering a fullscreen state, so we adjust any Graph children's
           dimensions to fit a full screen nicely */
        if (element && fscreen.fullscreenEnabled && !expanded) {
            saveGraphDimensions(this, () => fscreen.requestFullscreen(element))
        }
        else {
            fscreen.exitFullscreen();
            if (this.props.hide_target) {
                element.classList.add('hidden');
            }
        }
    }

    render() {
        const { children, id, className, hide_if_fullscreen_is_unsupported, style } = this.props;
        return (
                <div
                    id={id}
                    className={`
                        expandToFull \
                        expandToFull--fullscreen \
                        ${this.isDisabled ? 'expandToFull--disabled' : ''} \
                        ${hide_if_fullscreen_is_unsupported ? 'fullscreen--hidden' : ''} \
                        ${this.isDisabledInWorkspace ? 'expandToFull--workspace' : ''} \
                        ${className ? className : ''}
                    `}
                    data-expanded={this.state.expanded}
                    style={{ ...style }}
                    onClick={!this.isDisabled ? this.toggleFull : null}
                >
                    {children ? children : <IconCardFullscreen expanded={this.state.expanded} />}
                </div>
        )
    }
};

FullScreen.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The optional className of the child element(s) that activate
     * a fullscreen state on click
     */
    className: PropTypes.string,

    /**
     * The element(s) that, when clicked, will activate a fullscreen state
     */
    children: PropTypes.node,

    /**
     * A reference to a card to expand
     */
    cardRef: PropTypes.node,

    /**
     * The id of the DOM element to expand to full screen
     */
    target_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    /**
     * An option to initialize the fullscreen target as hidden
     */
    hide_target: PropTypes.bool,

    /**
     * An option to hide children of this component on unsupported
     * browsers (
     */
    hide_if_fullscreen_is_unsupported: PropTypes.bool,

    /**
     * A boolean representing the current expanded state of the target element
     */
    expanded: PropTypes.bool,

    /**
     * Dash-assigned callback that gets fired when the value changes
     */
    setProps: PropTypes.any,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,
};
