import { css } from '../utils.js';
import chroma          from 'chroma-js';
import tinycolor from 'tinycolor2';

const themedStyles = css`
    .ddk-container .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner table,
    .ddk-container .dash-spreadsheet-container .dash-spreadsheet-inner table {
        font-family: ${props => props.theme.font_family};
        --accent: ${props => chroma(props.theme.accent).css()};
        --selected-background: ${props => chroma(props.theme.accent).alpha(0.2).css()};
    }

    .ddk-container.ddk-container--print-context .ddk-report .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner table,
    .ddk-container.ddk-container--print-context .ddk-report .dash-spreadsheet-container .dash-spreadsheet-inner table {
        font-family: ${props => props.theme.report_font_family};
    }

    .ddk-container .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner input:not([type=radio]):not([type=checkbox]) {
        color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner table tr:not(:hover) .input-active {
        color: ${props => tinycolor.mostReadable(chroma(props.theme.accent).alpha(0.2), [props.theme.body_text],{includeFallbackColors:true}).toHexString()};
    }

    .ddk-container .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner table tr:hover .input-active {
        color: ${props => tinycolor.mostReadable(chroma(props.theme.accent).alpha(0.2), ['#fff', '#000'],{includeFallbackColors:true}).toHexString()};
    }

    .ddk-container .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner :not(.cell--selected) tr:hover {
        background-color: transparent;
    }

    .ddk-container .dash-table-container .dash-spreadsheet .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    .ddk-container .dash-spreadsheet .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
		background-color: transparent;
	    color: ${props => props.theme.body_text};
    }

    .ddk-container .dash-table-container .dash-spreadsheet .Select-arrow {
	    border-top-color: ${props => props.theme.body_text};
    }

    .ddk-container .dash-table-container .dash-spreadsheet .Select-control:hover .Select-arrow {
	    border-top-color: ${props => props.theme.body_text};
    }

    .ddk-container .dash-table-container .dash-spreadsheet .is-open > .Select-control .Select-arrow {
	    color: ${props => props.theme.body_text};
    }

    .ddk-container .dash-table-container .dash-spreadsheet .Select--multi .Select-value,
    .ddk-container .dash-table-container .dash-spreadsheet .Select--multi a.Select-value-label {
	    color: ${props => props.theme.body_text};
    }

    .ddk-container .dash-table-container .dash-spreadsheet .Select-menu-outer {
	    background-color : ${props => props.theme.background_content};
	    border-top-color : ${props => props.theme.border};
	    border-bottom-color : ${props => props.theme.border};
    }
    .ddk-container .dash-table-container .dash-spreadsheet .Select-option {
        color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-table-container .dash-spreadsheet .Select-option:hover {
	    color: ${props => props.theme.body_text};
        background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
    }
    .ddk-container .dash-table-container .dash-spreadsheet .Select.is-focused:not(.is-open) > .Select-control {
        background: ${props => props.theme.background_content};
    }
    .ddk-container .dash-table-container .dash-spreadsheet .Select-option.is-focused {
	    background-color: ${props => chroma(props.theme.background_content).alpha(0.8).css()};
	    color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-table-container .dash-spreadsheet .Select:hover .Select-clear {
	    color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-tooltip {
        border: 1px solid ${props => props.theme.border};
    }
    .ddk-container .dash-tooltip .dash-table-tooltip {
        background-color: ${props => props.theme.background_content};
    }
    .ddk-container .dash-tooltip[data-attr-anchor='top']:after {
        border-bottom-color: ${props => props.theme.border};
    }
    .ddk-container .dash-tooltip[data-attr-anchor='top']:before {
        border-bottom-color: ${props => props.theme.border} ;
    }
    .ddk-container .dash-tooltip[data-attr-anchor='bottom']:after {
        border-top-color: ${props => props.theme.border};
    }
    .ddk-container .dash-tooltip[data-attr-anchor='bottom']:before {
        border-top-color: ${props => props.theme.border};
    }
    .ddk-container .dash-spreadsheet-menu .dash-spreadsheet-menu-item .show-hide-menu {
        background-color: ${props => props.theme.background_content};
        border: 1px solid ${props => props.theme.background_content};
    }
    .ddk-container .dash-table-container .previous-next-container input.current-page {
        border-bottom: solid ${props => props.theme.border} 1px !important;
	    color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-table-container .previous-next-container input.current-page::placeholder {
	    color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-table-container .previous-next-container button.previous-page:hover,
    .ddk-container .dash-table-container .previous-next-container button.next-page:hover,
    .ddk-container .dash-table-container .previous-next-container button.first-page:hover,
    .ddk-container .dash-table-container .previous-next-container button.last-page:hover {
        color: ${props => props.theme.accent};
    }
    .ddk-container .dash-table-container .previous-next-container button.previous-page:hover:disabled,
    .ddk-container .dash-table-container .previous-next-container button.next-page:hover:disabled,
    .ddk-container .dash-table-container .previous-next-container button.first-page:hover:disabled,
    .ddk-container .dash-table-container .previous-next-container button.last-page:hover:disabled {
        color: ${props => props.theme.body_text};
    }
    .ddk-container .dash-table-container .dash-spreadsheet-container legend {
        color: ${props => props.theme.body_text};
    }
`
export default themedStyles
