import { css } from '../utils.js';
import tinycolor from 'tinycolor2'

const themedStyles = css`
    .ddk-container .VirtualizedSelectFocusedOption {
      cursor: pointer;
    }

    .ddk-container .VirtualizedSelectOption {
        display: block;
        min-width: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${props => props.theme.control_text};
    }

    .ddk-container .layout-header .VirtualizedSelectOption {
        color: ${props =>
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        };
    }

    .ddk-container .VirtualizedSelectOption::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .ddk-container .VirtualizedSelectFocusedOption,
    .ddk-container .VirtualizedSelectSelectedOption {
        color: ${props => props.theme.accent};
        background-color: ${props => {
            switch(props.theme.control_background_color) {
                case props.theme.background_content:
                    return props.theme.background_page;
                case props.theme.background_page:
                    return props.theme.background_content;
                default:
                    return tinycolor(props.theme.control_background_color).isLight()
                      ? tinycolor(props.theme.control_background_color).darken()
                      : tinycolor(props.theme.control_background_color).lighten()
            }
        }};
    }

    .ddk-container .ReactVirtualized__Grid.ReactVirtualized__List.VirtualSelectGrid::-webkit-scrollbar {
        width: 0.5em;
    }

    .ddk-container .ReactVirtualized__Grid.ReactVirtualized__List.VirtualSelectGrid::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.accent};
    }
`
export default themedStyles;
