const Neutral_Light = {
    accent: "#1670AE",
    accent_positive: "#4EAD41",
    accent_negative: "#CD3B3B",
    background_content: "#FFFFFF",
    background_page: "#F1F2F4",
    body_text: "#333333",
    border: "#CCCCCC",
    border_style: {
        name: "underlined",
        borderWidth: "0px 0px 0px 0px",
        borderStyle: "solid",
        borderRadius: "0px",
    },
    button_border: {
        width: "0px",
        color: "#1670AE",
        radius: "4px"
    },
    button_capitalization: "none",
    button_text: "#FFFFFF",
    button_background_color: "#1670AE",
    control_border: {
        width: "1px 1px 1px 1px",
        color: "#CCCCCC",
        radius: "4px"
    },
    control_background_color: "#FFFFFF",
    control_text: "#1670AE",
    card_margin: "20px",
    card_padding: "8px",
    card_border: {
        width: "0px",
        style: "solid",
        color: "#e2e2e2",
        radius: "8px",
    }, card_background_color: "#FFFFFF",
    card_box_shadow: "0px 0px 5px rgba(0,0,0,0.08)",
    card_outline: {
        width: "0px",
        style: "solid",
        color: "#e2e2e2"
    },
    card_header_margin: "0px 0px 16px 0px",
    card_header_padding: "0px",
    card_header_border: {
        width: "0px 0px 0px 0px",
        style: "solid",
        color: "#F2F3F5",
        radius: "0px",
    },
    card_header_background_color: "#FFFFFF",
    card_header_box_shadow: "0px 0px 0px rgba(0,0,0,0)",
    breakpoint_font: "700px",
    breakpoint_stack_blocks: "700px",
    colorway: [
        "#62A6FB",
        "#FC8250",
        "#70D362",
        "#B64226",
        "#158885",
        "#9C6316",
        "#F369CF",
        "#A274FC",
        "#FDC646",
        "#bab0ac",
    ],
    colorscale: [
        "#deeffa",
        "#c6dceb",
        "#afcadd",
        "#99b7cf",
        "#83a5c2",
        "#6d93b4",
        "#5882a7",
        "#427099",
        "#2b5f8c",
        "#094f7f",
    ],
    dbc_primary: "#1670AE",
    dbc_secondary: "#62A6FB",
    dbc_info: "#1670AE",
    dbc_gray: "#A0A0A0",
    dbc_success: "#4EAD41",
    dbc_warning: "#F2AA03",
    dbc_danger: "#CD3B3B",
    font_family: "Noto Sans",
    font_family_header: "Noto Sans",
    font_family_headings: "Noto Sans",
    font_size: "16px",
    font_size_smaller_screen: "16px",
    font_size_header: "24px",
    title_capitalization: "uppercase",
    header_content_alignment: "spread",
    header_margin: "0px 0px 24px 0px",
    header_padding: "0px 16px 0px 0px",
    header_border: {
        width: "0px 0px 0px",
        style: "solid",
        color: "#e2e2e2",
        radius: "0px",
    },
    header_background_color: "#333333",
    header_box_shadow: "0px 1px 3px rgba(0,0,0,0)",
    header_text: "#FFFFFF",
    heading_text: "#333333",
    text: "#333333",
    report_background: "#F1F2F4",
    report_background_content: "#FFFFFF",
    report_background_page: "white",
    report_text: "black",
    report_font_family: "Noto Serif",
    report_font_size: "12px",
    report_border: "#bbbbbb",
    graph_grid_color: "#CCCCCC",
    table_striped_even: "#FFFFFF",
    table_striped_odd: "#FFFFFF",
    table_border: "#D9DBE0",
    card_accent: "#62A6FB",
};

export default Neutral_Light;